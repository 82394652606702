import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { FormsControl } from '../../GlobalComponents/Form/FormControl';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { confirmForgotPassword } from '../../redux/slices/teacherSlice';
import teacherLogo from '../../Assets/Lemmy_Base.png';
import { Toast } from '../../LibraryComponents/Toast';
import { loginSchema } from './schema';

export const ConfirmForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [openToast, setOpenToast] = useState<{
    open: boolean;
    msg: string;
    severity: 'error' | 'success';
  }>({ open: false, msg: '', severity: 'error' });
  const formik = useFormik({
    initialValues: {
      email: '',
      newPassword: '',
      confirmPassword: '',
      confirmationCode: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { email, newPassword, confirmationCode } = values;
      setSaveLoading(true);
      try {
        await dispatch(
          confirmForgotPassword({
            email,
            newPassword,
            confirmationCode,
          }),
        )
          .then((res: any) => {
            if (res.payload === 400 || res.payload === 500) {
              setOpenToast({
                severity: 'error',
                open: true,
                msg: 'We encountered an issue while attempting to reset your password. Please ensure your information is correct and try again. If the problem persists, contact our support team for assistance',
              });
              throw new Error(res);
            }
            navigate('/educator/login');
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error(error);
      }
      setSaveLoading(false);
    },
  });
  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));
  return (
    <Box sx={{ margin: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          m: 'auto',
        }}
      >
        <Toast
          isOpen={openToast.open}
          severity={openToast.severity}
          onClose={() => closeToast(true)}
          styles={{
            width: { xs: '100%', sm: '400px' },
            textAlign: 'center',
          }}
          errorMessage={openToast.msg}
        />
      </Box>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <img src={teacherLogo} alt="educator-logo" width={80} />
        </Box>
        <Typography
          variant="h5"
          sx={{
            color: '#003366',
            fontWeight: 500,
            maxWidth: '400px',
            textAlign: 'center',
          }}
        >
          We’ve sent an email to your email address on file with a code. Please
          enter the code here:
        </Typography>
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '300px', md: '400px' },
          }}
        >
          <FormsControl
            control="input"
            type="email"
            name="email"
            label="Email"
            formik={formik}
          />
          <FormsControl
            control="input"
            type="password"
            name="newPassword"
            label="New Password"
            formik={formik}
          />
          <FormsControl
            control="input"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            formik={formik}
          />
          <InputLabel
            htmlFor="my-input"
            sx={{ width: '100%', textAlign: 'start', m: '0 0 5px 30px' }}
          >
            Confirmation code
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              label="Enter your code"
              inputProps={{ maxLength: 6 }}
              name="confirmationCode"
              value={formik.values.confirmationCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmationCode &&
                Boolean(formik.errors.confirmationCode)
              }
              helperText={
                formik.touched.confirmationCode &&
                formik.errors.confirmationCode
              }
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
            variant="outlined"
          >
            <Button
              variant="outlined"
              sx={{ mt: 3, mb: 2, mr: '20px', fontSize: '16px' }}
              onClick={() => navigate('/educator/login')}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: '16px', minWidth: '97px' }}
              type="submit"
              disabled={saveLoading}
            >
              {saveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
