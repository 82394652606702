import { ICurriculums } from '../../../../redux/types/resourcesTypes';

export function filterCoursesByStateAndGrade(
  courses: ICurriculums[],
  stateId: string,
  gradeId: string,
): ICurriculums[] {
  return courses.filter((course) => {
    if (stateId && gradeId) {
      return course.stateId === stateId && course.gradeId === gradeId;
    }
    if (stateId) {
      return course.stateId === stateId;
    }
    if (gradeId) {
      return course.gradeId === gradeId;
    }
    return true;
  });
}
