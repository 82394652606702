import { Box, Typography } from '@mui/material';
import React from 'react';

export const NoStudent = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: '10px 0 0px 0',
        background: '#fff',
        height: '50vh',
      }}
    >
      <Typography
        data-testid="not-student-in-class"
        sx={{ color: '#746E6F', fontWeight: 800, fontSize: '20px' }}
      >
        There are no students in this class
      </Typography>
    </Box>
  );
};
