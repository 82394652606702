import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import lemmy from '../../Assets/Lemmy_Base.png';
import { ReactComponent as LeftArrow } from '../../Assets/Left_Bold_Arrow.svg';
import { IGetStartedProps } from './types';
import { useNavigate } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import AddIcon from '@mui/icons-material/Add';

export const GetStarted = ({
  label,
  ifLeftArrow = false,
  isClassroom = false,
}: IGetStartedProps) => {
  const navigate = useNavigate();
  const goToCreateClassroom = () => {
    navigate(routeIDs.EDUCATOR_CREATE_CLASSROOM);
  };
  return (
    <Box
      sx={{
        background: '#003366',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: '80vh', sm: '100%' },
      }}
    >
      {isClassroom && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'right',
            height: '5rem',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            data-testid="Create Classroom"
            sx={{
              background: '#FFFFFF',
              color: '#003366',
              borderRadius: '12px',
              margin: '1rem',
              marginRight: '3rem',
            }}
            onClick={goToCreateClassroom}
            startIcon={<AddIcon />}
          >
            Create Classroom
          </Button>
        </Box>
      )}
      <Box
        sx={{
          background: '#003366',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: { xs: '80vh', sm: '100%' },
        }}
      >
        <Box sx={{ maxWidth: { xs: '150px', sm: '200px' } }}>
          <Box component={'img'} src={lemmy} width={'100%'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {ifLeftArrow && <LeftArrow width={50} height={50} fill="#fff" />}
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '20px', sm: '24px', md: '32px' },
              fontWeight: 600,
              marginLeft: '20px',
              color: '#fff',
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
