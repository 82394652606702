import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import lemmyBase from '../../../Assets/Lemmy_Math.png';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { studentEditSchema } from './StudentAccountSettingsHelpers';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  changeUserName,
  deleteStudentAccount,
  studentLeaveClassroom,
  isUsernameAvailable,
  getUserDetails,
  renameStudent,
} from '../../../redux/slices/studentSlice';
import { DeleteConfirmationDialog } from '../../../LibraryComponents/DeleteConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../../LibraryComponents/Toast';
import Modal from '../../../LibraryComponents/Modal/Modal';
import { ChangePassword } from '../../../LibraryComponents/ChangePassword';
import { logout } from '../../../redux/slices/authSlice';
import useDataCleanup from '../../../hooks/useDataCleanup';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { IStudentAccountSettingsProps, IToast } from './types';
import SaveIcon from '@mui/icons-material/Save';
import LockResetIcon from '@mui/icons-material/LockReset';

export const StudentAccountSettings = ({
  setShowAccountSetting,
}: IStudentAccountSettingsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { token, userId: studentId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { studentData } = useSelector((state: RootState) => state.student);

  const navigate = useNavigate();
  const { removeData } = useDataCleanup();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openToast, setOpenToast] = useState<IToast>({
    open: false,
    msg: '',
    severity: 'error',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const initialValues = {
    lastName: studentData?.lastName ?? '',
    firstName: studentData?.firstName ?? '',
    username: (studentData?.userName as string) ?? '',
  };

  const classroomTitle = 'ABC123';

  const formik = useFormik({
    initialValues,
    validationSchema: studentEditSchema,
    onSubmit: async (values) => {
      setSaveLoading(true);
      const { username, firstName, lastName } = values;
      if (username !== initialValues.username) {
        const { payload: isAvailable } = await dispatch(
          isUsernameAvailable({ token, username }),
        );
        if (isAvailable) {
          await dispatch(changeUserName({ token, studentId, username }));
          await dispatch(getUserDetails({ username, token }));
        } else {
          setOpenToast({
            open: true,
            severity: 'error',
            msg: 'This username is not available',
          });
          setSaveLoading(false);
          formik.setFieldValue('username', studentData?.userName as string);
          return;
        }
      }
      if (
        firstName !== initialValues.firstName ||
        lastName !== initialValues.lastName
      ) {
        setOpenToast({
          open: true,
          severity: 'success',
          msg: 'Name changed successfully',
        });
        await dispatch(
          renameStudent({ token, studentId, firstName, lastName }),
        );
        await dispatch(getUserDetails({ username, token }));
      }
      setShowAccountSetting(false);
      formik.resetForm();
      setSaveLoading(false);
    },
  });

  const handleDeleteAccount = () => {
    setDeleteLoading(true);
    dispatch(deleteStudentAccount({ studentId, token }))
      .then(() => {
        removeData();
        dispatch(logout());
        navigate(routeIDs.LOGIN);
      })
      .catch((err) => console.error(err))
      .finally(() => setDeleteLoading(false));
    setShowConfirmation(false);
  };

  const handleLeaveClass = () => {
    dispatch(studentLeaveClassroom({ studentId, token }));
    setLeaveDialogOpen(false);
  };

  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));

  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(fakeLoading);
  }, []);

  const name = `${studentData?.firstName} ${studentData?.lastName}` || '';

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#003366',
          position: 'relative',
          minHeight: { xs: '160px', sm: '140px' },
          borderRadius: '10px 10px 0 0 ',
          mb: { xs: '40px', sm: '20px' },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1.5,
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
            fontSize: { xs: '1.75rem', sm: '2.25rem' },
          }}
          gutterBottom
        >
          Student Account Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: { xs: '97px', md: '90px' },
          }}
        >
          <Avatar sx={{ width: '80px', height: 'min-content' }}>
            <img src={lemmyBase} alt="lemmy-logo" width={80} height={80} />
          </Avatar>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              color: '#003366',
            }}
            gutterBottom
          >
            {name}
          </Typography>
        </Box>
      </Box>
      <PageContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Toast
            isOpen={openToast.open}
            severity={openToast.severity}
            onClose={() => closeToast(true)}
            styles={{
              width: { xs: '86%', sm: '100%' },
              textAlign: 'center',
              top: 40,
            }}
            errorMessage={openToast.msg}
          />
        </Box>
        {isLoading && <Spinner />}
        <Box
          sx={{
            marginTop: { xs: 0, sm: 8 },
            marginBottom: { xs: 0, sm: 8 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component={'form'}
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '300px', md: '400px' },
            }}
          >
            <Typography variant="h6">Account Info</Typography>
            <FormsControl
              control="input"
              type="text"
              name="firstName"
              label="First Name"
              formik={formik}
              variant="standard"
            />
            <FormsControl
              control="input"
              type="text"
              name="lastName"
              label="Last Name"
              formik={formik}
              variant="standard"
            />
            <FormsControl
              control="input"
              type="text"
              name="username"
              label="Username"
              formik={formik}
              variant="standard"
            />
            <Button
              variant="contained"
              data-testid="student-change-password"
              fullWidth
              onClick={() => setOpenChangePassword(true)}
              sx={{ mt: '10px', backgroundColor: '#003366', fontWeight: 700 }}
              endIcon={<LockResetIcon fontSize="large" />}
            >
              Change Password
            </Button>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: { xs: '10px', sm: '20px' },
                flexDirection: { xs: 'column-reverse', sm: 'row' },
              }}
            >
              <Button
                sx={{
                  color: 'red',
                  borderColor: 'red',
                  padding: '5px',
                  width: { xs: '100%', sm: 'auto' },
                }}
                variant="outlined"
                onClick={() => setShowConfirmation(true)}
                disabled={deleteLoading}
                data-testid="delete-student-account"
              >
                {deleteLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Delete Account'
                )}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setShowAccountSetting(false)}
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  mb: { xs: '10px', sm: '0' },
                  borderColor: '#003366',
                  fontWeight: 700,
                  color: '#003366',
                }}
                data-testid="student-edit-cancel"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                  mb: { xs: '10px', sm: '0' },
                  backgroundColor: '#003366',
                  fontWeight: 700,
                }}
                disabled={saveLoading}
                endIcon={<SaveIcon />}
              >
                {saveLoading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Box>
            <DeleteConfirmationDialog
              mode="confirmation"
              handleDelete={handleDeleteAccount}
              handleCloseModal={() => setShowConfirmation(false)}
              actionButtonLabel="Delete Account"
              confirmationText="Are you sure you want to delete your account? This action cannot be undone."
              dialogTitle="Delete Account"
              open={showConfirmation}
            />
            <DeleteConfirmationDialog
              open={leaveDialogOpen}
              dialogTitle="Leave Classroom"
              confirmationText={`Are you sure you want to leave the classroom "${classroomTitle}"?`}
              actionButtonLabel="Delete"
              validationString="DELETE"
              handleDelete={handleLeaveClass}
              handleCloseModal={() => setLeaveDialogOpen(false)}
              mode="normal"
            />
          </Box>
        </Box>
        {openChangePassword && (
          <Modal
            isOpen={openChangePassword}
            onClose={() => setOpenChangePassword(false)}
          >
            <ChangePassword
              handleClose={() => setOpenChangePassword(false)}
              closeToast={closeToast}
              setOpenToast={setOpenToast}
              openToast={openToast}
            />
          </Modal>
        )}
      </PageContainer>
    </Box>
  );
};
