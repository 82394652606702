/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { StudentMenuOptions } from '../../LibraryComponents/StudentMenuOptions';
import lemmyLogo from '../../Assets/Lemmy_Base.png';
import { TeacherMenuOptions } from '../../LibraryComponents/TeacherMenuOptions';
import { AppDispatch, RootState } from '../../redux/store';
import Modal from '../../LibraryComponents/Modal/Modal';
import { About } from '../../Pages/About';
import { useNavigate, useLocation } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import {
  getTeacherDetails,
  teacherRefreshLogin,
} from '../../redux/slices/teacherSlice';
import {
  loginFailure,
  loginRefresh,
  logout,
} from '../../redux/slices/authSlice';
import useDataCleanup from '../../hooks/useDataCleanup';
import { debounce } from 'lodash';
import { Spinner } from '../../LibraryComponents/Spinner';
import {
  LemonyLogoButton,
  LemonyLogoText,
  StHeader,
  UserIcon,
} from './Styled.Headerbar';
import { jwtDecode } from 'jwt-decode';
import { Button } from '@mui/material';
import { useGTM } from '../../hooks/useGTM';

const HTTP_UNAUTHORIZED = 401;
const HTTP_INTERNAL_SERVER_ERROR = 500;

export const Headerbar = ({
  toggleDrawer,
}: {
  toggleDrawer: (isOpen: boolean) => void;
}) => {
  const { userId, role, refreshToken, accessToken, userIsPremium } =
    useSelector((state: RootState) => state.auth);
  const { teacherData } = useSelector((state: RootState) => state.teacher);
  const { studentData, status, statusStudentResponse } = useSelector(
    (state: RootState) => state.student,
  );
  const { statusQuizResponse } = useSelector((state: RootState) => state.quiz);
  const { statusLessonResponse } = useSelector(
    (state: RootState) => state.lesson,
  );
  const { statusResourcesResponse } = useSelector(
    (state: RootState) => state.resources,
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch<AppDispatch>();
  const { removeData } = useDataCleanup();

  const [showAbout, setShowAbout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useGTM();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    toggleDrawer(false);
  };

  const getUserInitials = () => {
    const firstInitial = teacherData?.firstName?.charAt(0) ?? '';
    const lastInitial = teacherData?.lastName?.charAt(0) ?? '';
    const userNameInitial = studentData?.userName?.charAt(0) ?? '';
    if (role === 'teacher') {
      return firstInitial + lastInitial;
    }
    return userNameInitial;
  };

  const handleStudentNavigation = () => {
    if (studentData?.classroomId === null) {
      navigate(routeIDs.STUDENT_OTP_CLASS_CODE);
    } else {
      navigate(routeIDs.STUDENT);
    }
  };

  const refreshLogin = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        teacherRefreshLogin({ userId, refreshToken }),
      );

      if (response.payload.status === HTTP_UNAUTHORIZED) {
        removeData();
        dispatch(loginFailure());
        navigate(routeIDs.LOGIN);
        setIsLoading(false);
        return;
      } else {
        dispatch(loginRefresh(response.payload));
        setIsLoading(false);
      }
    } catch (error) {
      console.error('An error occurred while refreshing login:', error);
      removeData();
      dispatch(loginFailure());
      setIsLoading(false);
      navigate(routeIDs.LOGIN);
    }
  };

  const debouncedRefreshLogin = debounce(refreshLogin, 100);

  const decodedToken = accessToken ? jwtDecode(accessToken) : '';
  const expirationTimeInSeconds = decodedToken === '' ? -1 : decodedToken.exp;

  const tokenExpiry = expirationTimeInSeconds && expirationTimeInSeconds * 1000;

  function isTokenExpired(tokenExpiry: number) {
    const now = new Date().getTime();
    return now > tokenExpiry;
  }

  const hasTokenExpired = isTokenExpired(tokenExpiry as number);

  useEffect(() => {
    if (role === 'teacher' && hasTokenExpired && userId) {
      debouncedRefreshLogin();
    }
  }, [hasTokenExpired]);

  useEffect(() => {
    if (role === 'student' && userId) {
      handleStudentNavigation();
    }
  }, [status]);

  useEffect(() => {
    if (
      role === 'student' &&
      (statusStudentResponse === HTTP_UNAUTHORIZED ||
        statusQuizResponse === HTTP_UNAUTHORIZED ||
        statusLessonResponse === HTTP_UNAUTHORIZED)
    ) {
      removeData();
      dispatch(logout());
      navigate(`${routeIDs.LOGIN}?role=${role}`);
    }
  }, [statusStudentResponse, statusQuizResponse, statusLessonResponse]);

  useEffect(() => {
    if (
      role === 'student' &&
      (statusStudentResponse === HTTP_INTERNAL_SERVER_ERROR ||
        statusQuizResponse === HTTP_INTERNAL_SERVER_ERROR ||
        statusLessonResponse === HTTP_INTERNAL_SERVER_ERROR)
    ) {
      navigate(routeIDs.PAGE_NOT_FOUND);
    }
  }, [statusStudentResponse, statusQuizResponse, statusLessonResponse]);

  useEffect(() => {
    if (!teacherData && role === 'teacher' && userId) {
      dispatch(
        getTeacherDetails({
          teacherId: userId,
          token: accessToken,
        }),
      );
    }
  }, [accessToken, dispatch, teacherData, userId]);

  useEffect(() => {
    if (
      role === 'teacher' &&
      statusResourcesResponse === HTTP_INTERNAL_SERVER_ERROR
    ) {
      navigate(routeIDs.PAGE_NOT_FOUND);
    }
  }, [statusResourcesResponse]);

  const redirectLogin = () => {
    const studentPaths = [
      '/login',
      '/guestSelectionCourses',
      '/createStudentAccount',
    ];

    const educatorPaths = ['/educator/login', '/educator/createTeacherAccount'];

    if (!userId) {
      navigate(routeIDs.ROLE_SELECTION_SCREEN);
    } else if (studentPaths.includes(location.pathname)) {
      navigate('/login');
    } else if (educatorPaths.includes(location.pathname)) {
      navigate('/login');
    } else {
      navigate(routeIDs.EDUCATOR_HOME);
    }
  };

  const redirectChoosePlan = () => {
    track('upgrade_button_clicked');
    navigate(routeIDs.EDUCATOR_PREMIUM_CHOOSEPLAN);
  };

  return (
    <StHeader>
      {isLoading && <Spinner />}
      <LemonyLogoButton onClick={redirectLogin}>
        <img alt="lemmy" src={lemmyLogo} width="35" />
        <LemonyLogoText>Lemoney Learning</LemonyLogoText>
      </LemonyLogoButton>
      {userId && role && role === 'teacher' && !userIsPremium && (
        <Button
          size="medium"
          onClick={redirectChoosePlan}
          variant="contained"
          sx={{
            color: '#FFFFFF',
            width: '14rem',
            height: '3rem',
            fontWeight: 'bold',
            mr: '2rem',
            marginLeft: 'auto',
          }}
        >
          Upgrade To Premium
        </Button>
      )}
      {userId && role && role !== 'admin' && (
        <Box>
          <UserIcon
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            data-testid="user-icon"
          >
            {getUserInitials()}
          </UserIcon>
          {role === 'student' ? (
            <StudentMenuOptions
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              open={open}
              setShowAbout={setShowAbout}
            />
          ) : (
            <TeacherMenuOptions
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
              data-testid="teacher-menu-options"
              open={open}
              setShowAbout={setShowAbout}
            />
          )}
        </Box>
      )}
      <Modal
        isOpen={showAbout}
        onClose={() => setShowAbout(false)}
        showCloseButton={false}
      >
        <About setShowAbout={setShowAbout} />
      </Modal>
    </StHeader>
  );
};
