import { Button, ButtonProps, InputBase } from '@mui/material';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { IStyledClassSummaryProps } from '../../types';

export const Span = styled.span`
  background-color: #003366;
  padding: 8px 10px;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
`;

export const ClassroomName = styled.input<IStyledClassSummaryProps>`
  border: none;
  font-size: 1.25rem;
  outline: ${({ isReadOnly }) => isReadOnly && 'none'};
  border-bottom: ${({ isReadOnly }) =>
    isReadOnly ? '1px solid black' : 'none'};
  margin: 0 8px 0 0;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const Input = styledMui(InputBase)(({ theme }) => ({
  'label + &': {},
  '& .MuiInputBase-input': {
    borderRadius: '20px',
    position: 'relative',
    backgroundColor: '#E0E0E0',
    border: '1px solid #ced4da',
    color: '#003366',
    fontSize: 16,
    padding: '5px 26px 5px 12px',
    transition: 'none',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  '& .MuiSelect-icon': {
    fill: '#003366',
  },
  '& .MuiInputBase-input:focus': {
    borderRadius: '20px',
  },
}));

export const AddButton = styledMui(Button)<ButtonProps>(({ theme }) => ({
  color: '#FFF',
  fontWeight: 800,
}));
