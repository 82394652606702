import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import QRCode from 'react-qr-code';

export const ShareClassroomDialog = ({
  dialogOpen,
  setDialogOpen,
  classroomCode,
}: {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  classroomCode: string;
}) => {
  const url = window.location.origin;
  const classroomCodeLink = `${url}/createAccount?role=student&code=${classroomCode}`;

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(classroomCodeLink);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          USE THE CLASS CODE
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <DialogContentText>
            This is your classroom code. Ask your students to enter it when
            creating a Lemoney Learning account by using this link or the QR
            code below.
          </DialogContentText>
          <Typography sx={{ marginTop: '3rem' }} variant="h3">
            {classroomCode}
          </Typography>
          <TextField
            autoFocus
            sx={{ marginTop: '3rem', marginBottom: '3rem' }}
            id="name"
            fullWidth
            variant="outlined"
            data-testid="url-classroom-code"
            disabled
            value={classroomCodeLink}
            InputProps={{
              endAdornment: (
                <Button
                  sx={{ width: '16rem', background: '#003366' }}
                  variant="contained"
                  onClick={copyLinkToClipboard}
                >
                  Copy to Clipboard
                </Button>
              ),
            }}
          />
          <QRCode value={classroomCodeLink} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
