export class TileToolTops {
  public static readonly standardsAlignmentPdfUrl =
    'See how Lemoney Learning lessons (with hyperlinks) align with your state and course standards.';
  public static readonly pacingGuide =
    'Scope & sequence showing how Lemoney Learning resources can be used with a day-by-day pacing guide. Includes hyperlinks to serve as a one-stop-resource.';
  public static readonly questionBankUrl =
    'Hundreds of assessment questions mapped to specific lessons/standards. Includes answer explanation and Depth of Knowledge level for each question.';
  public static readonly curriculumPdfUrl =
    'Full powerpoint presentation materials for all lessons with clear standards alignment.';
  public static readonly curriculumBreakoutPdfUrl = `Love our instructional materials powerpoint but wish it was broken out into individual lessons? We've got you covered! All of our lessons have been broken out into individual ppt documents for easier navigation!`;
  public static readonly teacherTipsAndTricksUrl =
    'Tips and tricks for each lesson! See talking points as well as common questions asked for each lesson.';
  public static readonly classroomActivities =
    'Instructions for facilitating hands-on activities in the classroom to reinforce key learnings.';
  public static readonly instructionalVideos =
    'Curated library of supplemental videos to reinforce key learnings.';
  public static readonly thirdPartyResource =
    'Curated list of external resources to help reinforce key points in the classroom.';
  public static readonly coachingVideos =
    'Videos aimed at assisting the educator! See videos discussing recommended points to reinforce during lessons as well as tips/tricks to managing classroom activities and answers to common questions heard in the classroom.';
  public static readonly preBuiltAssessments =
    'Pre-made pre-test, course test, and module assessments. Includes student version and answer key with corresponding standards alignment and Depth of Knowledge level for each question.';
  public static readonly worksheet =
    'Problem sets for specific lessons to reinforce key learnings. Answer key included.';
  public static readonly careerVideos =
    'Recorded videos to help give students exposure to a broad range of careers in business, finance, and entrepreneurship. Hear directly from professionals in the industry on what advice they have to give!';
  public static readonly projects =
    'Projects for completion in the classroom or at home with clearly defined deliverables and a corresponding grading rubric.';
  public static readonly studentLessonContent =
    'See what the students see. Full list of learning content seen by students on the digital learning platform.';
  public static readonly chatUI =
    'Lemoney Learning is excited to launch the Beta of our AI tool! Asked a tricky question in the classroom or have a question yourself? The Lemoney Learning AI tool can provide responses to academic related questions for you and your students!I';
  public static readonly newsRoundup =
    'See a curated list of current events to bring topics to life in the classroom!';
  public static readonly askAnExpert =
    'Need help with an upcoming lesson? Want to brainstorm with our team? Feel free to let us know how we can help!';
  public static readonly vocabBank =
    'A comprehensive collection of the most important key words and definitions for this course';
  //Not Currently Used
  public static readonly educatorReferralProgram = 'educatorReferralProgram';
  public static readonly inviteBankerToClass = 'inviteBankerToClass';
  public static readonly contactUs = 'contactUs';
}
