import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import lemmy from '../../Assets/Lemmy_Base.png';
import { IPremiumDialogProps } from './types';
import { ChoosePlanLink } from './ChoosePlanLink';

export const PremiumDialog = ({ open, handleClose }: IPremiumDialogProps) => {
  return (
    <Dialog sx={{ zIndex: '1200' }} open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '10px 20px',
          padding: '40px',
        }}
      >
        <Box component={'img'} src={lemmy} width={150} height={'100%'} />
        <Typography
          variant="h5"
          fontWeight={600}
          color={'green'}
          m={'20px 0 40px 0'}
        >
          Lemoney Learning Premium
        </Typography>
        <Typography
          sx={{ marginBottom: '20px', fontSize: '18px', fontWeight: 600 }}
        >
          This is a premium feature. <ChoosePlanLink text={'Click here'} /> to
          upgrade now!
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: '40px',
          }}
        >
          <Button
            variant="outlined"
            data-testid="cancel-delete-confirm-dialog"
            sx={{ width: '120px', color: '#003366', fontWeight: 500 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
