import { AnswerPlace } from '../../../redux/types/quizTypes';

export const findAnswerOption = (
  questionType: 'multi' | 'single' | 'open' | 'sentence' | null,
  answerPlaces: AnswerPlace,
  openEntryValue: string,
  multiValue: string[],
) => {
  const answerOption = answerPlaces?.answerOptions.find((answerOption) => {
    if (questionType === 'open') {
      return answerPlaces?.answerOptions?.some(
        (op) => op.answerId === openEntryValue,
      );
    } else {
      return answerOption.answerId === multiValue[0];
    }
  });
  return answerOption;
};
