import React from 'react';
import { Box, Button, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';

export const CoursesList = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
      }}
    >
      <List
        sx={{
          overflow: 'hidden',
        }}
      >
        <ListItem>
          <Button
            onClick={() => navigate(-1)}
            sx={{
              background: '#003366',
              color: '#fff',
              borderRadius: '10px',
              width: '100%',
              ':hover': {
                opacity: '0.5',
                background: '#003366',
              },
              padding: '10px 20px',
            }}
            startIcon={<UndoIcon />}
          >
            Classroom
          </Button>
        </ListItem>
      </List>
    </Box>
  );
};
