export interface ICurriculums {
  id: string;
  name: string;
  stateId: string;
  gradeId: string;
  preQuizId: string;
  postQuizId: string;
  curriculumPdfUrl: string;
  curriculumBreakoutPdfUrl: string;
  standardsAlignmentPdfUrl: string;
  studentLessonContentPdfUrl: string;
  questionBankUrl: string;
  pacingGuideUrl: string;
  courseType: CourseType;
  tileUIDisplay: string;
  tileUISortOrder: number;
  hasStudentContent: boolean;
  createdDate: Date;
  teacherTipsAndTricksUrl: string;
  newsRoundupUrl: string;
  vocabBankUrl: string;
  deletedDate: Date;
}
export interface IQuestionsDefinitions {
  quizId: string;
  quizName: string;
  questions: Question[];
}

export interface Question {
  questionId: string;
  questionTypeId: string;
  questionText: string;
  questionExplanation: string;
  sortOrder: number;
  answerPlaces: AnswerPlace[];
}

export interface AnswerPlace {
  answerPlaceId: string;
  answerOptions: AnswerOption[];
}

export interface AnswerOption {
  answerId: string;
  answerText: string;
  isCorrect: boolean;
}

export interface IStates {
  stateID: string;
  name: string;
  abbreviation: string;
}

export interface IGrades {
  gradeID: string;
  name: string;
  sortOrder: number;
}

export interface IDistricts {
  districtID: string;
  name: string;
  stateID: string;
}

export interface IResource {
  resourceID: string;
  resourceType: string;
  title: string;
  resourceURL: string;
  addressableGrades: string;
  topicsCovered: string;
  description?: string;
  createdDate: Date;
  deletedDate?: Date | null;
}

export interface IResourcesState {
  status: 'idle' | 'loading' | 'failed';
  curriculums: ICurriculums[] | null;
  preQuiz: IQuestionsDefinitions | null;
  postQuiz: IQuestionsDefinitions | null;
  states: IStates[] | null;
  grades: IGrades[] | null;
  externalUrls: IExternalUrls[];
  districts: IDistricts[] | null;
  classroomActivities: IResource[];
  childrenBooks: IResource[];
  videoResources: IResource[];
  additionalResources: IResource[];
  statusResourcesResponse: number | null | unknown;
  coachingVideos: IResource[];
  preBuiltAssessments: IResource[];
  worksheets: IResource[];
  careerVideos: IResource[];
  projects: IResource[];
}

export interface IExternalUrls {
  urlId: number;
  description: string;
  url: string;
}

export enum CourseType {
  AP_Course,
  Texas_Social_Studies,
  Texas_CTE,
  Texas_Math,
  Georgia_Social_Studies,
}
