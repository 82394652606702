import { Box, Paper } from '@mui/material';
import React from 'react';
import { IChatWindowsProps, IMessage } from '../../types';
import { Chat } from '../Chat/Chat';
import { DefaultChat } from '../DefaultChat';
import { NewChat } from '../NewChat';

export const ChatWindow = ({
  paperRef,
  aiChatHistory,
  groupedChatHistory,
  requestMessage,
}: IChatWindowsProps) => {
  return (
    <Paper
      ref={paperRef}
      elevation={3}
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: aiChatHistory ? 'column' : 'column-reverse',
        alignItems: 'center',
        m: 2,
        height: { xs: '65vh', sm: '60vh' },
        overflow: 'auto',
      }}
    >
      {groupedChatHistory.length > 0 ? (
        groupedChatHistory.map(
          (
            messages: { dateKey: string; messages: IMessage[] },
            index: number,
          ) => (
            <Chat
              key={index}
              currentDate={messages.dateKey}
              messages={messages.messages}
              isColumnReversed={!aiChatHistory}
            />
          ),
        )
      ) : (
        <DefaultChat />
      )}
      {requestMessage.length > 0 && (
        <NewChat
          currentDate={requestMessage[0]?.dateTime.toLocaleString()}
          messages={requestMessage}
          isColumnReversed={!aiChatHistory}
        />
      )}
      <Box sx={{ flex: 1 }} />
    </Paper>
  );
};
