import styled from 'styled-components';

export const FooterContainer = styled.div`
  background-color: #fff;
  bottom: 0;
  width: 100%;
`;

export const StFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem;
  font-size: 0.8rem;
`;

export const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: 5rem;
`;
