import { ICurriculums, IStates } from '../../../../redux/types/resourcesTypes';

export const filterStatesByStateIds = (
  curriculums: ICurriculums[] | null,
  states: IStates[] | null,
) => {
  const stateIdsSet = new Set(
    curriculums?.map((item: ICurriculums) => item.stateId),
  );

  return states?.filter((state: IStates) => stateIdsSet.has(state.stateID));
};
