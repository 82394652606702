import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ITeacherChangePassword,
  ITeacherProfile,
  ITeacherRegisterData,
  TeacherData,
  TeacherState,
} from '../types/teacherTypes';

// Define the initial state
const initialState: TeacherState = {
  teacherData: null,
  status: 'idle',
  statusResponse: null,
  replyFromChatGPT: null,
  aiChatHistory: null,
};

const API_URL = process.env.REACT_APP_API_URL;

export const fetchTeacherData = createAsyncThunk<TeacherData, string>(
  'teacher/fetchTeacherData',
  async (teacherId: string, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/teachers/${teacherId}`);
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return (await response.json()) as TeacherData;
  },
);

export const getTeacherDetails = createAsyncThunk(
  'teacher/getTeacherDetails',
  async (
    {
      teacherId,
      token,
    }: {
      teacherId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/teachers/${teacherId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return (await response.json()) as TeacherData;
  },
);

export const registerTeacher = createAsyncThunk(
  'teacher/registerTeacher',
  async (teacherData: ITeacherRegisterData, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/teachers/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(teacherData),
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return response.ok;
  },
);

export const resendRegistrationConfirmation = createAsyncThunk(
  'teacher/resendRegistrationConfirmation',
  async (email: string, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/teachers/resendRegistrationConfirmation`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);

export const forgotPassword = createAsyncThunk(
  'teacher/forgotPassword',
  async (email: string, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/teachers/forgotPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);

export const changePasswordTeacher = createAsyncThunk(
  'teacher/changePasswordTeacher',
  async (
    { oldPassword, newPassword, teacherId, token }: ITeacherChangePassword,
    { rejectWithValue },
  ) => {
    const payload = {
      newPassword,
      oldPassword,
    };
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/changePassword`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const removeStudentFromClassroom = createAsyncThunk(
  'teacher/removeStudentFromClassroom',
  async (
    {
      classroomId,
      studentId,
      token,
      teacherId,
    }: {
      classroomId: string;
      studentId: string;
      token: string | null;
      teacherId: string;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/students/${studentId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);

export const updateProfile = createAsyncThunk(
  'teacher/updateProfile',
  async (
    {
      firstName,
      lastName,
      stateId,
      districtId,
      schoolName,
      teacherId,
      token,
    }: ITeacherProfile,
    { rejectWithValue },
  ) => {
    const payload = {
      firstName,
      lastName,
      stateId,
      districtId,
      schoolName,
    };
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/updateProfile`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const teacherRefreshLogin = createAsyncThunk(
  'teacher/teacherRefreshLogin',
  async ({
    userId,
    refreshToken,
  }: {
    userId: string | null;
    refreshToken: string | null;
  }) => {
    const payload = {
      userId,
      refreshToken,
    };
    const response = await fetch(`${API_URL}/teachers/refreshLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return await response.json();
  },
);

export const confirmForgotPassword = createAsyncThunk(
  'teacher/confirmForgotPassword',
  async (
    {
      email,
      newPassword,
      confirmationCode,
    }: {
      email: string;
      newPassword: string;
      confirmationCode: string;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/teachers/confirmForgotPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, newPassword, confirmationCode }),
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);

export const deleteTeacherAccount = createAsyncThunk(
  'teacher/deleteTeacherAccount',
  async (
    { teacherId, token }: { teacherId: string | null; token: string | null },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/teachers/${teacherId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);
export const logoutTeacher = createAsyncThunk(
  'teacher/logoutTeacher',
  async (
    { teacherId, token }: { teacherId: string | null; token: string | null },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/teachers/${teacherId}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }

    return await response.json();
  },
);
export const addTeacherLicenseKey = createAsyncThunk(
  'teacher/addTeacherLicenseKey',
  async (
    {
      teacherId,
      licenseKey,
      token,
    }: { teacherId: string | null; licenseKey: string; token: string | null },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/licenseKeys/${licenseKey}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const handleAIChatMessage = createAsyncThunk(
  'teacher/handleAIChatMessage',
  async (
    {
      message,
      teacherId,
      token,
    }: { message: string; teacherId: string | null; token: string | null },
    { rejectWithValue },
  ) => {
    const payload = { requestMessageText: message };
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/aichat/message`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return await response.json();
  },
);

export const getAIChatHistory = createAsyncThunk(
  'teacher/getAIChatHistory',
  async (
    { teacherId, token }: { teacherId: string | null; token: string | null },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/aichat/messagehistory`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return await response.json();
  },
);

export const upgradeToPremiumPlanButtonClick = createAsyncThunk(
  'teacher/premium/upgradeattempt',
  async (
    {
      teacherId,
      subscriptionType,
      token,
    }: {
      teacherId: string;
      subscriptionType: number;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/premium/upgradeattempt/${subscriptionType}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const createStripeSession = createAsyncThunk(
  'teacher/premium/createCheckoutSession',
  async ({
    teacherId,
    token,
  }: {
    teacherId: string | null;
    token: string | null;
  }) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/premium/createCheckoutSession`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return await response.json();
  },
);

export const cancelStripeSubscription = createAsyncThunk(
  'teacher/premium/cancelSubscription',
  async (
    {
      teacherId,
      token,
      reason,
      details,
    }: {
      teacherId: string | null;
      token: string | null;
      reason: string | null;
      details: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/premium/cancelSubscription`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ reason, details }),
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const teacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTeacherData.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchTeacherData.fulfilled, (state, action) => {
      state.status = 'idle';
      state.teacherData = action.payload;
      state.statusResponse = null;
    });
    builder.addCase(fetchTeacherData.rejected, (state, action) => {
      state.status = 'failed';
      state.teacherData = null;
      state.statusResponse = action.payload;
    });
    builder.addCase(getTeacherDetails.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getTeacherDetails.fulfilled, (state, action) => {
      state.status = 'idle';
      state.teacherData = action.payload;
      state.statusResponse = null;
    });
    builder.addCase(getTeacherDetails.rejected, (state, action) => {
      state.status = 'failed';
      state.teacherData = null;
      state.statusResponse = action.payload;
    });
    builder.addCase(registerTeacher.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(registerTeacher.fulfilled, (state) => {
      state.status = 'idle';
    });
    builder.addCase(registerTeacher.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(resendRegistrationConfirmation.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(resendRegistrationConfirmation.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(
      resendRegistrationConfirmation.rejected,
      (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      },
    );
    builder.addCase(forgotPassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(changePasswordTeacher.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(changePasswordTeacher.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(changePasswordTeacher.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(removeStudentFromClassroom.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(removeStudentFromClassroom.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(removeStudentFromClassroom.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(updateProfile.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(teacherRefreshLogin.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(teacherRefreshLogin.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(teacherRefreshLogin.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = null;
    });
    builder.addCase(confirmForgotPassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(confirmForgotPassword.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(confirmForgotPassword.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(deleteTeacherAccount.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteTeacherAccount.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(deleteTeacherAccount.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(logoutTeacher.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(logoutTeacher.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(logoutTeacher.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(addTeacherLicenseKey.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addTeacherLicenseKey.fulfilled, (state) => {
      state.status = 'idle';
      state.statusResponse = null;
    });
    builder.addCase(addTeacherLicenseKey.rejected, (state, action) => {
      state.status = 'failed';
      state.statusResponse = action.payload;
    });
    builder.addCase(handleAIChatMessage.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(handleAIChatMessage.fulfilled, (state, action) => {
      state.status = 'idle';
      state.replyFromChatGPT = action.payload;
      state.statusResponse = null;
    });
    builder.addCase(handleAIChatMessage.rejected, (state, action) => {
      state.status = 'failed';
      state.replyFromChatGPT = null;
      state.statusResponse = action.payload;
    });
    builder.addCase(getAIChatHistory.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAIChatHistory.fulfilled, (state, action) => {
      state.status = 'idle';
      state.aiChatHistory = action.payload;
      state.statusResponse = null;
    });
    builder.addCase(getAIChatHistory.rejected, (state, action) => {
      state.status = 'failed';
      state.aiChatHistory = null;
      state.statusResponse = action.payload;
    });
  },
});

export default teacherSlice.reducer;
