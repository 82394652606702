import React from 'react';
import { getCareerVideos } from '../../../redux/slices/resourcesSlices';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

export const CareerVideos = () => {
  const { careerVideos } = useSelector((state: RootState) => state.resources);

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={careerVideos}
      curriculumId={curriculumId}
      getResourceAction={getCareerVideos}
      resourceTypeLabel={'classroomActivities'}
      label={'Career Videos'}
    />
  );
};
