import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import ShareIcon from '@mui/icons-material/Share';
import DescriptionIcon from '@mui/icons-material/Description';
import BadgeIcon from '@mui/icons-material/Badge';
import { ExternalUrlIdentifier } from './types';

export const OtherSidebarConfig = [
  {
    urlId: ExternalUrlIdentifier.AskAnExpert,
    label: 'Ask an Expert',
    icon: <BadgeIcon sx={{ color: '#003366' }} />,
    isPremium: true,
  },
  {
    urlId: ExternalUrlIdentifier.PlatformInstructions,
    label: 'Platform Instructions',
    icon: <DescriptionIcon sx={{ color: '#003366' }} />,
    isPremium: false,
  },
  {
    urlId: ExternalUrlIdentifier.EducatorReferral,
    label: 'Educator Referral Program',
    icon: <ShareIcon sx={{ color: '#003366' }} />,
    isPremium: false,
  },
  {
    urlId: ExternalUrlIdentifier.ContactUs,
    label: 'Contact Us',
    icon: <CallIcon sx={{ color: '#003366' }} />,
    isPremium: false,
  },
];
