import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Typography,
  Box,
  Avatar,
} from '@mui/material';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  resetRole,
} from '../../../redux/slices/authSlice';
import { Toast } from '../../../LibraryComponents/Toast';
import { RootState, AppDispatch } from '../../../redux/store';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { getUserDetails } from '../../../redux/slices/studentSlice';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { useGTM } from '../../../hooks/useGTM';
import studentLogo from '../../../Assets/Lemmy_Math.png';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LoginIcon from '@mui/icons-material/Login';
import { SubscriptionStatusType } from '../../../constants/SubscriptionStatusType';

const loginSchema = Yup.object().shape({
  userName: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const EnrolledStudentLogin = () => {
  const { pageView } = useGTM();
  const {
    status: loading,
    role: userRole,
    userId,
  } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const isResetSuccessfully: boolean = !!searchParams.get('reset');
  const API_URL = process.env.REACT_APP_API_URL;

  const [openToast, setOpenToast] = useState<{
    open: boolean;
    status: number | null;
    severity: 'error' | 'success';
    msg: string;
  }>({ open: false, status: null, severity: 'error', msg: '' });
  const [status, setStatus] = useState<number | null>(null);

  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));

  const handleSelectionRoles = () => {
    dispatch(resetRole());
    navigate(routeIDs.ROLE_SELECTION_SCREEN);
  };

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      dispatch(loginStart());
      try {
        const response = await axios.post(`${API_URL}/students/login`, {
          userName: values.userName,
          password: values.password,
        });
        if (response.data) {
          dispatch(
            loginSuccess({
              userId: response.data.studentId,
              token: response.data.token,
              role: 'student',
              accessToken: '',
              refreshToken: '',
              userIsPremium: false,
              userSubscriptionStatus: SubscriptionStatusType.None,
              lemoneyClassroomExpirationDate: null,
            }),
          );
          dispatch(
            getUserDetails({
              username: values.userName,
              token: response.data.token,
            }),
          );
          navigate(routeIDs.STUDENT);
        }
      } catch (error: any) {
        const PASSWORD_RESET_PENDING = 'Password reset pending.';
        if (error && error?.response?.data === PASSWORD_RESET_PENDING) {
          dispatch(loginFailure());
          return navigate(`${routeIDs.STUDENT_RESET_PASSWORD}?reset=pending`);
        }
        setOpenToast({
          open: true,
          status: error?.response?.status || status,
          msg: '',
          severity: 'error',
        });
        if (axios.isAxiosError(error) && error.response) {
          setStatus(error.response.data.title);
        } else {
          setStatus(500);
        }
        dispatch(loginFailure());
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    pageView('/login');
  }, [dispatch]);

  useEffect(() => {
    if (userRole === 'student' && userId) {
      navigate('/student');
    } else if (userRole === 'teacher' && userId) {
      navigate('/');
    }
  }, [navigate, userRole]);

  useEffect(() => {
    if (isResetSuccessfully) {
      setOpenToast({
        open: true,
        severity: 'success',
        status: null,
        msg: 'Password Successfully Reset',
      });
    }
  }, [isResetSuccessfully]);

  return (
    <Box
      sx={{
        margin: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: { xs: '100vh', sm: '85vh' },
      }}
    >
      {loading === 'loading' && <Spinner />}
      <Grid
        container
        sx={{
          marginTop: { md: 8, sm: 1, xs: 0 },
          marginBottom: { md: 8, sm: 1 },
          maxWidth: '500px',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '10px',
        }}
      >
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: { xs: 0, sm: 0 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#003366',
              width: '100%',
              borderRadius: '10px 10px 0 0',
              minHeight: '140px',
              position: 'relative',
              mb: '60px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mt: { xs: 0, sm: 1.5 },
                textAlign: 'center',
                fontWeight: '600',
                color: '#fff',
              }}
            >
              Student Login
            </Typography>
            <Box sx={{ position: 'absolute', top: '100px' }}>
              <Avatar
                sx={{
                  m: 'auto',
                  width: 80,
                  height: 80,
                }}
              >
                <Box sx={{ marginLeft: '6px', marginTop: '5px' }}>
                  <img
                    src={studentLogo}
                    alt="educator-logo"
                    width={120}
                    height={120}
                  />
                </Box>
              </Avatar>
              <Typography
                variant="body2"
                color="#9C27B0"
                onClick={handleSelectionRoles}
                sx={{ cursor: 'pointer' }}
              >
                Switch to Educator Login
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: { ms: '50%', md: '100%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component={'form'}
              onSubmit={formik.handleSubmit}
              sx={{
                width: { xs: '300px', md: '400px' },
                p: '20px',
              }}
            >
              <FormsControl
                control="input"
                type="text"
                name="userName"
                label="Username"
                formik={formik}
              />
              <FormsControl
                control="input"
                type="password"
                name="password"
                label="Password"
                formik={formik}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '300px', md: '400px' },
                }}
              >
                <Toast
                  isOpen={openToast.open}
                  severity={openToast.severity}
                  onClose={() => closeToast(true)}
                  styles={{ width: '100%', textAlign: 'center', top: 10 }}
                  status={openToast.status}
                  errorMessage={openToast.msg}
                />
              </Box>
              <FormControl fullWidth sx={{ mb: 1.5 }} variant="outlined">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    fontSize: '16px',
                    fontWeight: 700,
                    bgcolor: '#003366',
                  }}
                  endIcon={<LoginIcon />}
                >
                  Login
                </Button>
              </FormControl>
              <Divider
                variant="fullWidth"
                sx={{ margin: '10px', width: '100%' }}
              />
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2" color={'inherit'}>
                  Don't have an account yet?
                </Typography>
              </Box>
              <FormControl
                fullWidth
                sx={{ mt: 1, marginBottom: { xs: '30px', md: 1 } }}
                variant="outlined"
              >
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 2,
                    fontSize: '16px',
                    fontWeight: 700,
                    bgcolor: '#003366',
                  }}
                  onClick={() =>
                    navigate(`${routeIDs.CREATE_ACCOUNT}?role=student`)
                  }
                  endIcon={<PersonAddAlt1Icon />}
                >
                  Create account
                </Button>
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: { xs: '20px', md: '0' },
          }}
        ></Grid>
      </Grid>
    </Box>
  );
};
