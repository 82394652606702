import { QuestionType } from '../../../../constants/QuestionType';
import {
  AnswerOption,
  AnswerPlace,
} from '../../../../redux/types/lessonsTypes';
import DOMPurify from 'dompurify';

export const handleQuestionType = (questionTypeId: string) => {
  switch (questionTypeId) {
    case QuestionType.MultiSelect:
      return 'multi';
    case QuestionType.SingleSelect:
      return 'single';
    case QuestionType.OpenEntry:
      return 'open';
    case QuestionType.SentenceCompletion:
      return 'sentence';
    default:
      return null;
  }
};

export const findAnswerOption = (
  questionType: 'multi' | 'single' | 'open' | 'sentence' | null,
  openEntryValue: string,
  multiValue: string[],
  answerPlaces: AnswerPlace,
) => {
  const answerOption = answerPlaces?.answerOptions.find(
    (answerOption: AnswerOption) => {
      if (questionType === 'open') {
        return answerPlaces?.answerOptions?.some(
          (op) => op.answerId === openEntryValue,
        );
      } else {
        return answerOption.answerId === multiValue[0];
      }
    },
  );
  return answerOption;
};

export const handleSaveDataSentence = (
  currentQuestion: any,
  multiValue: string[],
) => {
  const orderAnswerPlaces = currentQuestion.sortOrder || 0;
  let newMultiValue;
  if (orderAnswerPlaces === 1) {
    newMultiValue = multiValue.reverse();
  } else {
    newMultiValue = multiValue;
  }
  let dataAnswer;
  if (multiValue?.length > 1) {
    dataAnswer = {
      answerPlaceAnswers: [
        {
          answerPlaceId: currentQuestion.answerPlaceId,
          studentAnswers: [
            {
              answerId: newMultiValue[0],
              openEntryAnswerText: null,
            },
          ],
        },
        {
          answerPlaceId: currentQuestion.answerPlaceId,
          studentAnswers: [
            {
              answerId: newMultiValue[1],
              openEntryAnswerText: null,
            },
          ],
        },
      ],
    };
  } else {
    dataAnswer = {
      answerPlaceAnswers: [
        {
          answerPlaceId: currentQuestion.answerPlaceId,
          studentAnswers: [
            {
              answerId: multiValue[0],
              openEntryAnswerText: null,
            },
          ],
        },
      ],
    };
  }

  return dataAnswer;
};

export const answerOptionMultivalued = (
  answerPlace: AnswerPlace[],
  multiValue: string[],
) => {
  const op1 = answerPlace[0]?.answerOptions?.find(
    (op) => op.answerId === multiValue[0],
  );
  const op2 = answerPlace[0]?.answerOptions?.find(
    (op) => op.answerId === multiValue[1],
  );

  return [
    {
      answerId: op1?.answerId as string,
      openEntryAnswerText: '',
    },
    {
      answerId: op2?.answerId as string,
      openEntryAnswerText: '',
    },
  ];
};

export const handleSaveDataMultiOpen = ({
  answerPlace,
  questionType,
  answerOption,
  multiValue,
}: {
  answerPlace: any;
  questionType: 'multi' | 'single' | 'open' | 'sentence' | null;
  answerOption: string;
  multiValue: string[];
}) => {
  const data: any = {
    answerPlaceAnswers: [
      {
        answerPlaceId: answerPlace[0].answerPlaceId,
        studentAnswers:
          questionType === 'multi'
            ? answerOptionMultivalued(answerPlace, multiValue)
            : [
                {
                  answerId: answerOption,
                  openEntryAnswerText:
                    questionType === 'open'
                      ? multiValue[0].replace(/,/g, '')
                      : '',
                },
              ],
      },
    ],
  };

  return data;
};

export const sanitizeHTML = (html: string) => {
  return {
    __html: DOMPurify.sanitize(html, {
      ALLOWED_TAGS: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'img',
        'font',
        'b',
        'i',
        'u',
        'br',
        'p',
        'table',
        'tr',
        'td',
        'th',
        'tbody',
        'thead',
      ],
      ALLOWED_ATTR: ['src', 'alt', 'style', 'size'],
    }),
  };
};

export const hasHTMLTags = (text: string | null) => {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return text ? htmlRegex.test(text) : false;
};
