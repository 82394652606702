import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Typography, Button, Box, CircularProgress } from '@mui/material';
import lemmy from '../../Assets/Lemmy_Base.png';
import { useDispatch } from 'react-redux';
import { resendRegistrationConfirmation } from '../../redux/slices/teacherSlice';
import { AppDispatch } from '../../redux/store';
import { useGTM } from '../../hooks/useGTM';
import { routeIDs } from '../../Routes/RouteIds.routes';

export const ThankYouScreen = () => {
  const { pageView } = useGTM();
  const [searchParams] = useSearchParams();
  const lastPath = searchParams.get('lastPath');
  const email = searchParams.get('email');
  const dispatch = useDispatch<AppDispatch>();

  const [seconds, setSeconds] = useState(30);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    pageView('/thankYouScreen');
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [seconds]);

  const formattedSeconds = seconds % 60;

  const handleResendRegistrationConfirmation = () => {
    setLoading(true);
    try {
      if (email) {
        dispatch(resendRegistrationConfirmation(email))
          .then(() => setLoading(false))
          .catch((err) => {
            setLoading(false);
            throw new Error(err);
          });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box
        component={'img'}
        src={lemmy}
        lang="img-lemmy"
        width={150}
        marginBottom={4}
      />
      <Typography variant="h4" align="center" gutterBottom color="#003366">
        Thank you for registering!
      </Typography>
      <Box sx={{ maxWidth: '700px', mb: 1 }}>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{ margin: '0 20px' }}
          paragraph
        >
          {lastPath === 'educator' ? (
            <>
              An email has been sent to your registered email address "{email}".
              <br />
              Please click on the verification link.
            </>
          ) : (
            'Click the button below to login to your account.'
          )}
        </Typography>
      </Box>

      {lastPath === 'educator' && (
        <>
          {seconds !== 0 && (
            <Box>
              <Typography variant="h6" color={'#9C27B0'}>
                Resend verification email is available in:
              </Typography>
              <Typography variant="h3" align="center" color="#003366">
                {formattedSeconds.toString().padStart(2, '0')}
              </Typography>
            </Box>
          )}
          <Box sx={{ width: '300px', margin: '20px auto 0 ' }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={seconds !== 0 || loading}
              onClick={handleResendRegistrationConfirmation}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                'Resend Verification Email'
              )}
            </Button>
          </Box>
        </>
      )}
      <Box sx={{ width: '300px', margin: '20px auto' }}>
        <Button
          fullWidth
          component={Link}
          to={`${routeIDs.LOGIN}?role=student`}
          variant="contained"
          color="primary"
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};
