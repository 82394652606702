import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionStatusType } from '../../constants/SubscriptionStatusType';

interface AuthState {
  userId: string | null;
  token: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  role: string | null;
  userIsPremium: boolean;
  userSubscriptionStatus: SubscriptionStatusType;
  lemoneyClassroomExpirationDate: Date | null;
  status: 'idle' | 'loading' | 'failed' | null;
}

const initialState: AuthState = {
  userId: null,
  token: null,
  accessToken: null,
  refreshToken: null,
  role: null,
  userIsPremium: false,
  userSubscriptionStatus: SubscriptionStatusType.None,
  lemoneyClassroomExpirationDate: null,
  status: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state) => {
      state.status = 'loading';
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        userId: string;
        token: string;
        accessToken: string;
        role: string;
        refreshToken: string;
        userIsPremium: boolean;
        userSubscriptionStatus: SubscriptionStatusType;
        lemoneyClassroomExpirationDate: Date | null;
      }>,
    ) => {
      state.status = 'idle';
      state.userId = action.payload.userId;
      state.token = action.payload.token;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.role = action.payload.role;
      state.userIsPremium = action.payload.userIsPremium;
      state.userSubscriptionStatus = action.payload.userSubscriptionStatus;
      state.lemoneyClassroomExpirationDate =
        action.payload.lemoneyClassroomExpirationDate;
    },
    loginFailure: (state) => {
      state.status = 'failed';
      state.userId = null;
      state.token = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = null;
      state.userIsPremium = false;
      state.userSubscriptionStatus = SubscriptionStatusType.None;
      state.lemoneyClassroomExpirationDate = null;
    },
    loginGuest: (state) => {
      state.status = 'idle';
      state.userId = null;
      state.token = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = 'guest';
    },
    logout: (state) => {
      state.userId = null;
      state.token = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = null;
      state.userIsPremium = false;
      state.userSubscriptionStatus = SubscriptionStatusType.None;
      state.lemoneyClassroomExpirationDate = null;
    },
    resetRole: (state) => {
      state.role = null;
    },
    loginRefresh: (
      state,
      action: PayloadAction<{
        userId: string;
        accessToken: string;
        refreshToken: string;
        userIsPremium: boolean;
        userSubscriptionStatus: SubscriptionStatusType;
        lemoneyClassroomExpirationDate: Date | null;
      }>,
    ) => {
      state.status = 'idle';
      state.userId = action.payload.userId;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userIsPremium = action.payload.userIsPremium;
      state.userSubscriptionStatus = action.payload.userSubscriptionStatus;
      state.lemoneyClassroomExpirationDate =
        action.payload.lemoneyClassroomExpirationDate;
    },
    roleSelection: (
      state,
      action: PayloadAction<{
        role: string | null;
      }>,
    ) => {
      state.role = action.payload.role;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  loginGuest,
  resetRole,
  loginRefresh,
  roleSelection,
} = authSlice.actions;

export default authSlice.reducer;
