import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalUrlIdentifier } from '../../Pages/ClassroomTile/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const ContactUs = () => {
  const externalUrls = useSelector(
    (state: RootState) => state.resources.externalUrls,
  );
  const handleExternalUrl = () => {
    const foundUrl = externalUrls.find(
      (url) => url.urlId === ExternalUrlIdentifier.ContactUs,
    );
    return foundUrl?.url ?? '';
  };
  return (
    <Typography
      component={Link}
      to={handleExternalUrl()}
      target="_blank"
      sx={{
        textDecoration: 'none',
        color: '#1976d2',
        fontWeight: 'inherit',
        fontSize: 'inherit',
      }}
    >
      Contact Us
    </Typography>
  );
};
