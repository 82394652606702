import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { CourseType, ICurriculums } from '../../redux/types/resourcesTypes';
import { debounce } from 'lodash';
import { getCurriculums } from '../../redux/slices/resourcesSlices';
import { Spinner } from '../../LibraryComponents/Spinner';
import { CourseTileSection } from './ClassroomTileSection';

export const ClassroomRedesign = () => {
  const { curriculums } = useSelector((state: RootState) => state.resources);
  const TeacherData = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );

  const dispatch = useDispatch<AppDispatch>();

  const [curriculumsData, setCurriculumsData] = useState(curriculums);
  const debouncedGetCurriculums = debounce(
    () =>
      dispatch(getCurriculums())
        .then()
        .catch((err) => console.error(err)),
    500,
  );

  const compareByName = (a: ICurriculums, b: ICurriculums) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (curriculums?.length === 0) {
      debouncedGetCurriculums();
    }
  }, []);

  useEffect(() => {
    if (curriculums && curriculums.length > 0) {
      const sortedData = [...curriculums].sort(compareByName);
      setCurriculumsData(sortedData);
    }
  }, [curriculums]);

  const apCourses = curriculumsData?.filter(
    (curriculum) => curriculum.courseType === CourseType.AP_Course,
  );

  const texasSSCourses = curriculumsData?.filter(
    (curriculum) => curriculum.courseType === CourseType.Texas_Social_Studies,
  );

  const texasCTECourses = curriculumsData?.filter(
    (curriculum) => curriculum.courseType === CourseType.Texas_CTE,
  );

  const texasMathUnits = curriculumsData?.filter(
    (curriculum) => curriculum.courseType === CourseType.Texas_Math,
  );

  const georgiaSSCourses = curriculumsData?.filter(
    (curriculum) => curriculum.courseType === CourseType.Georgia_Social_Studies,
  );

  const name = TeacherData
    ? TeacherData.firstName + ' ' + TeacherData.lastName
    : '';

  return (
    <Box>
      {(!curriculums || curriculums?.length === 0) && <Spinner />}
      <Typography
        variant="h5"
        sx={{
          textAlign: 'center',
          mb: '30px',
          m: '20px auto',
          fontWeight: 700,
          fontSize: { md: '24px', xs: '16px' },
        }}
      >
        Explore all resources by course!
      </Typography>

      {apCourses && apCourses.length > 0 && (
        <CourseTileSection coursesList={apCourses} courseTitle="AP Courses:" />
      )}

      {texasSSCourses && texasSSCourses.length > 0 && (
        <CourseTileSection
          coursesList={texasSSCourses}
          courseTitle="Texas Social Studies Courses:"
        />
      )}

      {texasCTECourses && texasCTECourses.length > 0 && (
        <CourseTileSection
          coursesList={texasCTECourses}
          courseTitle="Texas CTE Courses:"
        />
      )}

      {texasMathUnits && texasMathUnits.length > 0 && (
        <CourseTileSection
          coursesList={texasMathUnits}
          courseTitle="Texas Math Units:"
        />
      )}

      {georgiaSSCourses && georgiaSSCourses.length > 0 && (
        <CourseTileSection
          coursesList={georgiaSSCourses}
          courseTitle="Georgia Social Studies Courses:"
        />
      )}
    </Box>
  );
};
