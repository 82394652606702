import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Button, Box, Container } from '@mui/material';
import lemmy from '../../Assets/Lemmy_Celebrating.png';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { ContactUs } from '../../LibraryComponents/ContactUs';
import { teacherRefreshLogin } from '../../redux/slices/teacherSlice';
import { RootState, useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { loginRefresh } from '../../redux/slices/authSlice';
import { Spinner } from '../../LibraryComponents/Spinner';

export const SelfCheckoutUnderConstruction = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { userId, refreshToken } = useSelector(
    (state: RootState) => state.auth,
  );

  const homeButtonClicked = () => {
    navigate(routeIDs.EDUCATOR_HOME);
  };

  useEffect(() => {
    const refreshLogin = async () => {
      setIsLoading(true);
      const teacherRefresh = await dispatch(
        teacherRefreshLogin({ userId: userId, refreshToken }),
      );
      const response = await dispatch(loginRefresh(teacherRefresh.payload));
      setIsLoading(false);
    };
    refreshLogin();
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          component={'img'}
          src={lemmy}
          lang="img-lemmy"
          width={200}
          marginBottom={4}
        />
        <Typography variant="h4" align="center" gutterBottom color="#003366">
          Lemoney Learning Premium Unlocked!
        </Typography>
        <Box sx={{ maxWidth: '900px', mb: 1 }}>
          <Typography
            variant="body1"
            align="center"
            gutterBottom
            sx={{ margin: '0 20px' }}
            paragraph
          >
            Thank you for subscribing to Lemoney Learning Premium! Feel free to{' '}
            <ContactUs /> with any questions or concerns!
            <br />
          </Typography>
        </Box>
        <Box sx={{ width: '300px', margin: '20px auto' }}>
          <Button
            fullWidth
            onClick={() => homeButtonClicked()}
            variant="contained"
            color="primary"
          >
            Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
