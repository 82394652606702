import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import lemmy from '../../Assets/Lemmy_Base.png';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getExternalUrls } from '../../redux/slices/resourcesSlices';
import { ExternalUrlIdentifier } from '../../GlobalComponents/Sidebar/types';
import { debounce } from 'lodash';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { IAboutProps } from './types';

export const About = ({ setShowAbout }: IAboutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const externalUrls = useSelector(
    (state: RootState) => state.resources.externalUrls,
  );
  const debouncedGetExternalUrls = debounce(
    () => dispatch(getExternalUrls()),
    500,
  );

  const MAJOR_VERSION = process.env.REACT_APP_MAJOR_VERSION;
  const MINOR_VERSION = process.env.REACT_APP_MINOR_VERSION;
  const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION;

  useEffect(() => {
    if (externalUrls.length === 0) {
      debouncedGetExternalUrls();
    }
  }, [debouncedGetExternalUrls, externalUrls.length]);

  const handleExternalUrl = () => {
    const foundUrl = externalUrls.find(
      (url) => url.urlId === ExternalUrlIdentifier.ContactUs,
    );
    return foundUrl?.url ?? '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '500px',
        margin: 'auto',
      }}
    >
      <Box
        component={'img'}
        src={lemmy}
        alt="lemmy"
        sx={{ width: { xs: '70px', sm: '120px' } }}
      />

      <Typography
        variant="h5"
        sx={{
          margin: { xs: '20px 0 20px 0', sm: '20px 0 40px 0' },
          fontWeight: 700,
          color: '#7F7F7F',
          fontSize: { xs: '1.3rem', sm: '1.5rem' },
        }}
      >
        Lemoney Learning Version {MAJOR_VERSION}.{MINOR_VERSION}.
        {RELEASE_VERSION}
      </Typography>
      <Box>
        <Typography
          variant="body1"
          sx={{
            marginBottom: '20px',
            fontWeight: 600,
            textAlign: { xs: 'center', sm: 'start' },
          }}
        >
          The use of this product is subject to the following agreements:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: { xs: 'center', sm: 'start' },
            width: '100%',
            marginLeft: { xs: '0', sm: '20px' },
          }}
        >
          <Typography
            component={Link}
            data-testid="privacy-policy-link"
            to={routeIDs.PRIVACY_POLICY}
            sx={{
              marginBottom: '10px',
              textDecoration: 'none',
              fontWeight: 600,
              color: '#1976d2',
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            component={Link}
            data-testid="terms-of-service-link"
            to={routeIDs.TERM_AND_CONDITION}
            sx={{
              marginBottom: '10px',
              textDecoration: 'none',
              fontWeight: 600,
              color: '#1976d2',
            }}
          >
            Terms of Service
          </Typography>
          <Typography
            component={Link}
            data-testid="cookie-policy-link"
            to={routeIDs.COOKIE_POLICY}
            sx={{
              marginBottom: '10px',
              textDecoration: 'none',
              fontWeight: 600,
              color: '#1976d2',
            }}
          >
            Cookie Use Notice
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            marginTop: '20px',
            fontWeight: 600,
            textAlign: { xs: 'center', sm: 'start' },
          }}
        >
          We're grateful for all of our users and immensely value your feedback!
          Please{' '}
          <Typography
            component={Link}
            to={handleExternalUrl()}
            target="_blank"
            sx={{ textDecoration: 'none', color: '#1976d2' }}
          >
            Contact Us
          </Typography>{' '}
          with any suggestions or feature requests.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <Button data-testid="done-button" onClick={() => setShowAbout(false)}>
            DONE
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
