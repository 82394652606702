import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardData } from '../../Pages/ClassroomDashboard/types';
import { Classroom } from '../../Pages/Classroom/ClassroomOverview/types';

interface ClassroomState {
  status: 'idle' | 'loading' | 'failed';
  defaultDataCreateClassroom: { grade: string; curriculum: string };
  statusResponse: number | null | unknown;
  dashboardData: DashboardData | null;
  classrooms: Classroom[] | null;
}

const initialState: ClassroomState = {
  status: 'idle',
  defaultDataCreateClassroom: { grade: '', curriculum: '' },
  statusResponse: null,
  dashboardData: null,
  classrooms: null,
};

const API_URL = process.env.REACT_APP_API_URL;

export const removeClassroom = createAsyncThunk(
  'classroom/removeClassroom',
  async (
    {
      teacherId,
      classroomId,
      token,
    }: {
      teacherId: string;
      classroomId: string;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const renameClassroom = createAsyncThunk(
  'classroom/renameClassroom',
  async (
    {
      teacherId,
      classroomId,
      newName,
      token,
    }: {
      teacherId: string;
      classroomId: string;
      newName: string;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/rename`,
      {
        method: 'POST',
        body: JSON.stringify({ newName }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const lockClassroom = createAsyncThunk(
  'classroom/lockClassroom',
  async (
    {
      teacherId,
      classroomId,
      token,
    }: {
      teacherId: string;
      classroomId: string;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/lock`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const unlockClassroom = createAsyncThunk(
  'classroom/unlockClassroom',
  async (
    {
      teacherId,
      classroomId,
      token,
    }: {
      teacherId: string;
      classroomId: string;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/unlock`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const saveDataCreateClassroom = createAsyncThunk(
  'classroom/saveDataCreateClassroom',
  async ({ grade, curriculum }: { grade: any; curriculum: any }) => {
    return { grade, curriculum };
  },
);

export const resetStudentPassword = createAsyncThunk(
  'classroom/resetStudentPassword',
  async (
    {
      teacherId,
      classroomId,
      studentId,
      token,
    }: {
      studentId: string;
      teacherId: string | null;
      classroomId: string;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/students/${studentId}/resetPassword`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (!response.ok) {
        return rejectWithValue(response.status);
      }
      return response.ok;
    } catch (error) {
      return error;
    }
  },
);

export const editStudentClassroom = createAsyncThunk(
  'classroom/editStudentClassroom',
  async (
    {
      teacherId,
      classroomId,
      studentId,
      token,
      firstName,
      lastName,
    }: {
      studentId: string;
      teacherId: string | null;
      classroomId: string;
      token: string | null;
      firstName: string;
      lastName: string;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      firstName,
      lastName,
    };
    try {
      const response = await fetch(
        `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/students/${studentId}/rename`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      );
      if (!response.ok) {
        return rejectWithValue(response.status);
      }
      return response.ok;
    } catch (error) {
      return error;
    }
  },
);

export const getClassroomDashboard = createAsyncThunk(
  'classroom/getClassroomDashboard',
  async (
    {
      teacherId,
      classroomId,
      token,
    }: {
      teacherId: string | null;
      classroomId: string | undefined;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms/${classroomId}/dashboard`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return (await response.json()) as DashboardData;
  },
);

export const getClassrooms = createAsyncThunk(
  'classroom/getClassrooms',
  async (
    {
      teacherId,
      token,
    }: {
      teacherId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return (await response.json()) as Classroom[];
  },
);

export const createClassroom = createAsyncThunk(
  'classroom/createClassroom',
  async (
    {
      teacherId,
      curriculumId,
      token,
      classroomName,
      stateId,
      gradeId,
    }: {
      teacherId: string | null;
      curriculumId: string | undefined;
      token: string | null;
      stateId: string | undefined;
      gradeId: string;
      classroomName: string;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      classroomName,
      curriculumId,
      stateId,
      gradeId,
    };
    const response = await fetch(
      `${API_URL}/teachers/${teacherId}/classrooms`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
  },
);

export const classroomSlice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeClassroom.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(removeClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(renameClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(renameClassroom.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(renameClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(lockClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(lockClassroom.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(lockClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(unlockClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(unlockClassroom.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(unlockClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(saveDataCreateClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveDataCreateClassroom.fulfilled, (state, action) => {
        state.status = 'idle';
        state.defaultDataCreateClassroom = action.payload;
      })
      .addCase(saveDataCreateClassroom.rejected, (state) => {
        state.status = 'failed';
        state.defaultDataCreateClassroom = { grade: '', curriculum: '' };
      })
      .addCase(resetStudentPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetStudentPassword.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(resetStudentPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(editStudentClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editStudentClassroom.fulfilled, (state) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(editStudentClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      })
      .addCase(getClassroomDashboard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getClassroomDashboard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusResponse = null;
        state.dashboardData = action.payload;
      })
      .addCase(getClassroomDashboard.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
        state.dashboardData = null;
      })
      .addCase(getClassrooms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getClassrooms.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusResponse = null;
        state.classrooms = action.payload;
      })
      .addCase(getClassrooms.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
        state.classrooms = null;
      })
      .addCase(createClassroom.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createClassroom.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusResponse = null;
      })
      .addCase(createClassroom.rejected, (state, action) => {
        state.status = 'failed';
        state.statusResponse = action.payload;
      });
  },
});

export default classroomSlice.reducer;
