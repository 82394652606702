import React from 'react';
import { useSelector } from 'react-redux';
import { getClassroomActivities } from '../../../redux/slices/resourcesSlices';
import { RootState } from '../../../redux/store';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const ClassroomActivities = () => {
  const { classroomActivities } = useSelector(
    (state: RootState) => state.resources,
  );

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={classroomActivities}
      curriculumId={curriculumId}
      getResourceAction={getClassroomActivities}
      resourceTypeLabel={'classroomActivities'}
      label="Classroom Activities"
    />
  );
};
