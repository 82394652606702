import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCurriculums, getStates } from '../../redux/slices/resourcesSlices';
import { AppDispatch, RootState } from '../../redux/store';
import { ICurriculums, IStates } from '../../redux/types/resourcesTypes';
import { debounce } from 'lodash';
import { Spinner } from '../Spinner';
import { IPDFLinkViewerProps } from './types';

export const PDFLinkViewer = ({ onCancel, typePdf }: IPDFLinkViewerProps) => {
  const { curriculums, states } = useSelector(
    (state: RootState) => state.resources,
  );

  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState<
    | {
        name: string;
        stateID: string;
        abbreviation: string;
      }
    | undefined
  >({
    name: '',
    stateID: '',
    abbreviation: '',
  });
  const [curriculum, setCurriculum] = useState<ICurriculums[] | null>();

  const debouncedGetCurriculums = debounce(
    () =>
      dispatch(getCurriculums())
        .then()
        .catch((err) => console.error(err)),
    500,
  );

  const debouncedGetStates = debounce(
    () =>
      dispatch(getStates())
        .then()
        .catch((err) => console.error(err)),
    500,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setState(states?.find((state) => state.stateID === e?.target?.value));
  };

  useEffect(() => {
    if (!curriculums || curriculums.length === 0) debouncedGetCurriculums();
  }, []);

  useEffect(() => {
    if (!states) debouncedGetStates();
  }, []);

  const description = {
    curriculumPdfUrl:
      'Lemoney Learning provides tailored instructional materials and pacing guides to support classroom instruction. Choose your state below to see available instructional materials!',
    standardsAlignmentPdfUrl:
      'Lemoney Learning provides tailored courses to support local state standards. Choose your state below to see how the available courses map to your standards.',
  };
  useEffect(() => {
    setCurriculum(
      curriculums?.filter(
        (curriculum) => curriculum.stateId === state?.stateID,
      ),
    );
  }, [curriculums, state?.stateID]);

  const curriculumList = curriculum?.sort((a: ICurriculums, b: ICurriculums) =>
    a.name.localeCompare(b.name),
  );

  const filterStatesByStateIds = () => {
    const stateIdsSet = new Set(
      curriculums?.map((item: ICurriculums) => item.stateId),
    );
    return states?.filter((state: IStates) => stateIdsSet.has(state.stateID));
  };
  const filterStates = filterStatesByStateIds() || null;

  useEffect(() => {
    if (filterStates) setState(filterStates[0]);
  }, [filterStates]);

  const handleLabel = () => {
    const labelMap = {
      standardsAlignmentPdfUrl: 'Standards Alignment',
      curriculumPdfUrl: 'Instructional Materials',
      studentLessonContentPdfUrl: 'Student Lesson Content',
      pacingGuideUrl: 'Pacing Guides',
      //questionBankUrl: 'Assessment Question Bank',
    };

    return (
      labelMap[
        typePdf as
          | 'standardsAlignmentPdfUrl'
          | 'curriculumPdfUrl'
          | 'studentLessonContentPdfUrl'
          | 'pacingGuideUrl'
        //          | 'questionBankUrl'
      ] || ''
    );
  };

  return (
    <Box>
      <Typography
        variant="h4"
        mb={2}
        sx={{
          background: '#003366',
          color: '#fff',
          textAlign: 'center',
          p: '20px 0',
          borderRadius: '5px 5px 0 0',
        }}
      >
        {handleLabel()}
      </Typography>
      <Box sx={{ padding: '0 40px', textAlign: 'center' }}>
        {!filterStates && <Spinner />}
        <Typography color={'#003366'}>{handleLabel()}</Typography>
        <Typography>
          {
            description[
              typePdf as 'standardsAlignmentPdfUrl' | 'curriculumPdfUrl'
            ]
          }
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent={'space-around'}>
        <Grid item xs={5}>
          {filterStates && (
            <TextField
              fullWidth
              margin="dense"
              select
              id="state"
              name="state"
              label="State"
              value={state?.stateID || ''}
              onChange={handleChange}
            >
              {filterStates?.map((option) => (
                <MenuItem key={option.stateID} value={option.stateID}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          margin: '20px',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '5px',
          padding: '20px',
          maxHeight: '486px',
          overflow: 'auto',
        }}
      >
        <Typography
          data-testid="standards-alignment-event"
          sx={{
            textAlign: 'center',
            color: '#fff',
            background: '#003366',
            p: '10px',
            fontWeight: 600,
          }}
        >
          Course List
        </Typography>
        <List data-testid="course-list">
          {curriculumList?.map((curriculum: any) => (
            <ListItem
              data-testid="course-list-item"
              disablePadding
              key={curriculum.id}
            >
              <Link
                href={curriculum[typePdf]}
                target="_blank"
                sx={{
                  textDecoration: 'none',
                  color: '#003366',
                }}
              >
                <ListItemText primary={curriculum.name} />
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          sx={{
            color: '#003366',
            width: '10rem',
            marginRight: '1rem',
            borderColor: '#003366',
          }}
          variant="outlined"
          data-testid="close-button"
          onClick={onCancel}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};
