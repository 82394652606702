import React, { useEffect, useState } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import studentLemmy from '../../../Assets/Lemmy_Tech.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserDetails,
  studentJoinClassroom,
} from '../../../redux/slices/studentSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { Toast } from '../../../LibraryComponents/Toast';
import { useGTM } from '../../../hooks/useGTM';

export const OTPClassCode = () => {
  const { pageView } = useGTM();
  const { userId, token } = useSelector((state: RootState) => state.auth);
  const username = useSelector(
    (state: RootState) => state.student.studentData?.userName,
  );
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const closeToast = (isOpen: boolean) =>
    isOpen ? setOpen(false) : setOpen(true);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  useEffect(() => {
    pageView('/student/otpClassCode');
  }, []);

  const handleClassJoin = async () => {
    dispatch(
      studentJoinClassroom({ studentId: userId, token, classroomCode: otp }),
    )
      .then((res: any) => {
        if (res.error.message === '404') {
          setErrorMessage('check your classroom code');
          setOpen(true);
        } else {
          dispatch(getUserDetails({ username, token }));
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box
      sx={{
        background: '#194AA6',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        paddingTop: '0.1px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            maxWidth: '500px',
            margin: '20px auto',
            background: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
          }}
        >
          <Box
            maxWidth={'350px'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              minHeight: '600px',
            }}
          >
            <Box sx={{ margin: '40px 0' }}>
              <img src={studentLemmy} alt="student_ lemmy" width={80} />
            </Box>

            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
              Please enter your class code.
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: '40px' }}>
              Your teacher can give you the 6 digit class code. It will look
              something like this “ABC123”.
            </Typography>
            <MuiOtpInput
              length={6}
              value={otp}
              onChange={handleChange}
              display="flex"
              gap={1}
              maxWidth={300}
              sx={{ marginBottom: '50px' }}
              aria-autocomplete="none"
            />
            <Box sx={{ mb: '20px' }}>
              <Toast
                isOpen={open}
                severity="error"
                onClose={() => closeToast(true)}
                styles={{
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  top: '-10px',
                }}
                errorMessage={errorMessage}
              />
            </Box>

            <Button
              variant="contained"
              fullWidth
              sx={{ marginBottom: '60px', fontWeight: 'bold', padding: '10px' }}
              disabled={otp?.length !== 6}
              onClick={handleClassJoin}
            >
              Continue
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            margin: 'auto',
            width: '500px',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <Typography variant="body2" margin={'40px 0 10px 0'}>
            By continuing, you agree to the{' '}
            <Link href={'#'} color="#fff">
              Term of use
            </Link>{' '}
            and the{' '}
            <Link href={'#'} color="#fff">
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            Copyright &copy; 2023 Lemoney Inc. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
