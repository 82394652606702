import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const InputTitleGrid = styled(Grid)`
  display: flex;
  align-items: center;
  padding-left: 2rem;
`;

export const InputTitle = styled(Typography)`
  font-weight: 600 !important;
  padding-right: 0.5rem;
`;
