import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LemmyEducator from '../../Assets/TeacherLemmy.png';
import LemmyStudent from '../../Assets/Lemmy_Ball_Cap.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const RoleSelectionScreen = () => {
  const [searchParams] = useSearchParams();
  const isCreateAccount = searchParams.get('isCreateAccount') ?? '';
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('');
  const { role: isRole, userId } = useSelector(
    (state: RootState) => state.auth,
  );

  const handleRoleSelection = (role: string) => {
    if (role && !isCreateAccount) {
      setSelectedRole(role);
      navigate(`${routeIDs.LOGIN}?role=${role}`);
    } else {
      setSelectedRole(role);
      navigate(`${routeIDs.CREATE_ACCOUNT}?role=${role}`);
    }
  };

  useEffect(() => {
    if (isRole && userId) {
      if (isRole === 'teacher') {
        navigate(routeIDs.EDUCATOR_HOME);
      }
      if (isRole === 'student') {
        navigate(routeIDs.STUDENT);
      }
    }
  }, [isRole]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: { md: '600px', sm: '100%' },
        margin: 'auto',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { md: '2rem', xs: '1.2rem' },
          marginLeft: '0.4rem',
          color: '#003366',
        }}
      >
        Are you an educator or a student?
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: { xs: 'center' },
          width: '100%',
          marginTop: '60px',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          onClick={() => handleRoleSelection('teacher')}
          sx={{
            backgroundColor: selectedRole === 'teacher' ? '#b5f5ec' : '#e6e5e5',
            cursor: 'pointer',
            width: { md: '220px', xs: '280px' },
            paddingBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '20px',
            transition: 'background-color 0.3s ease',
            color: '#003366',

            '&:hover': {
              backgroundColor: '#003366',
              color: '#FFF',
            },
          }}
        >
          <Box component={'img'} src={LemmyEducator} width={150} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '1.6rem',
            }}
          >
            Educator
          </Typography>
        </Box>
        <Box
          sx={{
            borderRight: { md: '1px solid #1976d2', sm: 'none' },
            borderBottom: { xs: '1px solid #1976d2', md: 'none' },
            height: '90%',
            width: { md: '0%', xs: '260px' },
            margin: { md: 'auto', xs: '40px' },
          }}
        />
        <Box
          onClick={() => handleRoleSelection('student')}
          sx={{
            backgroundColor: selectedRole === 'student' ? '#b5f5ec' : '#e6e5e5',
            cursor: 'pointer',
            width: { md: '220px', xs: '280px' },
            paddingBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '20px',
            transition: 'background-color 0.3s ease',
            color: '#003366',
            '&:hover': {
              backgroundColor: '#003366',
              color: '#FFF',
            },
          }}
        >
          <Box component={'img'} src={LemmyStudent} width={150} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '1.6rem',
            }}
          >
            Student
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
