export const generateLicenseKeyErrorMessage = (
  currentMessage: string,
): string => {
  const TOO_MANY_USERS_MESSAGE =
    'Oops, looks like others have already used all of the licenses on this license key. Please reach out to your purchasing team or directly to us to coordinate purchasing more., {Contact Us} to add more licenses';
  const LICENSE_EXPIRED_MESSAGE =
    'Oops, looks like this license has already expired., {Contact Us} to renew your license';
  const LICENSE_NOT_FOUND_MESSAGE =
    'Oops, there was a problem with your License Key. Please re-enter your key and try again. If the problem persists please reach out to your administrator or directly to us.';
  const ALREADY_ADDED_LICENSE_MESSAGE =
    'This license is already active on your account!';

  const cleanedMessage = currentMessage.replace(/"/g, '');

  if (currentMessage.includes('Too may')) {
    return TOO_MANY_USERS_MESSAGE;
  }

  if (
    currentMessage.includes('License does') ||
    currentMessage.includes('default')
  ) {
    return LICENSE_EXPIRED_MESSAGE;
  }

  if (currentMessage.includes('Not Found')) {
    return LICENSE_NOT_FOUND_MESSAGE;
  }

  if (currentMessage.includes('Teacher already added license')) {
    return ALREADY_ADDED_LICENSE_MESSAGE;
  }

  return cleanedMessage;
};
