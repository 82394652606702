import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import React from 'react';
import { IMenuProps } from './types';

export const Menu = ({
  anchorRef,
  menuOpen,
  menuOptions,
  handleClick,
}: IMenuProps) => {
  return (
    <Popper
      open={menuOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="top-start"
      transition
      disablePortal
      sx={{ zIndex: 1 }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'left top',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClick}>
              <MenuList autoFocusItem={menuOpen}>
                {menuOptions.map((option, index) => (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(option);
                    }}
                    key={index}
                    data-testid={`${option}`}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
