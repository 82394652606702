export const handleQuestionStyles = (
  option: any,
  multiValue: string[],
  submitting: boolean,
  isCompleted?: boolean,
) => {
  switch (true) {
    case multiValue.includes(option.answerId) && option.isCorrect && submitting:
      return { bg: '#028000', color: '#fff' };
    case multiValue.includes(option.answerId) &&
      !option.isCorrect &&
      submitting:
      return { bg: '#DC163C', color: '#fff' };
    case multiValue.includes(option.answerId) &&
      !option.isCorrect &&
      isCompleted:
      return { bg: '#DC163C', color: '#fff' };
    case multiValue.includes(option.answerId) &&
      option.isCorrect &&
      isCompleted:
      return { bg: '#028000', color: '#fff' };
    case multiValue.includes(option.answerId):
      return { bg: '#E9E9E9' };
    case !multiValue.includes(option.answerId) &&
      option.isCorrect &&
      isCompleted:
      return { bg: '#028000', color: '#fff' };
    case !multiValue.includes(option.answerId) &&
      option.isCorrect &&
      submitting:
      return { bg: '#028000', color: '#fff' };
    default:
      return null;
  }
};
