import React from 'react';
import { useFormik } from 'formik';
import { Button, FormControl, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import studentLogo from '../../Assets/TeacherLemmy.png';
import { FormsControl } from '../../GlobalComponents/Form/FormControl';
import { useAppDispatch } from '../../redux/store';
import { forgotPassword } from '../../redux/slices/teacherSlice';
import { forgotPasswordSchema } from './schema';

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      try {
        dispatch(forgotPassword(values.email))
          .then(() => navigate('/confirmForgotPassword'))
          .catch((err) => {
            throw new Error(err);
          });
      } catch (error) {
        console.error(error);
      }
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ margin: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <img src={studentLogo} alt="educator-logo" width={120} height={120} />
        </Box>
        <Typography
          variant="h4"
          sx={{ mt: 1.5, textAlign: 'center', fontWeight: '600', mb: 0 }}
          gutterBottom
        >
          Forgot Password
        </Typography>
        <Typography
          variant="h6"
          sx={{ textAlign: 'center', fontWeight: '400' }}
          gutterBottom
        >
          Enter your email address
        </Typography>
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '300px', md: '400px' },
          }}
        >
          <FormsControl
            control="input"
            type="email"
            name="email"
            label="Email"
            formik={formik}
          />
          <FormControl
            fullWidth
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
            variant="outlined"
          >
            <Button
              variant="outlined"
              sx={{ mt: 3, mb: 2, mr: '20px', fontSize: '16px' }}
              onClick={() => navigate('/educator/login')}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: '16px', minWidth: '97px' }}
              type="submit"
            >
              Continue
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
