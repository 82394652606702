import { useRouteError } from 'react-router-dom';
import React from 'react';
import { ErrorPage } from '../ErrorPage';

export const GlobalErrorPage = () => {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <div id="error-page">
      <ErrorPage
        status={error.status}
        title="Unexpected Error"
        description="An unexpected error occurred. We apologize for the inconvenience."
      />
    </div>
  );
};
