/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import { IAnswerOption } from '../../types';
import { InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import {
  hasHTMLTags,
  sanitizeHTML,
} from '../../../Student/components/LessonProgress/LessonProgressHelpers';

export interface IOpenEntryQuestionProps {
  questionText?: string;
  questionExplanation?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  answerOptions?: IAnswerOption[];
  multiValue: string[];
  submitting: boolean;
  disabled?: boolean;
  isCompleted: boolean;
  setMultiValue: React.Dispatch<React.SetStateAction<string[]>>;
  setOpenEntryValue: React.Dispatch<React.SetStateAction<string>>;
  answerText: string | undefined;
}
export const OpenEntry = ({
  questionText,
  questionExplanation,
  answerOptions,
  multiValue,
  submitting,
  handleChange,
  setOpenEntryValue,
  isCompleted,
  answerText,
}: IOpenEntryQuestionProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [colors, setColors] = useState<string>();

  const debouncedHandleError = debounce(() => {
    if (
      answerChoice &&
      answerOptions &&
      answerCorrect !== answerChoice &&
      answerCorrect !== parseStringToNumber(inputValue)
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, 1000);

  const parseStringToNumber = (value: string | undefined) => {
    return value && parseFloat(value.replace(/[^0-9.]/g, ''));
  };

  const handleColor = () => {
    if (submitting || isCompleted) {
      if (isError) {
        setColors('#d32f2f');
      } else {
        setColors('green');
      }
    } else {
      setColors('gray');
    }
  };

  const formatValueWithCommas = (value: string) => {
    if (value) {
      const number = parseFloat(value.replace(/[^0-9.]/g, ''));
      return number.toLocaleString('en-US');
    } else {
      return '';
    }
  };
  const answer = parseStringToNumber(answerText);
  const answerCorrect = parseStringToNumber(answerOptions?.[0]?.answerText);
  const answerChoice = parseStringToNumber(multiValue[0]);

  useEffect(() => {
    if (answerOptions) {
      setOpenEntryValue(answerOptions[0]?.answerId);
    }
  }, []);

  useEffect(() => {
    const formattedAnswer = isCompleted
      ? formatValueWithCommas(answerText || '')
      : '';
    setInputValue(formattedAnswer);
  }, [isCompleted, answerText]);

  useEffect(() => {
    debouncedHandleError();
    handleColor();
  }, [
    answerChoice,
    answerCorrect,
    answerOptions,
    submitting,
    isError,
    isCompleted,
  ]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatValueWithCommas(e.target.value);
    setInputValue(formattedValue); // Update the inputValue state
    e.target.value = formattedValue;
    handleChange(e);
  };

  return (
    <Box data-testid="question-open" sx={{ width: '100%', mb: '20px' }}>
      <Box>
        {questionText && hasHTMLTags(questionText) ? (
          <Box
            sx={{ img: { width: '100%' } }}
            dangerouslySetInnerHTML={sanitizeHTML(questionText ?? '')}
          />
        ) : (
          <Typography
            variant="h5"
            color={'#003366'}
            sx={{ marginBottom: '10px' }}
          >
            {questionText}
          </Typography>
        )}
      </Box>
      <FormControl sx={{ width: '100%' }}>
        <Box sx={{ marginBottom: '40px' }}>
          <FormLabel id="answer" sx={{ color: colors }}>
            Your Answer
          </FormLabel>
          <TextField
            sx={{
              width: '90%',
              '.MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors} !important`,
              },
            }}
            hiddenLabel
            placeholder=""
            defaultValue={isCompleted ? answer : ''}
            error={isError}
            disabled={isCompleted ? true : submitting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            value={inputValue}
            onChange={handleInputChange}
            inputProps={{
              onKeyPress: (event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              },
            }}
          />
          {isError && (submitting || isCompleted) && (
            <Typography
              data-testid="correct-open-answer"
              sx={{ mt: '5px', color: 'green' }}
            >
              Correct Answer: {answerOptions?.[0]?.answerText}
            </Typography>
          )}
        </Box>
      </FormControl>
      {(submitting || isCompleted) && (
        <Box>
          <Typography
            variant="h5"
            color={'#003366'}
            textAlign={'center'}
            fontWeight={600}
            data-testid="explanation-test"
          >
            Explanation
          </Typography>
          <Typography
            sx={{ marginBottom: '10px', color: '#003366', fontWeight: '600' }}
          >
            {questionExplanation}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
