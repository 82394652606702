/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../../LibraryComponents/PageContainer';
import { SingleQuestion } from '../../../Quiz/components/SingleQuestions/SingleQuestions';
import { ArrowBack } from '@mui/icons-material';
import { QuestionType } from '../../../../constants/QuestionType';
import { MultiSelect } from '../../../Quiz/components/MultiSelect/MultiSelect';
import { OpenEntry } from '../../../Quiz/components/OpenEntry/OpenEntry';
import { SentenceCompletion } from '../../../Quiz/components/SentenceCompletion/SentenceCompletion';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Spinner } from '../../../../LibraryComponents/Spinner';
import { ILessonProgressProps } from '../../types';

export const QuestionTest = ({
  questions,
  indexLesson,
  multiValue,
  submitting,
  handleChanges,
  handlePrev,
  handleNext,
  setMultiValue,
  setOpenEntryValue,
  totalQuestions,
  isPreQuiz,
  setIndexLesson,
  handleSubmit,
  minimized,
}: ILessonProgressProps) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const { answerLocal, status, answerLocalPreGuest, answerLocalPostGuest } =
    useSelector((state: RootState) => state.quiz);
  const answerLocalGuest = isPreQuiz
    ? answerLocalPreGuest
    : answerLocalPostGuest;

  const { role } = useSelector((state: RootState) => state.auth);
  const answerLocally: any =
    role === 'guest' ? answerLocalGuest.answerLocal : answerLocal;
  const handleQuestionType = () => {
    const questionTypeId = questions[indexLesson]?.questionTypeId;
    switch (questionTypeId) {
      case QuestionType.MultiSelect:
        return 'multi';
      case QuestionType.SingleSelect:
        return 'single';
      case QuestionType.OpenEntry:
        return 'open';
      case QuestionType.SentenceCompletion:
        return 'sentence';
      default:
        return null;
    }
  };
  const noEmptyOrUndefinedValues = multiValue?.every(
    (item) => item !== '' && item !== undefined,
  );

  const handleSubmitButton = () => {
    if (questions[indexLesson] && !submitting && !isCompleted) {
      return true;
    }

    return false;
  };

  const handleSubmitButtonDisabled = () => {
    const currentQuestionType = handleQuestionType();
    if (
      currentQuestionType === 'multi' &&
      multiValue?.length < 2 &&
      noEmptyOrUndefinedValues
    ) {
      return true;
    }
    if (multiValue.length === 0) {
      return true;
    }
    if (handleQuestionType() === 'sentence' && !noEmptyOrUndefinedValues) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (answerLocally) {
      setIsCompleted(
        answerLocally[indexLesson]?.answerPlaceAnswers[0]?.studentAnswers
          ?.length > 0,
      );
      if (isCompleted && answerLocally) {
        const ids: string[] = [];
        answerLocally[indexLesson]?.answerPlaceAnswers?.map((item: any) =>
          item.studentAnswers.map((answer: any) => ids.push(answer.answerId)),
        );

        return setMultiValue(ids || []);
      }
    }
  }, [indexLesson, isCompleted, setMultiValue, status]);

  const answerText =
    answerLocally[indexLesson]?.answerPlaceAnswers[0]?.studentAnswers[0]
      ?.openEntryAnswerText || '';

  return (
    <Box
      sx={{
        background: '#003366',
        height: { xs: '85vh', sm: '100%' },
        paddingTop: '0.1px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        flexGrow: 1,
      }}
    >
      {status === 'loading' && <Spinner />}
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: '40px',
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            color: '#fff',
            fontWeight: 700,
          }}
        >
          {isPreQuiz ? 'Pre Test' : 'Course Test'}
        </Typography>
      </Box>
      <PageContainer
        styles={{
          background: '#fff',
          width: {
            xs: '90%',
            md: '642px',
          },
          padding: '20px',
          height: 'auto',
          position: 'relative',
          minHeight: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          m: { xs: '0 10px', sm: '0' },
          borderRadius: '10px',
        }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          style={{
            minHeight: 'inherit',
          }}
        >
          <Grid
            item
            xs
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            {questions[indexLesson] && (
              <Box
                data-testid="question-container"
                sx={{
                  margin: { xs: '0', sm: '20px 0 20px 40px' },
                  position: 'relative',
                }}
              >
                {(() => {
                  switch (handleQuestionType()) {
                    case 'multi':
                      return (
                        <MultiSelect
                          key={questions[indexLesson].questionId}
                          questionText={questions[indexLesson].questionText}
                          questionExplanation={
                            questions[indexLesson].questionExplanation
                          }
                          answerOptions={
                            questions[indexLesson]?.answerPlaces[0]
                              ?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                    case 'single':
                      return (
                        <SingleQuestion
                          key={questions[indexLesson].questionId}
                          questionText={questions[indexLesson].questionText}
                          questionExplanation={
                            questions[indexLesson].questionExplanation
                          }
                          answerOptions={
                            questions[indexLesson].answerPlaces[0]
                              ?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                    case 'open':
                      return (
                        <OpenEntry
                          key={questions[indexLesson].questionId}
                          questionText={questions[indexLesson].questionText}
                          questionExplanation={
                            questions[indexLesson].questionExplanation
                          }
                          answerOptions={
                            questions[indexLesson].answerPlaces[0]
                              ?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          handleChange={handleChanges}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                          setOpenEntryValue={setOpenEntryValue}
                          answerText={answerText}
                        />
                      );
                    default:
                      return (
                        <SentenceCompletion
                          key={questions[indexLesson].questionId}
                          questionText={questions[indexLesson].questionText}
                          questionExplanation={
                            questions[indexLesson].questionExplanation
                          }
                          answerOptions={
                            questions[indexLesson].answerPlaces[0]
                              ?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          setMultiValue={setMultiValue}
                          answerPlaces={questions[indexLesson].answerPlaces}
                          isCompleted={isCompleted}
                          answer={answerLocally[indexLesson]}
                        />
                      );
                  }
                })()}
              </Box>
            )}
          </Grid>
          <Grid item width={'100%'}>
            <Box
              sx={{
                width: { xs: 'auto', sm: '95%' },
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'space-between' },
                alignItems: 'center',
                position: 'absolute',
                bottom: '10px',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: '#000',
                  marginRight: '20px',
                  minWidth: '120px',
                }}
                onClick={handlePrev}
                startIcon={<ArrowBack />}
                disabled={indexLesson === 0}
              >
                Prev
              </Button>
              <Typography data-testid="answer-number" sx={{ color: '#9C27B0' }}>
                {indexLesson + 1} of{' '}
                {totalQuestions ? totalQuestions - 1 : totalQuestions}
              </Typography>
              {handleSubmitButton() ? (
                <Box>
                  <Button
                    data-testid="submit-answer-button"
                    variant="contained"
                    sx={{ background: '#003366' }}
                    onClick={handleSubmit}
                    disabled={handleSubmitButtonDisabled()}
                  >
                    Submit answer
                  </Button>
                </Box>
              ) : (
                <Button
                  data-testid="next-answer-button"
                  onClick={handleNext}
                  variant="contained"
                  sx={{ background: '#FA4729', minWidth: '120px' }}
                  disabled={
                    !!questions[indexLesson] && !submitting && !isCompleted
                  }
                >
                  next
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </Box>
  );
};
