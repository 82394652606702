import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { ICurriculums } from '../../../../redux/types/resourcesTypes';
import { debounce } from 'lodash';
import {
  getCurriculums,
  getExternalUrls,
} from '../../../../redux/slices/resourcesSlices';
import { PageContainer } from '../../../../LibraryComponents/PageContainer';
import { resources } from '../../ClassroomTileHelper/ResourcesConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { ResourcesListContent } from '../ResourcesListContent';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGTM } from '../../../../hooks/useGTM';
import { ExternalUrlIdentifier } from '../../types';

export const ResourcesList = () => {
  const { curriculums } = useSelector((state: RootState) => state.resources);
  const externalUrls = useSelector(
    (state: RootState) => state.resources.externalUrls,
  );
  const TeacherData = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );

  const { curriculumID } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { track } = useGTM();

  const [curriculumData, setCurriculumData] = useState<ICurriculums>();

  const debouncedGetCurriculums = debounce(
    () =>
      dispatch(getCurriculums())
        .then()
        .catch((err) => console.error(err)),
    500,
  );

  useEffect(() => {
    const data: ICurriculums = curriculums?.find(
      (curriculum) => curriculum.id === curriculumID,
    ) as ICurriculums;
    if (data) {
      setCurriculumData(data);
    }
  }, [curriculumID, curriculums]);

  useEffect(() => {
    if (curriculums?.length === 0) {
      debouncedGetCurriculums();
    }
  }, [curriculums]);

  const getAskAnExpertUrl = () => {
    const foundUrl = externalUrls.find(
      (url) => url.urlId === ExternalUrlIdentifier.AskAnExpert,
    );
    return foundUrl?.url ?? '';
  };

  const debouncedGetExternalUrls = debounce(
    () => dispatch(getExternalUrls()),
    500,
  );
  useEffect(() => {
    if (externalUrls.length === 0) {
      debouncedGetExternalUrls();
    }
  }, []);

  const handleCourseTileClick = (label: string) => {
    const courseTitle = curriculumData?.tileUIDisplay;
    const resourceTitle = label;
    track('course_tile_click', {
      courseTitle,
      resourceTitle,
    });
  };

  const name = TeacherData
    ? TeacherData.firstName + ' ' + TeacherData.lastName
    : '';

  return (
    <PageContainer
      styles={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflowX: 'hidden',
        maxWidth: '100%',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          margin: '16px 38px',
        }}
      >
        <Grid item xs={12} position={'relative'}>
          <Button
            variant="text"
            sx={{
              color: '#003366',
              fontWeight: 600,
              textTransform: 'none',
              fontSize: '1rem',
              position: 'absolute',
              top: { xs: '-20px', md: '0px' },
              left: { xs: '0px', md: '10px' },
            }}
            startIcon={
              <ArrowBackIcon
                sx={{
                  fill: '#003366',
                  width: '25px',
                  height: '25px',
                }}
              />
            }
            onClick={() => navigate('/educator/home')}
          >
            Back
          </Button>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              mb: '30px',
              fontWeight: 'bold',
            }}
          >
            {(curriculumData?.tileUIDisplay as string) || curriculumData?.name}
          </Typography>
        </Grid>
        {resources(curriculumData, getAskAnExpertUrl())
          ?.filter((c) => !c.isHidden)
          .map((element) => (
            <ResourcesListContent
              key={element.id}
              isLink={element.isLink}
              label={element.label}
              target="_blank"
              url={element.url as string}
              icon={element.icon}
              order={element.order}
              onClick={() => handleCourseTileClick(element.label)}
              isPremium={element.isPremium}
              tileToolTipsMsg={element.tileToolTipsMsg}
              subText={element.subText}
            />
          ))}
      </Grid>
    </PageContainer>
  );
};
