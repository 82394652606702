import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Divider, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Headerbar } from '../Headerbar';
import Modal from '../../LibraryComponents/Modal/Modal';
import { PDFLinkViewer } from '../../LibraryComponents/PDFLinkViewer';
import { RootState } from '../../redux/store';
import { Footer } from '../Footer';
import { DrawerComponent } from './components/Drawer';
import useResize from '../../hooks/useResize';

export const Sidebar = () => {
  const { role, userId } = useSelector((state: RootState) => state.auth);
  const { replyFromChatGPT } = useSelector((state: RootState) => state.teacher);

  const { width: widthResize } = useResize();
  const outletContainerRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [typePdf, setTypePdf] = useState<string>('');
  const [isTeacher, setIsTeacher] = useState<boolean>(
    role === 'teacher' && userId !== null,
  );

  const toggleDrawer = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    setIsTeacher(role === 'teacher' && userId !== null);
  }, [role, userId]);

  useEffect(() => {
    toggleDrawer(false);
  }, [isTeacher]);

  const scrollToBottom = () => {
    if (outletContainerRef.current) {
      const scrollHeight = outletContainerRef.current.scrollHeight;
      const clientHeight = outletContainerRef.current.clientHeight;
      outletContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [replyFromChatGPT]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '4.2rem' }}>
        {isTeacher && (
          <Button
            data-testid="button-sidebar"
            onClick={() => toggleDrawer(!open)}
            sx={{ display: widthResize > 1920 ? 'none' : 'block' }}
          >
            <MenuIcon sx={{ color: '#003366' }} />
          </Button>
        )}
        <Headerbar toggleDrawer={toggleDrawer} />
      </Box>
      <Divider light sx={{ borderWidth: '1px' }} />
      {isTeacher && (
        <nav>
          {widthResize > 1920 ? (
            <Drawer
              variant="permanent"
              sx={{
                display: widthResize > 1921 ? 'block' : 'none',
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: 275,
                  height: '86vh',
                },
                top: '69px',
                '& .MuiModal-backdrop ,.MuiDrawer-paper': {
                  top: '69px',
                  height: '-webkit-fill-available',
                  zIndex: 98,
                },
              }}
              open
            >
              <DrawerComponent
                toggleDrawer={toggleDrawer}
                setTypePdf={setTypePdf}
                setShowPDFViewer={setShowPDFViewer}
              />
            </Drawer>
          ) : (
            <Drawer
              variant="temporary"
              data-testid="drawer"
              open={open}
              onClose={() => toggleDrawer(false)}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: widthResize > 1920 ? 'none' : 'block',
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300 },
                top: '67px',
                '.MuiModal-backdrop ,.MuiDrawer-paper,.MuiDrawer-modal': {
                  top: '67px',
                  height: '-webkit-fill-available',
                  zIndex: 98,
                },
              }}
            >
              <DrawerComponent
                toggleDrawer={toggleDrawer}
                setTypePdf={setTypePdf}
                setShowPDFViewer={setShowPDFViewer}
              />
            </Drawer>
          )}
        </nav>
      )}
      <Box
        ref={outletContainerRef}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width:
            widthResize > 1920 && isTeacher ? `calc(100% - ${250}px)` : '100%',
          marginLeft: widthResize > 1920 && isTeacher ? '250px' : '0',
          overflow: 'auto',
          marginBottom: '0px',
          height: '87vh',
        }}
      >
        <Outlet />
        <Modal
          isOpen={showPDFViewer}
          onClose={() => setShowPDFViewer(false)}
          showCloseButton={false}
        >
          <PDFLinkViewer
            onCancel={() => setShowPDFViewer(false)}
            typePdf={typePdf}
          />
        </Modal>
      </Box>
      <Footer />
    </>
  );
};
