import { useState } from 'react';

const useDataCleanup = () => {
  const [dataRemoved, setDataRemoved] = useState(false);

  const removeData = () => {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const cookieParts = cookie.split('=');
      const cookieName = cookieParts[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    localStorage.clear();

    sessionStorage.clear();

    setDataRemoved(true);
  };

  return { removeData, dataRemoved };
};

export default useDataCleanup;
