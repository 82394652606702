import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { PageContainer } from '../../LibraryComponents/PageContainer';
import educatorLogo from '../../Assets/TeacherLemmy.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getDistricts, getStates } from '../../redux/slices/resourcesSlices';
import _ from 'lodash';
import { useFormik } from 'formik';
import {
  getTeacherDetails,
  updateProfile,
} from '../../redux/slices/teacherSlice';
import { Toast } from '../../LibraryComponents/Toast';
import { FormsControl } from '../../GlobalComponents/Form/FormControl';
import { profileSchema } from './schema';

export const Profile = ({
  setShowProfile,
}: {
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const TeacherData = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );
  const { states, districts } = useSelector(
    (state: RootState) => state.resources,
  );
  const { userId: teacherId, accessToken: token } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [saveLoading, setSaveLoading] = useState(false);
  const [openToast, setOpenToast] = useState<{
    open: boolean;
    msg: string;
    severity: 'error' | 'success';
  }>({ open: false, msg: '', severity: 'error' });

  const initialValues = {
    lastName: TeacherData?.lastName ?? '',
    firstName: TeacherData?.firstName ?? '',
    stateId: TeacherData?.stateId ?? '',
    districtId: TeacherData?.districtId ?? '',
    schoolName: TeacherData?.schoolName ?? '',
  };

  const debouncedSave = _.debounce((dispatchFunc) => {
    if (!states) dispatchFunc(getStates());
    if (!districts) dispatchFunc(getDistricts());
  }, 500);

  useEffect(() => {
    debouncedSave(dispatch);
    return () => {
      debouncedSave.cancel();
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const { firstName, lastName, stateId, districtId, schoolName } = values;
      const isEqual = _.isEqual(values, initialValues);
      setSaveLoading(true);
      if (!isEqual) {
        await dispatch(
          updateProfile({
            firstName,
            lastName,
            stateId,
            districtId,
            schoolName,
            teacherId,
            token,
          }),
        )
          .then((res) => {
            if (res.payload === 400) {
              setOpenToast({
                severity: 'error',
                open: true,
                msg: 'We encountered an issue while attempting to update your profile. Please ensure your information is correct and try again. If the problem persists, contact our support team for assistance',
              });
              return;
            }
            dispatch(
              getTeacherDetails({
                teacherId,
                token,
              }),
            );
            setOpenToast({
              severity: 'success',
              open: true,
              msg: 'successfully modified profile',
            });
            setShowProfile(false);
          })
          .catch((err) => {
            console.log('error', err);
            console.error(err);
          });

        setShowProfile(false);
        formik.resetForm();
        setSaveLoading(false);
      }
    },
  });

  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));

  useEffect(() => {
    if (!TeacherData) {
      dispatch(
        getTeacherDetails({
          teacherId,
          token,
        }),
      );
    }
  }, [TeacherData, dispatch, teacherId, token]);

  return (
    <>
      <Box
        sx={{
          background: '#003366',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: '10px',
          borderRadius: '5px 5px 0 0',
          position: 'relative',
          minHeight: { xs: '100px', lg: '100px' },
          mb: { xs: '60px', md: '40px', xl: '0' },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1.5,
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
          }}
          gutterBottom
        >
          Profile
        </Typography>
        <Box sx={{ position: 'absolute', top: { xs: '70px', lg: '70px' } }}>
          <Avatar
            sx={{
              width: 'min-content',
              height: 'min-content',
              m: 'auto',
            }}
          >
            <img
              src={educatorLogo}
              alt="educator-logo"
              width={'80px'}
              height={'80px'}
            />
          </Avatar>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              color: '#003366',
            }}
            gutterBottom
          >
            {' '}
            {TeacherData?.email}
          </Typography>
        </Box>
      </Box>
      <PageContainer
        styles={{
          display: 'flex',
          justifyContent: 'center',
          width: 'max-content',
          padding: { xs: 0, sm: '0 40px' },
          height: '45%',
        }}
      >
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 20px',
            minWidth: '350px',
            width: { xs: '350px', sm: '405px' },
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormsControl
                control="input"
                type="text"
                name="firstName"
                data-testid="firstName"
                label="First Name"
                formik={formik}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormsControl
                control="input"
                type="text"
                name="lastName"
                data-testid="lastName"
                label="Last Name"
                formik={formik}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <FormsControl
            control="select"
            name="stateId"
            formik={formik}
            label="-- Select State --"
            children={states?.map((option) => (
              <MenuItem
                key={option?.stateID}
                value={option?.stateID}
                sx={{ color: 'gray' }}
              >
                {option.name}
              </MenuItem>
            ))}
          />
          <Autocomplete
            data-testid="my-autocomplete"
            sx={{ mt: 1 }}
            fullWidth
            options={districts as []}
            getOptionLabel={(option) => option.name}
            value={
              districts?.find(
                (option) => option.districtID === formik.values.districtId,
              ) || null
            }
            onChange={(event, newValue) => {
              formik.setFieldValue(
                'districtId',
                newValue ? newValue.districtID : '',
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                label="-- Select District --"
                error={
                  formik.touched.districtId && formik.errors.districtId
                    ? true
                    : false
                }
                helperText={
                  formik.touched.districtId && formik.errors.districtId
                }
                name="districtId"
              />
            )}
          />
          <FormsControl
            control="input"
            type="text"
            name="schoolName"
            label="School"
            formik={formik}
            variant="outlined"
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minWidth: '100%',
            }}
          >
            <Toast
              isOpen={openToast.open}
              severity={openToast.severity}
              onClose={() => closeToast(true)}
              styles={{
                textAlign: 'center',
                top: 0,
                width: { xs: '100%', sm: '400px' },
              }}
              errorMessage={openToast.msg}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '20px',
              gap: { xs: '20px' },
            }}
          >
            <Button
              sx={{ color: '#003366', width: '100%', borderColor: '#003366' }}
              variant="outlined"
              onClick={() => setShowProfile(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%', backgroundColor: '#003366' }}
              disabled={saveLoading}
            >
              {saveLoading ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};
