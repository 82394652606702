import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Avatar, Button, Divider, FormControl, Link } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import educatorLogo from '../../../Assets/TeacherLemmy.png';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { FormValues, SuccessResponse } from './types';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  resetRole,
} from '../../../redux/slices/authSlice';
import { Toast } from '../../../LibraryComponents/Toast';
import { getTeacherDetails } from '../../../redux/slices/teacherSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { resetGuestInfo } from '../../../redux/slices/guestSlices';
import { useGTM } from '../../../hooks/useGTM';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LoginIcon from '@mui/icons-material/Login';

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().min(8).required('Required'),
});

export const TeacherLogin = () => {
  const {
    status,
    role: userRole,
    userId,
  } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const { track, pageView } = useGTM();
  const [searchParams] = useSearchParams();
  const isRegistrationCompleted: boolean = !!searchParams.get('registration');
  const isAccountDeleted: boolean = !!searchParams.get('account');
  const API_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch<AppDispatch>();

  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [openToast, setOpenToast] = useState<{
    open: boolean;
    status: number | null;
    severity: 'error' | 'success';
    msg: string;
  }>({ open: false, status: statusCode, severity: 'error', msg: '' });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values: FormValues, { setSubmitting }) => {
      track('login_attempt', { method: 'teacher_login' });
      dispatch(loginStart());
      try {
        const response = await axios.post<SuccessResponse>(
          `${API_URL}/teachers/login`,
          values,
        );
        track('login_success', {
          method: 'teacher_login',
          userId: response.data.userId,
        });
        dispatch(
          loginSuccess({
            ...response.data,
            role: 'teacher',
          }),
        );
        await dispatch(
          getTeacherDetails({
            teacherId: response.data.userId,
            token: response.data.accessToken,
          }),
        );
        setSubmitting(false);
        navigate(routeIDs.EDUCATOR_HOME);
      } catch (error) {
        setOpenToast((prev) => ({ ...prev, open: true }));
        track('login_failure', {
          method: 'teacher_login',
          errorCode: statusCode,
        });
        dispatch(loginFailure());
        if (axios.isAxiosError(error) && error.response) {
          setStatusCode(error.response.status);
        } else {
          setStatusCode(500);
        }
        setSubmitting(false);
      }
    },
  });

  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));

  const handleSelectionRoles = () => {
    dispatch(resetRole());
    navigate(routeIDs.ROLE_SELECTION_SCREEN);
  };

  useEffect(() => {
    dispatch(resetGuestInfo());
  }, [dispatch]);

  useEffect(() => {
    if (userRole === 'student' && userId) {
      navigate(routeIDs.STUDENT);
    } else if (userRole === 'teacher' && !isAccountDeleted && userId) {
      navigate(routeIDs.EDUCATOR_HOME);
    }
    pageView('/login');
  }, [navigate, userRole]);

  useEffect(() => {
    if (isRegistrationCompleted) {
      setOpenToast({
        open: true,
        severity: 'success',
        msg: 'Registration Complete!',
        status: null,
      });
    } else if (isAccountDeleted) {
      setOpenToast({
        open: true,
        severity: 'success',
        msg: 'Account Deleted!',
        status: null,
      });
    }
    setTimeout(() => {
      setOpenToast((prev) => ({ ...prev, open: false }));
    }, 2000);
  }, [isRegistrationCompleted, isAccountDeleted]);

  return (
    <Box sx={{ m: { md: '64px auto 0 auto', xs: '64px 10px 0 10px' } }}>
      {status === 'loading' && <Spinner />}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Toast
          isOpen={openToast.open}
          severity={openToast.severity}
          onClose={() => closeToast(true)}
          styles={{ width: '600px', textAlign: 'center' }}
          status={statusCode}
          errorMessage={openToast.msg}
        />
      </Box>
      <PageContainer
        styles={{
          maxWidth: '500px',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#003366',
              width: '100%',
              borderRadius: '10px 10px 0 0',
              minHeight: '120px',
              position: 'relative',
              mb: '50px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mt: 1.5,
                textAlign: 'center',
                fontWeight: '600',
                color: '#fff',
              }}
            >
              Educator Login
            </Typography>
            <Box sx={{ position: 'absolute', top: '70px' }}>
              <Avatar
                sx={{
                  m: 'auto',
                  width: 80,
                  height: 80,
                }}
              >
                <Box sx={{ marginLeft: '6px', marginTop: '5px' }}>
                  <img
                    src={educatorLogo}
                    alt="educator-logo"
                    width={120}
                    height={120}
                  />
                </Box>
              </Avatar>
              <Typography
                variant="body2"
                color="#9C27B0"
                onClick={handleSelectionRoles}
                sx={{ cursor: 'pointer' }}
              >
                Switch to Student Login
              </Typography>
            </Box>
          </Box>
          <Box
            component={'form'}
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '300px', md: '400px' },
              p: '20px',
            }}
          >
            <FormsControl
              control="input"
              type="text"
              name="email"
              label="Email"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="password"
              name="password"
              label="Password"
              formik={formik}
            />
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: '16px',
                  bgcolor: '#003366',
                  fontWeight: 700,
                }}
                onClick={() =>
                  track('button_click', { label: 'Teacher Login Button' })
                }
                endIcon={<LoginIcon />}
              >
                Login
              </Button>
            </FormControl>
            <Box>
              <Link
                href={routeIDs.FORGOT_PASSWORD}
                variant="body2"
                color={'inherit'}
                underline="none"
              >
                Forgot password?
              </Link>
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ margin: '10px', width: '100%' }}
            />
            <Box>
              <Typography variant="h6" color={'inherit'} fontWeight={500}>
                {"Don't have an account?"}
              </Typography>
            </Box>
            <FormControl fullWidth variant="outlined">
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                  fontSize: '16px',
                  bgcolor: '#003366',
                  fontWeight: 700,
                }}
                onClick={() => {
                  track('button_click', { label: 'Teacher Create Button' });
                  return navigate(`${routeIDs.CREATE_ACCOUNT}?role=teacher`);
                }}
                endIcon={<PersonAddAlt1Icon />}
              >
                Create account
              </Button>
            </FormControl>
          </Box>
        </Box>
      </PageContainer>
    </Box>
  );
};
