/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Avatar,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import educatorLogo from '../../../Assets/TeacherLemmy.png';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { registerTeacher } from '../../../redux/slices/teacherSlice';
import { Toast } from '../../../LibraryComponents/Toast';
import { AppDispatch, RootState } from '../../../redux/store';
import { initialValues, signUpSchema } from './TeacherCreateAccountHelpers';
import {
  getDistricts,
  getGrades,
  getStates,
} from '../../../redux/slices/resourcesSlices';
import { Spinner } from '../../../LibraryComponents/Spinner';
import _ from 'lodash';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { useGTM } from '../../../hooks/useGTM';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const debouncedSave = _.debounce((dispatchFunc) => {
  dispatchFunc(getDistricts());
  dispatchFunc(getStates());
  dispatchFunc(getGrades());
}, 1000);

export const TeacherCreateAccount = () => {
  const { track, pageView } = useGTM();
  const { role: userRole, userId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { states, grades, districts } = useSelector(
    (state: RootState) => state.resources,
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const licenseKeyCode = searchParams.get('licenseKey') ?? '';

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: initialValues(licenseKeyCode) || {},
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      values.districtID = values.districtID
        ? values.districtID
        : '00000000-0000-0000-0000-000000000000';
      track('create_account_attempt', { method: 'teacher_create_account' });
      dispatch(
        registerTeacher({ ...values, organizationID: values.districtID }),
      )
        .then((res: any) => {
          setIsLoading(false);
          if (res.error) {
            console.log('Submit Registration Error');
            setOpen(true);
            return setErrorMessage(
              res.payload.message.replace(/['"]+/g, '') ||
                'Oops, there was a problem creating your account. Please try again, or contact us if this error persists.',
            );
          }
          // console.log('Registration Result Is' + JSON.stringify(res, null, 2));
          // track('create_account_success', {
          //   method: 'teacher_login',
          //   userId: res.data.userId,
          // });
          navigate(`${routeIDs.LOGIN}?role=teacher&registration=completed`);
        })
        .catch((error) => {
          setIsLoading(false);
          track('create_account_failure', {
            method: 'teacher_create_account',
            errorCode: error.statusCode,
          });
          if (error) {
            setOpen(true);
            setErrorMessage(
              error.message ||
                'Oops, there was a problem creating your account. Please try again, or contact us if this error persists.',
            );
          }
        });
    },
  });

  const selectedState = states?.find(
    (state) => state.stateID === formik.values.stateID,
  );

  const closeToast = (isOpen: boolean) => {
    isOpen ? setOpen(false) : setOpen(true);
    setErrorMessage(null);
  };

  const handleCreateAccountStudent = () => {
    navigate(`${routeIDs.CREATE_ACCOUNT}?role=student`);
  };

  useEffect(() => {
    pageView('/createAccount');
    if (userRole === 'student' && userId) {
      navigate(routeIDs.STUDENT);
    } else if (userRole === 'teacher' && userId) {
      navigate('/');
    }
  }, [navigate, userRole]);

  useEffect(() => {
    debouncedSave(dispatch);
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box sx={{ margin: 2 }}>
      {!states && !grades && !districts && <Spinner />}
      <PageContainer
        styles={{
          maxWidth: '500px',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '10px',
          mt: 4,
          mb: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#003366',
              width: '100%',
              borderRadius: '10px 10px 0 0',
              minHeight: '140px',
              position: 'relative',
              mb: '20px',
            }}
          >
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', fontWeight: '600', color: '#fff' }}
              gutterBottom
            >
              Create Educator Account
            </Typography>
            <Box sx={{ m: 'auto', position: 'absolute', top: '90px' }}>
              <Avatar
                sx={{
                  m: 'auto',
                  width: 80,
                  height: 80,
                }}
              >
                <Box sx={{ marginLeft: '6px', marginTop: '5px' }}>
                  <img
                    src={educatorLogo}
                    alt="educator-logo"
                    width={120}
                    height={120}
                  />
                </Box>
              </Avatar>
              <Typography
                variant="body2"
                color="#9C27B0"
                component={Link}
                to={`${routeIDs.CREATE_ACCOUNT}?role=student`}
                sx={{
                  textDecoration: 'none',
                }}
                onClick={handleCreateAccountStudent}
              >
                Click here to create a student account instead
              </Typography>
            </Box>
          </Box>
          <Box
            component={'form'}
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '300px', md: '400px' },
              marginTop: '40px',
            }}
          >
            <Typography variant="h6">Account Info</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FormsControl
                  control="input"
                  type="text"
                  name="firstName"
                  label="First Name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormsControl
                  control="input"
                  type="text"
                  name="lastName"
                  label="Last Name"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <FormsControl
              control="input"
              type="text"
              name="email"
              label="Email"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="password"
              name="password"
              label="Password"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="text"
              name="licenseKey"
              label="License Key (Optional)"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="text"
              name="referralCode"
              label="Who referred you? (Optional)"
              formik={formik}
            />
            <Typography variant="h6">School Info</Typography>
            <FormsControl
              control="select"
              name="stateID"
              formik={formik}
              helperText={formik.touched['stateID'] && formik.errors['stateID']}
              data-testid={`${'stateID'}-helper-text`}
              label="-- Select State --"
              children={states?.map((option) => (
                <MenuItem
                  key={option?.stateID}
                  value={option?.stateID}
                  sx={{ color: 'gray' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            />
            <FormsControl
              control="select"
              name="gradeID"
              formik={formik}
              label="-- Select Grade --"
              children={grades?.map((option) => (
                <MenuItem
                  key={option?.gradeID}
                  value={option?.gradeID}
                  sx={{ color: '#000' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            />
            {selectedState?.name === 'Texas' && (
              <Autocomplete
                data-testid="districtID"
                sx={{ mt: 1 }}
                fullWidth
                options={(districts as []) || []}
                getOptionLabel={(option) => option.name}
                value={
                  districts && formik.values.districtID
                    ? districts.find(
                        (option) =>
                          option.districtID === formik.values.districtID,
                      )
                    : null
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    'districtID',
                    newValue ? newValue.districtID : '',
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    label="-- Select District --"
                    error={
                      formik.touched.districtID && formik.errors.districtID
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.districtID && formik.errors.districtID
                    }
                    name="districtID"
                    id="districtID"
                  />
                )}
              />
            )}
            <FormsControl
              control="input"
              type="text"
              name="schoolName"
              formik={formik}
              label="School/Organization Name"
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Toast
                isOpen={open}
                severity="error"
                onClose={() => closeToast(true)}
                styles={{ width: '400px', textAlign: 'center', top: '10px' }}
                errorMessage={errorMessage}
              />
            </Box>
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: '16px',
                  bgcolor: '#003366',
                  fontWeight: 700,
                }}
                endIcon={<HowToRegIcon sx={{ fontSize: '24px' }} />}
              >
                Register
              </Button>
            </FormControl>
            <Typography variant="body2" mb={3}>
              Already have an account?{' '}
              <Typography
                component={Link}
                to={`${routeIDs.LOGIN}?role=teacher`}
                variant="body2"
                sx={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Sign in instead
              </Typography>
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Box>
  );
};
