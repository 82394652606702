import styled from 'styled-components';
import { IStyledImprovementProps } from '../../types';
import { getColorScoreChange } from '../../ClassroomDashboardHelpers/getColorScoreChange';

export const StyledBoard = styled.div`
  padding: 20px;
  border-radius: 10px;
  .MuiToolbar-root {
    border-radius: 10px;
  }
  .MuiPaper-root {
    box-shadow: none;
  }
  .check-icon {
    fill: #0f7d2d;
    font-weight: 800;
    font-size: 28px;
  }
  .percent {
    color: #696969;
    font-size: 19px;
  }
  p {
    margin: 0;
  }
`;

export const StyledImprovement = styled.div<IStyledImprovementProps>`
  display: flex;
  align-items: end;
  color: #696969;
  .percent {
    color: ${({ percent = 0 }) => getColorScoreChange(percent)};
    font-size: 20px;
    font-weight: 800;
  }
`;
