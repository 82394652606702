import { Box, Typography, debounce } from '@mui/material';
import React, { useEffect } from 'react';
import LemmySherlock from '../../../../Assets/Lemmy_Sherlock.png';
import { Link } from 'react-router-dom';
import { ExternalUrlIdentifier } from '../../../ClassroomTile/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { getExternalUrls } from '../../../../redux/slices/resourcesSlices';

export const ListResourcesEmpty = ({ label }: { label: string }) => {
  const { externalUrls } = useSelector((state: RootState) => state.resources);
  const dispatch = useDispatch<AppDispatch>();

  const debouncedGetExternalUrls = debounce(
    () => dispatch(getExternalUrls()),
    500,
  );

  useEffect(() => {
    if (externalUrls.length === 0) {
      debouncedGetExternalUrls();
    }
  }, [externalUrls.length]);

  const handleExternalUrl = () => {
    const foundUrl = externalUrls.find(
      (url) => url.urlId === ExternalUrlIdentifier.ContactUs,
    );
    return foundUrl?.url ?? '';
  };
  const removeLastLetterIfS = (text: string) => {
    if (text.length > 0) {
      const lastLetter = text.slice(-1);

      if (lastLetter.toLowerCase() === 's') {
        return text.slice(0, -1);
      }
    }

    return text;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '620px',
        margin: 'auto',
      }}
    >
      <Box component={'img'} src={LemmySherlock} width={300} />
      <Typography
        variant="h6"
        color="#7F7F7F"
        fontWeight={500}
        textAlign={'center'}
      >
        {`Oops! It looks like there are no ${removeLastLetterIfS(label)}s for
    this course.`}{' '}
        <Typography
          component={Link}
          to={handleExternalUrl()}
          target="_blank"
          sx={{
            textDecoration: 'none',
            color: '#1976d2',
            fontSize: '1.12rem',
          }}
        >
          Contact Us
        </Typography>{' '}
        if you’d like us to build some!
      </Typography>
    </Box>
  );
};
