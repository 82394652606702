export const LICENSE_STATUS_MESSAGES = {
  NO_LICENSE: {
    message:
      'You do not have a premium license or subscription. If you’d like to learn more, {Contact Us}.',
    color: '#FF5252',
  },
  LICENSE_EXPIRED: {
    message:
      'Your premium license key expired on {ExpirationDate}. {Contact Us} to renew your license.',
    color: '#FF5252',
  },
  LICENSE_EXPIRING: {
    message:
      'Your Lemoney Learning Premium access is expiring soon on {ExpirationDate}. Please {Contact Us} to renew your license.',
    color: '#FFC107',
  },
  LICENSE_VALID: {
    message:
      'Your have access to Lemoney Learning Premium until {ExpirationDate}. Feel free to {Contact Us} for any inquiries.',
    color: '#4CAF50',
  },
  ACTIVE_SUBSCRIPTION: {
    message:
      'Your Premium subscription will renew automatically on {ExpirationDate}.',
    color: '#4CAF50',
  },
  PAST_DUE_SUBSCRIPTION: {
    message:
      'Your Premium subscription is past due. {Contact Us} to renew your subscription.',
    color: '#FFC107',
  },
  CANCELLED_SUBSCRIPTION: {
    message:
      'Your Premium subscription will NOT renew and expires on {ExpirationDate}. {Contact Us} to renew your subscription.',
    color: '#FF5252',
  },
};
