import React from "react";
import { ModalCloseButton, ModalContainer, ModalOverlay } from "./Styled.Modal";
import { ModalProps } from "./types";

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  showCloseButton = true,
  maxWidth = 600,
}) => {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <ModalOverlay onClick={handleOverlayClick}>
          <ModalContainer maxWidth={maxWidth}>
            {showCloseButton && (
              <ModalCloseButton onClick={onClose}>X</ModalCloseButton>
            )}
            {children}
          </ModalContainer>
        </ModalOverlay>
      )}
    </>
  );
};

export default Modal;
