export const generateMessageError = (status: number | null | undefined) => {
  switch (status) {
    case 400:
      return 'Bad Request: Invalid input. Please check your request and try again.';
    case 401:
      return 'Unauthorized. Please check your credentials.';
    case 404:
      return 'Not Found.';
    case 500:
      return 'Something went wrong. Please try again later.';
    default:
      return 'Something went wrong. Please try again later.';
  }
};
