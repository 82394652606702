import { Drawer, Grid, debounce } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../../LibraryComponents/Spinner';
import { Minimized } from '../Minimized';
import { ClassProgressComponent } from '../ClassProgress';
import useResize from '../../../../hooks/useResize';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { IQuizData } from '../../../../redux/types/quizTypes';
import {
  getPostQuizDefinition,
  getPreQuizDefinition,
} from '../../../../redux/slices/quiz';
import { getLessonProgress } from '../../../../redux/slices/lessonsSlice';
import { routeIDs } from '../../../../Routes/RouteIds.routes';
import { useNavigate } from 'react-router-dom';
import { IAsideComponentProps } from '../../types';

export const AsideComponent = ({
  minimized,
  isLoading,
  open,
  toggleDrawer,
  setMinimized,
  setOpen,
  setIsLoading,
  setIsCompleted,
  setIndexLesson,
  handleResetQuestions,
  setShowQuestions,
  setIsPreQuiz,
  setQuestions,
}: IAsideComponentProps) => {
  const { classProgress } = useSelector((state: RootState) => state.lesson);
  const studentData = useSelector(
    (state: RootState) => state.student.studentData,
  );
  const { userId: studentId, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const { preQuizDefinition, postQuizDefinition } = useSelector(
    (state: RootState) => state.quiz,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useResize();

  const [expanded, setExpanded] = useState<string | false>(false);

  const debouncedGetPreQuizDefinition = debounce(() => {
    dispatch(getPreQuizDefinition({ studentId, token }));
  }, 500);

  const debouncedGetPostQuizDefinition = debounce(() => {
    dispatch(getPostQuizDefinition({ studentId, token }));
  }, 500);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleLessonProgress = (lessonId: string) => {
    setShowQuestions(false);
    setQuestions(undefined);
    toggleDrawer(false);
    setIsLoading(true);
    setIsCompleted({ done: false, type: null, name: '' });
    setIndexLesson(0);
    dispatch(getLessonProgress({ studentId, token, lessonId }))
      .then((res) => {
        if (res.payload === 404) {
          navigate(routeIDs.PAGE_NOT_FOUND);
        }
      })
      .catch((err) => console.error(err));

    handleResetQuestions();
    setShowQuestions(false);
  };

  const getPreQuiz = async () => {
    setIsPreQuiz(true);
    toggleDrawer(false);
    setIsLoading(true);
    setIsCompleted({ done: false, type: null, name: '' });
    setIndexLesson(0);
    try {
      const response = await dispatch(
        getPreQuizDefinition({ studentId, token }),
      );
      if (response.payload === 404) {
        navigate(routeIDs.PAGE_NOT_FOUND);
      }
      const questions = response.payload;
      handleQuizResponse(questions);
    } catch (err) {
      console.error('Error obtaining the pre-quiz:', err);
    }
  };

  const getPostQuiz = async () => {
    toggleDrawer(false);
    setIsPreQuiz(false);
    setIsLoading(true);
    setIndexLesson(0);
    setIsCompleted({ done: false, type: null, name: '' });
    try {
      const response = await dispatch(
        getPostQuizDefinition({ studentId, token }),
      );
      if (response.payload === 404) {
        navigate(routeIDs.PAGE_NOT_FOUND);
      }
      const questions = response.payload;
      handleQuizResponse(questions);
    } catch (err) {
      console.error('Error obtaining the post-quiz:', err);
    }
  };

  const handleQuizResponse = (
    questions: React.SetStateAction<IQuizData | undefined>,
  ) => {
    setQuestions(questions);
    setIndexLesson(0);
    setIsCompleted({ done: false, type: null, name: '' });
    setShowQuestions(true);
    handleResetQuestions();
  };

  useEffect(() => {
    if (preQuizDefinition.questions.length === 0) {
      debouncedGetPreQuizDefinition();
    }
  }, []);

  useEffect(() => {
    if (postQuizDefinition.questions.length === 0) {
      debouncedGetPostQuizDefinition();
    }
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={minimized}
      sx={{
        overflowY: 'scroll',
        height: { lg: '100%' },
        maxWidth: { xs: '0', lg: minimized === 1 ? '60px' : '100%' },
        backgroundColor: '#fff',
      }}
    >
      {isLoading && <Spinner />}
      {width < 1200 ? (
        <Drawer
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            top: '67px',
            '.MuiModal-backdrop ,.MuiDrawer-paper,.MuiDrawer-modal': {
              top: '67px',
            },
          }}
          variant="temporary"
          open={open}
          onClose={() => toggleDrawer(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid container justifyContent="center">
            <Minimized
              minimized={minimized}
              setMinimized={setMinimized}
              setOpen={setOpen}
            />
            <ClassProgressComponent
              classProgress={classProgress}
              preQuizId={studentData?.preQuizId as string}
              expanded={expanded}
              handleChange={handleChange}
              handleLessonProgress={handleLessonProgress}
              minimized={minimized}
              getPreQuiz={getPreQuiz}
              getPostQuiz={getPostQuiz}
              setIsCompleted={setIsCompleted}
            />
          </Grid>
        </Drawer>
      ) : (
        <Grid container justifyContent="center">
          <Minimized
            minimized={minimized}
            setMinimized={setMinimized}
            setOpen={setOpen}
          />
          <ClassProgressComponent
            classProgress={classProgress}
            preQuizId={studentData?.preQuizId as string}
            expanded={expanded}
            handleChange={handleChange}
            handleLessonProgress={handleLessonProgress}
            minimized={minimized}
            getPreQuiz={getPreQuiz}
            getPostQuiz={getPostQuiz}
            setIsCompleted={setIsCompleted}
          />
        </Grid>
      )}
    </Grid>
  );
};
