import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ICurriculumGuest, IGuest } from '../types/guestTypes';

const initialState: IGuest = {
  status: 'idle',
  curriculumId: '',
  curriculums: {
    curriculum: {
      id: '',
      name: '',
      stateId: '',
      gradeId: '',
      preQuizId: '',
      postQuizId: '',
      curriculumPdfUrl: null,
      standardsAlignmentPdfUrl: null,
      createdDate: null,
    },
    modules: [{ moduleId: '', moduleName: null, sortOrder: 0, lessons: [] }],
  },
  lesson: null,
  preQuizzes: null,
  postQuizzes: null,
  progress: [],
};

const API_URL = process.env.REACT_APP_API_URL;

export const saveCurriculumId = createAsyncThunk(
  'guest/saveCurriculumId',
  (curriculumID: string) => {
    const response = curriculumID;
    return response;
  },
);

export const getCurriculumGuest = createAsyncThunk(
  'guest/getCurriculumGuest',
  async (curriculumID: string) => {
    const response = await fetch(
      `${API_URL}/guests/curriculums/${curriculumID}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Server Error');
    }
    return (await response.json()) as ICurriculumGuest;
  },
);

export const getLessonGuest = createAsyncThunk(
  'guest/getLessonGuest',
  async (LessonID: string) => {
    const response = await fetch(
      `${API_URL}/guests/lessons/${LessonID}/contents`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Server Error');
    }
    return await response.json();
  },
);

export const getPreQuizzesGuest = createAsyncThunk(
  'guest/getPreQuizzesGuest',
  async (quizID: string) => {
    const response = await fetch(`${API_URL}/guests/quizzes/${quizID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Server Error');
    }
    return await response.json();
  },
);

export const getPostQuizzesGuest = createAsyncThunk(
  'guest/getPostQuizzesGuest',
  async (quizID: string) => {
    const response = await fetch(`${API_URL}/guests/quizzes/${quizID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Server Error');
    }
    return await response.json();
  },
);

export const resetLessonGuest = createAsyncThunk(
  'guest/resetLessonGuest',
  async () => {
    return 'reset Lesson';
  },
);

export const saveLocalLessonProgress = createAsyncThunk(
  'guest/saveLocalLessonProgress',
  async ({
    data,
  }: {
    data: {
      lessonId: string;
      counterLesson: number;
      totalLessons: number;
      completed: boolean;
    }[];
  }) => {
    return data;
  },
);

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    resetGuestInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(saveCurriculumId.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveCurriculumId.fulfilled, (state, action) => {
      state.status = 'idle';
      state.curriculumId = action.payload;
    });
    builder.addCase(saveCurriculumId.rejected, (state) => {
      state.status = 'failed';
      state.curriculumId = '';
    });
    builder.addCase(getCurriculumGuest.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCurriculumGuest.fulfilled, (state, action) => {
      state.status = 'idle';
      state.curriculums = action.payload;
    });
    builder.addCase(getCurriculumGuest.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(getLessonGuest.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getLessonGuest.fulfilled, (state, action) => {
      state.status = 'idle';
      state.lesson = action.payload;
    });
    builder.addCase(getLessonGuest.rejected, (state) => {
      state.status = 'failed';
      state.lesson = null;
    });
    builder.addCase(getPreQuizzesGuest.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getPreQuizzesGuest.fulfilled, (state, action) => {
      state.status = 'idle';
      state.preQuizzes = action.payload;
    });
    builder.addCase(getPreQuizzesGuest.rejected, (state) => {
      state.status = 'failed';
      state.preQuizzes = null;
    });
    builder.addCase(getPostQuizzesGuest.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getPostQuizzesGuest.fulfilled, (state, action) => {
      state.status = 'idle';
      state.postQuizzes = action.payload;
    });
    builder.addCase(getPostQuizzesGuest.rejected, (state) => {
      state.status = 'failed';
      state.postQuizzes = null;
    });
    builder.addCase(resetLessonGuest.fulfilled, (state) => {
      state.status = 'idle';
      state.preQuizzes = null;
      state.postQuizzes = null;
      state.curriculumId = '';
      state.lesson = null;
    });
    builder.addCase(saveLocalLessonProgress.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(saveLocalLessonProgress.fulfilled, (state, action) => {
      state.status = 'idle';
      state.progress = action.payload;
    });
    builder.addCase(saveLocalLessonProgress.rejected, (state) => {
      state.status = 'failed';
      state.progress = [];
    });
  },
});
export const { resetGuestInfo } = guestSlice.actions;

export default guestSlice.reducer;
