import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPostQuizDefinition,
  getPreQuizDefinition,
} from '../../../../redux/slices/quiz';
import { AppDispatch, RootState } from '../../../../redux/store';
import { debounce } from 'lodash';
import { IQuizCompletedProps } from '../../types';

export const QuizCompleted = ({
  totalQuestions,
  isPreQuiz,
  type,
  label,
  isCompleted,
}: IQuizCompletedProps) => {
  const {
    userId: studentId,
    token,
    role,
  } = useSelector((state: RootState) => state.auth);
  const { answerLocalPostGuest, answerLocalPreGuest } = useSelector(
    (state: RootState) => state.quiz,
  );

  const dispatch = useDispatch<AppDispatch>();

  const [totalAnswersCorrect, setTotalAnswersCorrect] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showTotalLabel, setShowTotalLabel] = useState(false);

  const getQuestions = async () => {
    try {
      setIsLoading(true);
      setShowTotalLabel(true);
      let response;
      if (isPreQuiz) {
        response = await dispatch(getPreQuizDefinition({ studentId, token }));
      } else {
        response = await dispatch(getPostQuizDefinition({ studentId, token }));
      }

      const questions = response.payload;
      let correctCount = 0;

      if (questions) {
        for (const question of questions.questions) {
          if (question.isCorrect) {
            correctCount++;
          }
        }
      }

      setTotalAnswersCorrect(correctCount);
      setIsLoading(false);
    } catch (err) {
      console.error('Error obtaining quiz questions:', err);
      setIsLoading(false);
      setShowTotalLabel(true);
    }
  };
  const debounceGetQuestions = debounce(() => getQuestions(), 500);

  useEffect(() => {
    if (role !== 'guest') debounceGetQuestions();
  }, [isCompleted.done]);

  const calculatePercentage = (numerator: number, denominator: number) => {
    const percentage = (numerator / denominator) * 100;
    const roundedPercentage = Math.round(percentage);
    return roundedPercentage;
  };

  const percentageStudent = calculatePercentage(
    totalAnswersCorrect,
    totalQuestions,
  );

  const answersLocal = isPreQuiz
    ? answerLocalPreGuest.correctAnswers
    : answerLocalPostGuest.correctAnswers;

  const percentageGuest = calculatePercentage(answersLocal, totalQuestions);

  const totalCorrectAnswersGuest = answersLocal;

  const percentage = role === 'student' ? percentageStudent : percentageGuest;

  const totalCorrectAnswers =
    role === 'student' ? totalAnswersCorrect : totalCorrectAnswersGuest;

  return (
    <Box
      sx={{
        background: '#003366',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: { xs: '80vh' },
      }}
    >
      <CheckCircleOutlineIcon
        sx={{
          width: { xs: '60px', sm: '150px' },
          height: { xs: '60px', sm: '150px' },
          mb: 2,
          color: '#66b67b',
        }}
      />
      <Typography
        variant="h2"
        color="#003366"
        fontWeight={500}
        mb={2}
        data-testid="quiz-completed"
        textTransform={'capitalize'}
        sx={{
          fontSize: { xs: '2rem', md: '3.75rem' },
          color: '#fff',
          textAlign: 'center',
        }}
      >
        {type === 'question' ? `${label} Complete!` : `${label} Completed!`}
      </Typography>
      {type === 'question' &&
        showTotalLabel &&
        (isLoading ? (
          <CircularProgress color="success" />
        ) : (
          <Typography
            data-testid="score-quiz-completed"
            variant="body1"
            sx={{ color: '#fff' }}
          >
            You got {totalCorrectAnswers} correct out of {totalQuestions}{' '}
            questions for a score of {percentage}%
          </Typography>
        ))}
    </Box>
  );
};
