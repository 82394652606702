import { routeIDs } from '../../../Routes/RouteIds.routes';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import HandymanIcon from '@mui/icons-material/Handyman';
import { TileToolTops } from '../../../constants/TileToolTips';
import { Box } from '@mui/material';
import { ReactComponent as SchoolABC } from '../../../Assets/school-abc.svg';
import SchoolIcon from '@mui/icons-material/School';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { ICurriculums } from '../../../redux/types/resourcesTypes';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BadgeIcon from '@mui/icons-material/Badge';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';

export const commonStylesSvg = {
  fill: '#003366',
  width: '70px',
  height: '70px',
};

export const resources = (
  curriculumData: ICurriculums | undefined,
  getAskAnExpertUrl: string,
) => [
  {
    id: 1,
    label: 'Standards Alignment',
    url: `${curriculumData?.standardsAlignmentPdfUrl}`,
    icon: (
      <Box component={SchoolABC} viewBox="0 0 24 24" sx={commonStylesSvg} />
    ),
    isPremium: false,
    tileToolTipsMsg: TileToolTops.standardsAlignmentPdfUrl,
    isLink: true,
    order: 1,
  },
  {
    id: 2,
    label: 'Scope & Sequence / Pacing Guide',
    url: `${curriculumData?.pacingGuideUrl}`,
    icon: <LocalLibraryIcon sx={commonStylesSvg} />,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.pacingGuide,
    isLink: true,
    order: 2,
  },
  {
    id: 3,
    label: 'Instructional Materials',
    url: `${curriculumData?.curriculumPdfUrl}`,
    icon: <SchoolIcon sx={commonStylesSvg} />,
    order: 3,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.curriculumPdfUrl,
    isLink: true,
  },
  {
    id: 3.1,
    label: 'Instructional Materials Lesson Breakouts',
    url: `${curriculumData?.curriculumBreakoutPdfUrl}`,
    icon: <DynamicFeedIcon sx={commonStylesSvg} />,
    order: 3,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.curriculumBreakoutPdfUrl,
    isLink: true,
  },
  {
    id: 4,
    label: 'Assessment Question Bank',
    url: `${curriculumData?.questionBankUrl}`,
    icon: <QuestionAnswerIcon sx={commonStylesSvg} />,
    order: 9,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.questionBankUrl,
    isLink: true,
  },
  {
    id: 6,
    label: 'Teacher Tips and Tricks',
    icon: <TipsAndUpdatesIcon sx={commonStylesSvg} />,
    url: `${curriculumData?.teacherTipsAndTricksUrl}`,
    order: 10,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.teacherTipsAndTricksUrl,
    isLink: true,
  },
  {
    id: routeIDs.EDUCATOR_CLASSROOM_ACTIVITIES,
    label: 'Classroom Activities',
    icon: <DirectionsRunIcon sx={commonStylesSvg} />,
    url: `/educator/classroomActivities?curriculumId=${curriculumData?.id}`,
    tileToolTipsMsg: TileToolTops.classroomActivities,
    order: 4,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_VIDEO_LIBRARY,
    label: 'Instructional Videos',
    icon: <OndemandVideoIcon sx={commonStylesSvg} />,
    url: `/educator/videoLibrary?curriculumId=${curriculumData?.id}`,
    tileToolTipsMsg: TileToolTops.instructionalVideos,
    order: 7,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_ADDITIONAL_RESOURCES,
    label: `Third-Party Resources`,
    icon: <AddCircleOutlineIcon sx={commonStylesSvg} />,
    url: `/educator/additionalResources?curriculumId=${curriculumData?.id}`,
    order: 16,
    tileToolTipsMsg: TileToolTops.thirdPartyResource,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_COACHING_VIDEOS,
    label: 'Coaching Videos',
    url: `/educator/coachingVideos?curriculumId=${curriculumData?.id}`,
    icon: <VideoCallIcon sx={commonStylesSvg} />,
    order: 11,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.coachingVideos,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_PRE_BUILT_ASSESSMENTS,
    label: `Pre-Built Assessments`,
    icon: <AssessmentIcon sx={commonStylesSvg} />,
    url: `/educator/preBuiltAssessments?curriculumId=${curriculumData?.id}`,
    order: 8,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.preBuiltAssessments,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_WORKSHEETS,
    label: `Worksheets`,
    icon: <FormatShapesIcon sx={commonStylesSvg} />,
    url: `/educator/worksheets?curriculumId=${curriculumData?.id}`,
    order: 6,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.worksheet,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_PROJECTS,
    label: 'Projects',
    icon: <HandymanIcon sx={commonStylesSvg} />,
    url: `/educator/projects?curriculumId=${curriculumData?.id}`,
    isPremium: true,
    order: 5,
    tileToolTipsMsg: TileToolTops.projects,
    isLink: false,
  },
  {
    id: routeIDs.EDUCATOR_CHAT,
    label: 'LemoneyAI',
    icon: <PsychologyIcon sx={commonStylesSvg} />,
    url: `/educator/chat`,
    isPremium: true,
    order: 12,
    tileToolTipsMsg: TileToolTops.chatUI,
    isLink: false,
    subText: '(This feature is still in beta)',
  },
  // {
  //   id: 15,
  //   label: 'News Roundup',
  //   icon: <NewspaperIcon sx={commonStylesSvg} />,
  //   url: `${curriculumData?.newsRoundupUrl}`,
  //   order: 14,
  //   isPremium: true,
  //   tileToolTipsMsg: TileToolTops.newsRoundup,
  //   isLink: true,
  // },
  {
    id: 16,
    label: 'Vocab Bank',
    icon: <MenuBookIcon sx={commonStylesSvg} />,
    url: `${curriculumData?.vocabBankUrl}`,
    order: 6,
    isPremium: true,
    tileToolTipsMsg: TileToolTops.vocabBank,
    isLink: true,
    isHidden: true,
  },
];
