import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  confirmationCode: Yup.string().min(6).max(6).required(),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must contain at least one uppercase letter, one lowercase letter, one number and one symbol',
    )
    .required(),
  confirmPassword: Yup.string()
    .min(8, 'Confirm Password must be at least 8 characters')
    .oneOf([Yup.ref('newPassword'), ''], 'The password does not match')
    .required(),
});
