import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ILemmyTooltipsProps } from './types';

export const LemmyTooltip = ({
  tooltipTrigger,
  tooltipText,
  tooltipPlacement,
}: ILemmyTooltipsProps) => {
  return (
    <Tooltip
      title={
        <Box sx={{ display: 'flex' }}>
          <div>
            <img
              alt="teacher-lemmy"
              src={require('../../Assets/TeacherLemmy.png')}
              width="25"
            />
          </div>
          <Typography color="inherit">{tooltipText}</Typography>
        </Box>
      }
      placement={tooltipPlacement}
    >
      {tooltipTrigger}
    </Tooltip>
  );
};
