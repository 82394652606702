import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { cookiePolicy } from '../../cookiePolicy';
import { useGTM } from '../../hooks/useGTM';

export const CookiePolicy = () => {
  const { pageView } = useGTM();
  useEffect(() => {
    pageView('/cookiePolicy');
  }, []);
  return (
    <Box
      sx={{ padding: '20px', marginLeft: '20px', marginRight: '20px' }}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(cookiePolicy),
      }}
    />
  );
};
