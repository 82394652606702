import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  classroomCode: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  userName: Yup.string()
    .notOneOf(['username'], 'Already Taken (if username al ready exists)')
    .required(),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must contain at least one uppercase letter, one lowercase letter, one number and one symbol'
    )
    .required(),
  confirmPassword: Yup.string()
    .min(8, 'Confirm Password must be at least 8 characters')
    .oneOf([Yup.ref('password'), ''], 'The password does not match')
    .required(),
});
