import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Box, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { AddButton, ClassroomName, Span } from './Styled.ClassSummary';
import { ClassSummaryProps } from '../../types';
import {
  lockClassroom,
  removeClassroom,
  renameClassroom,
  unlockClassroom,
} from '../../../../redux/slices/classroomSlice';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { DeleteConfirmationDialog } from '../../../../LibraryComponents/DeleteConfirmationDialog';
import { ShareClassroomDialog } from '../../../Classroom/ClassroomCard/Components/ShareClassroomDialog';
import { getCurriculums } from '../../../../redux/slices/resourcesSlices';
import { debounce } from 'lodash';
import Typography from '@mui/material/Typography';
import { routeIDs } from '../../../../Routes/RouteIds.routes';
import { renderStartIcon } from '../../ClassroomDashboardHelpers/rederStartIcon';

export const ClassSummary: React.FC<ClassSummaryProps> = ({
  classroomData,
  classroomId,
  teacherId,
  isLocked,
  handleClassroomLState,
}) => {
  const { curriculums } = useSelector((state: RootState) => state.resources);
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [classroomName, setClassroomName] = useState<string>(
    classroomData.classroomName,
  );
  const [oldClassroomName, setOldClassroomName] = useState<string>(
    classroomData.classroomName,
  );
  const [editClassroomName, setEditClassroomName] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoadingLocked, setIsLoadingLocked] = useState(false);

  const debouncedGetCurriculums = debounce(
    () => dispatch(getCurriculums()),
    500,
  );

  const handleRemoveClass = async () => {
    await dispatch(
      removeClassroom({
        teacherId: teacherId,
        classroomId: classroomId,
        token: accessToken,
      }),
    );
    navigate(routeIDs.EDUCATOR_CLASSROOMS);
    setDeleteDialogOpen(false);
  };

  const handleRenameClass = async () => {
    if (classroomName !== classroomData.classroomName) {
      await dispatch(
        renameClassroom({
          teacherId: teacherId,
          classroomId: classroomId,
          newName: classroomName,
          token: accessToken,
        }),
      )
        .then((res) => {
          setOldClassroomName(res.meta.arg.newName);
          return setClassroomName(res.meta.arg.newName);
        })
        .catch((err) => console.error(err));
    }
    setEditClassroomName(false);
  };

  const addStudents = () => {
    setDialogOpen(true);
  };

  const handleLockClassroom = async (): Promise<void> => {
    try {
      setIsLoadingLocked(true);
      const action = isLocked
        ? unlockClassroom({ teacherId, classroomId, token: accessToken })
        : lockClassroom({ teacherId, classroomId, token: accessToken });
      await dispatch(action)
        .then((res) => setIsLoadingLocked(false))
        .catch((err) => {
          setIsLoadingLocked(false);
          console.error(err);
        })
        .finally(() => setIsLoadingLocked(false));
      handleClassroomLState();
    } catch (error) {
      console.error(error);
      setIsLoadingLocked(false);
    }
  };

  useEffect(() => {
    if (curriculums?.length === 0) {
      debouncedGetCurriculums();
    }
  }, []);

  return (
    <Box padding={'20px'}>
      <Stack
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'20px 40px'}
        sx={{
          borderRadius: '10px',
          background: '#FFF',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              justifyContent: 'center',
              mb: '4px',
            }}
          >
            <Box>
              {editClassroomName ? (
                <ClassroomName
                  id="classroom-name"
                  type="text"
                  readOnly={!editClassroomName}
                  value={classroomName}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setClassroomName(e.target.value)}
                  isReadOnly={editClassroomName}
                />
              ) : (
                <Typography fontSize={'1.25rem'} m={'0 8px'}>
                  {classroomName}
                </Typography>
              )}
            </Box>
            {editClassroomName && (
              <>
                <Box>
                  <IconButton
                    aria-label="save"
                    onClick={handleRenameClass}
                    sx={{ padding: 0 }}
                  >
                    <CheckIcon
                      sx={{ fill: '#003366', width: '20px', height: '20px' }}
                    />
                  </IconButton>
                </Box>
                <div>
                  <IconButton
                    aria-label="cancel"
                    onClick={() => {
                      setClassroomName(oldClassroomName);
                      return setEditClassroomName(false);
                    }}
                    sx={{ padding: 0 }}
                  >
                    <ClearIcon
                      sx={{ fill: '#003366', width: '20px', height: '20px' }}
                    />
                  </IconButton>
                </div>
              </>
            )}
            {!editClassroomName && (
              <div>
                <IconButton
                  aria-label="edit"
                  onClick={() => setEditClassroomName(!editClassroomName)}
                  sx={{ padding: 0 }}
                >
                  <EditIcon
                    sx={{ fill: '#003366', width: '20px', height: '20px' }}
                  />
                </IconButton>
              </div>
            )}
          </Box>{' '}
          <Box sx={{ display: 'flex', m: '10px 0 10px 0' }}>
            <Box sx={{ mr: '10px' }}>
              <Span>{classroomData?.classroomCode}</Span>
            </Box>
            <Box
              sx={{
                color: '#637381',
                fontWeight: 400,
                marginBottom: '10px',
                fontSize: { xs: '14px' },
              }}
            >{`${classroomData.studentsClassProgress.length} STUDENTS`}</Box>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: '14px',
              color: '#637381',
              mb: { xs: '16px', sm: 0 },
            }}
          >{`Created ${new Date(
            classroomData.classroomCreatedDate,
          ).toLocaleDateString()}`}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Button
            id="lock/unlock-button"
            variant="outlined"
            startIcon={renderStartIcon(isLoadingLocked, isLocked)}
            sx={{
              borderColor: '#003366',
              marginRight: '10px',
              color: '#003366',
              minWidth: { xs: '100%', sm: '137px' },
              fontWeight: 800,
              borderRadius: '12px',
            }}
            onClick={handleLockClassroom}
            style={{
              transition: 'background-color 0.5s',
              backgroundColor: isLoadingLocked ? 'gray' : '',
            }}
          >
            {isLocked ? 'locked' : 'unlocked'}
          </Button>
          <AddButton
            variant="outlined"
            id="addStudent-button"
            startIcon={<Add sx={{ fill: '#003366' }} />}
            sx={{
              borderColor: '#003366',
              marginRight: '10px',
              color: '#003366',
              minWidth: { xs: '100%', sm: '137px' },
              mt: { xs: 1.5, sm: 0 },
              borderRadius: '12px',
            }}
            onClick={addStudents}
          >
            ADD STUDENTS
          </AddButton>
          <AddButton
            variant="outlined"
            id="classResources-button"
            sx={{
              borderColor: '#003366',
              marginRight: '10px',
              color: '#003366',
              minWidth: { xs: '100%', sm: '137px' },
              mt: { xs: 1.5, sm: 0 },
              borderRadius: '12px',
            }}
            onClick={() =>
              navigate(
                `${routeIDs.EDUCATOR_RESOURCES_LIST}/${classroomData.curriculumId}`,
              )
            }
          >
            Class Resources
          </AddButton>
          <IconButton
            aria-label="edit"
            onClick={() => setDeleteDialogOpen(true)}
            sx={{
              padding: '0',
              mt: { xs: 1.5, sm: 0 },
              ml: { xs: 1.5, sm: 0 },
            }}
          >
            <Typography display={{ xs: 'block', sm: 'none' }}>
              Remove Class
            </Typography>
            <DeleteIcon sx={{ fontSize: '35px', fill: '#003366' }} />
          </IconButton>
          <ShareClassroomDialog
            setDialogOpen={setDialogOpen}
            dialogOpen={dialogOpen}
            classroomCode={classroomData?.classroomCode}
          />
          <DeleteConfirmationDialog
            open={deleteDialogOpen}
            dialogTitle="Delete Classroom"
            confirmationText={`Are you sure you want to delete the classroom "${classroomData.classroomName}"? This cannot be undone.`}
            actionButtonLabel="Delete"
            validationString="DELETE"
            handleDelete={handleRemoveClass}
            handleCloseModal={() => setDeleteDialogOpen(false)}
            mode="confirmation"
          />
        </Box>
      </Stack>
    </Box>
  );
};
