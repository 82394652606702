import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  Chip,
  Divider,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu } from '../../../LibraryComponents/Menu';
import { ShareClassroomDialog } from './Components/ShareClassroomDialog';
import { ClassroomCardProps } from './types';
import { RootState, useAppDispatch } from '../../../redux/store';
import {
  removeClassroom,
  renameClassroom,
  lockClassroom,
  unlockClassroom,
} from '../../../redux/slices/classroomSlice';
import { DeleteConfirmationDialog } from '../../../LibraryComponents/DeleteConfirmationDialog';
import { useSelector } from 'react-redux';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export const ClassroomCard: React.FC<ClassroomCardProps> = ({
  teacherId,
  classroomId,
  classroomCode,
  classroomTitle,
  studentCount,
  isLocked,
  onClassroomRemoved,
  onClassroomRenamed,
  handleClassroomLState,
  setIsLoading,
  index,
}) => {
  const { accessToken } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const menuOptions = [
    'Share Classroom Code',
    'Lock/Unlock Classroom',
    'Rename Classroom',
    'Delete Classroom',
  ];

  const goToClassDashboard = () => {
    navigate(
      `${routeIDs.EDUCATOR_CLASSROOM_DASHBOARD}/${teacherId}/${classroomId}`,
    );
  };

  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleDeleteClassroom = async () => {
    const resultAction = await dispatch(
      removeClassroom({ teacherId, classroomId, token: accessToken }),
    );
    if (removeClassroom.fulfilled.match(resultAction)) {
      onClassroomRemoved();
    }
    setDeleteDialogOpen(false);
  };

  const handleRenameClassroom = async () => {
    const resultAction = await dispatch(
      renameClassroom({ teacherId, classroomId, newName, token: accessToken }),
    );
    if (renameClassroom.fulfilled.match(resultAction)) {
      onClassroomRenamed(newName);
    }
    setRenameDialogOpen(false);
  };

  const handleLockClassroom = async () => {
    setIsLoading(true);
    if (isLocked) {
      await dispatch(
        unlockClassroom({ teacherId, classroomId, token: accessToken }),
      );
    } else {
      await dispatch(
        lockClassroom({ teacherId, classroomId, token: accessToken }),
      );
    }
    handleClassroomLState();
  };

  const handleClick = async (option: string) => {
    switch (option) {
      case 'Share Classroom Code':
        setDialogOpen(true);
        break;
      case 'Delete Classroom':
        setDeleteDialogOpen(true);
        break;
      case 'Rename Classroom':
        setNewName(classroomTitle);
        setRenameDialogOpen(true);
        break;
      case 'Lock/Unlock Classroom':
        handleLockClassroom();
        break;
      default:
        break;
    }
    setMenuOpen(false);
  };

  const handleIconStudent = (student: number) => {
    switch (student) {
      case 0:
        return <PersonOffIcon sx={{ m: '0 5px' }} fontSize="large" />;
      case 1:
        return <PersonIcon sx={{ m: '0 5px' }} fontSize="large" />;
      default:
        return <GroupsIcon sx={{ m: '0 5px' }} fontSize="large" />;
    }
  };

  return (
    <Card
      sx={{
        minWidth: 320,
        maxWidth: 320,
        minHeight: 255,
        maxHeight: 255,
        paddingBottom: '20px',
        cursor: 'pointer',
        margin: { xs: 'auto', md: 'inherit' },
        borderRadius: '20px',
      }}
      onClick={goToClassDashboard}
    >
      <CardContent
        data-testid="card-content"
        sx={{ background: '#003366', minHeight: '3rem' }}
      >
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Chip
            data-testid={`${classroomTitle}-1`}
            label={classroomCode}
            sx={{ background: 'gray', color: 'white', fontWeight: '600' }}
          />
          <Button
            data-testid={`${classroomTitle}-${index}`}
            id={`classroom-card-menu-button-${index}`}
            size="small"
            ref={anchorRef}
            onClick={(event) => {
              event.stopPropagation();
              handleToggle();
            }}
            sx={{ justifyContent: 'end' }}
          >
            <MoreVertIcon sx={{ color: 'white' }} />
          </Button>
          <Menu
            anchorRef={anchorRef}
            handleClick={handleClick}
            menuOpen={menuOpen}
            menuOptions={menuOptions}
          />
        </CardActions>
      </CardContent>
      <Divider sx={{ mt: -2 }}></Divider>
      <CardContent
        sx={{
          minHeight: '7rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: 'center', fontSize: '16px' }}
          data-testid={`title-${classroomTitle}-${index}`}
        >
          {classroomTitle}
        </Typography>
        <Box>{handleIconStudent(studentCount)}</Box>
        <Typography
          variant="button"
          color="text.secondary"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {studentCount <= 0 ? 'No' : studentCount}{' '}
          {studentCount === 1 ? 'Student' : 'Students'}
        </Typography>
      </CardContent>
      {isLocked && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: ' 10px',
          }}
        >
          <LockIcon
            sx={{ color: '#OOO', padding: '0 15px' }}
            fontSize="large"
            data-testid="LockIcon"
          />
          <Typography
            sx={{ color: '#003366', maxWidth: '260px', fontSize: '14px' }}
          >
            Students can’t join this class when it’s locked
          </Typography>
        </Box>
      )}
      <Box onClick={(event) => event.stopPropagation()}>
        <ShareClassroomDialog
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
          classroomCode={classroomCode}
        />
      </Box>
      <Box onClick={(event) => event.stopPropagation()}>
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          dialogTitle="Delete Classroom"
          confirmationText={`Are you sure you want to delete the classroom "${classroomTitle}"? This cannot be undone.`}
          actionButtonLabel="Delete"
          validationString="DELETE"
          handleDelete={handleDeleteClassroom}
          handleCloseModal={() => setDeleteDialogOpen(false)}
          mode="confirmation"
        />
      </Box>
      <Box onClick={(event) => event.stopPropagation()}>
        <Dialog
          open={renameDialogOpen}
          onClose={() => {
            setRenameDialogOpen(false);
          }}
          aria-labelledby="rename-dialog-title"
          fullWidth
          maxWidth={'xs'}
        >
          <DialogTitle
            id="rename-dialog-title"
            sx={{
              textAlign: 'center',
              bgcolor: '#003366',
              color: '#fff',
              fontWeight: 700,
              m: '2px 2px 20px 2px',
              borderRadius: '5px 5px 0 0',
            }}
          >
            Rename Classroom
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              data-testid="rename-classroom"
              id="name"
              label="Classroom Name"
              type="text"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setNewName('');
                return setRenameDialogOpen(false);
              }}
              sx={{ color: '#003366' }}
              data-testid="cancel-rename-classroom"
            >
              Cancel
            </Button>
            <Button
              data-testid="rename-classroom-button"
              onClick={handleRenameClassroom}
              sx={{ color: '#003366' }}
            >
              Rename
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Card>
  );
};
