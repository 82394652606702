const ZERO_PERCENT = 0;

export const getColorScoreChange = (percent: number): string => {
  if (percent === ZERO_PERCENT) {
    return '#696969';
  } else if (percent > ZERO_PERCENT) {
    return '#0f7d2d';
  } else {
    return 'red';
  }
};
