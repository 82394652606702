import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface GTMHook {
  track: (event: string, parameters?: Record<string, any>) => void;
  pageView: (path: string) => void;
}

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const useGTM = (): GTMHook => {
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const location = useLocation();

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      window.dataLayer ||
      !process.env.REACT_APP_GTM_ID
    )
      return;

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      if (isProduction) {
        window.dataLayer.push(arguments);
      }
    };
    window.gtag('js', new Date());

    return () => {
      document.body.removeChild(script);
    };
  }, [isProduction]);

  useEffect(() => {
    pageView(location.pathname + location.search);
  }, [location]);

  const sendToGTM = (args: any[]): void => {
    if (window.gtag && isProduction) {
      window.gtag(...args);
    }
  };

  const track = (event: string, parameters: Record<string, any> = {}): void => {
    sendToGTM(['event', event, parameters]);
  };

  const pageView = (path: string): void => {
    sendToGTM([
      'config',
      process.env.REACT_APP_GTM_ID as string,
      { page_path: path },
    ]);
  };

  return { track, pageView };
};
