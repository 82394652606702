import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout, roleSelection } from '../redux/slices/authSlice';

const redirectToStudentScreen = () => <Navigate to="/student" />;
const redirectToEducatorLogin = () => <Navigate to="/login?role=teacher" />;
const redirectToTeacherDashboard = () => <Navigate to="/" />;
const redirectToStudentLogin = () => <Navigate to="/login?role=student" />;

const studentRoles = ['student', 'guest'];
const premiumRoutes = [
  '/educator/coachingVideos',
  '/educator/preBuiltAssessments',
  '/educator/worksheets',
  '/educator/careerVideos',
  '/educator/projects',
];

export const EducatorPrivateRouter = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { role, userId, userIsPremium } = useSelector(
    (state: RootState) => state.auth,
  );
  const dispatch = useDispatch();
  const location = useLocation();

  if (role === 'student' && userId) {
    dispatch(roleSelection({ role: null }));
    dispatch(logout());
  }

  if (studentRoles.includes(role as string)) {
    return redirectToStudentScreen();
  }

  if (role !== 'teacher' || !userId) {
    return redirectToEducatorLogin();
  }
  if (!userIsPremium && premiumRoutes.includes(location.pathname)) {
    return redirectToTeacherDashboard();
  }

  return <>{children}</>;
};

export const StudentPrivateRouter = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { role, userId } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  if (role === 'teacher' && userId) {
    dispatch(roleSelection({ role: null }));
    dispatch(logout());
  }
  if (role === 'student' && !userId) {
    return redirectToStudentLogin();
  }

  if (role === 'teacher') {
    return redirectToTeacherDashboard();
  }

  if (!studentRoles.includes(role as string) && !userId) {
    return redirectToStudentLogin();
  }

  return <>{children}</>;
};
