import { Alert, Box, Grid } from '@mui/material';
import { IToastProps } from './types';
import { generateMessageError } from './toastHelpers/generateMessageError';

export const Toast = ({
  isOpen,
  onClose = () => {},
  severity = 'success',
  position = 'relative',
  styles,
  status,
  errorMessage = null,
}: IToastProps) => {
  return (
    <Box>
      {isOpen && (
        <Grid
          data-testid="toastSection"
          id="toast"
          sx={{
            position: { position },
            textAlign: 'center',
            width: '100%',
            ...styles,
          }}
        >
          <Alert
            onClose={onClose}
            severity={status === 401 ? 'error' : severity}
          >
            {status ? generateMessageError(status) : errorMessage}
          </Alert>
        </Grid>
      )}
    </Box>
  );
};
