import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormikValues } from 'formik';
import { IRequestMessage } from '../../types';

export const AIChatForm = ({
  formik,
  saveLoading,
  setRequestMessage,
}: {
  formik: FormikValues;
  saveLoading: boolean;
  setRequestMessage: React.Dispatch<React.SetStateAction<IRequestMessage[]>>;
}) => {
  const handleRequestMessage = () => {
    const newUserMessage: IRequestMessage = {
      id: new Date().getTime(),
      message: formik.values.message,
      dateTime: new Date(),
      isUser: true,
    };

    setRequestMessage((prevMessages) => [...prevMessages, newUserMessage]);
  };
  const totalCharacters = formik?.values?.message?.length ?? 0;

  const btnIsDisabled = saveLoading || totalCharacters > 250;
  return (
    <>
      {' '}
      <Box
        component={'form'}
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'flex',
          width: '100%',
          mt: 1,
          p: '0 16px 8px 16px',
          boxSizing: 'border-box',
        }}
      >
        <>
          <TextField
            variant="outlined"
            label="Write a message"
            fullWidth
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            autoComplete="off"
            sx={{
              marginRight: '5px',
              '.MuiInputBase-root': { borderRadius: '20px', height: '45px' },
              label: { top: '-5px' },
            }}
          />
        </>
        <Box sx={{ height: '45px', display: 'flex', alignItems: 'center' }}>
          <Typography fontSize={18}>{`(${totalCharacters}/250)`}</Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            borderRadius: '20px',
            minWidth: '100px',
            height: '45px',
            ml: '5px',
          }}
          disabled={btnIsDisabled}
          onClick={handleRequestMessage}
        >
          {saveLoading ? <CircularProgress size={24} /> : 'Send'}
        </Button>
      </Box>
      <Typography variant="body2" textAlign={'center'} p="0 16px 0 16px">
        Responses are AI generated and should be reviewed before use in the
        classroom. AI data includes information up until January 2022. Questions
        for information after that date may be unreliable.
        <br />
        LemoneyAI should not be used for math, financial advice, or legal
        advice, and should only be used as a supplemental resource.
      </Typography>
    </>
  );
};
