export const formatDateToOrdinal = (dateString: Date | null) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let dayWithOrdinal;
  if (day === 1 || day === 21 || day === 31) {
    dayWithOrdinal = `${day}st`;
  } else if (day === 2 || day === 22) {
    dayWithOrdinal = `${day}nd`;
  } else if (day === 3 || day === 23) {
    dayWithOrdinal = `${day}rd`;
  } else {
    dayWithOrdinal = `${day}th`;
  }

  return `${month} ${dayWithOrdinal}, ${year}`;
};
