import React from 'react';
import { useSelector } from 'react-redux';
import { getWorksheets } from '../../../redux/slices/resourcesSlices';
import { RootState } from '../../../redux/store';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const Worksheets = () => {
  const { worksheets } = useSelector((state: RootState) => state.resources);

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={worksheets}
      curriculumId={curriculumId}
      getResourceAction={getWorksheets}
      resourceTypeLabel={'worksheets'}
      label="Worksheets"
    />
  );
};
