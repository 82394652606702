import { PageContainer } from '../../LibraryComponents/PageContainer';
import { ICurriculums } from '../../redux/types/resourcesTypes';
import { TileCardContent } from './Components/TileCardContent';

export interface ICourseTileSectionProps {
  coursesList: ICurriculums[];
  courseTitle: string;
}

export const CourseTileSection = ({
  coursesList,
  courseTitle,
}: ICourseTileSectionProps) => {
  return (
    <PageContainer
      styles={{
        maxWidth: '95%',
        minHeight: 'auto',
        mb: '50px',
      }}
    >
      <TileCardContent
        courses={coursesList}
        isFullCourse={true}
        title={courseTitle}
      />
    </PageContainer>
  );
};
