import React, { useMemo, useState, useEffect } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { BoardProps, TableData } from '../../types';
import { StyledBoard } from './Styled.Board';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import Modal from '../../../../LibraryComponents/Modal/Modal';
import { EditStudent } from '../../../Classroom/EditStudent';
import { Theme } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CancelSaveButtons } from '../../../../LibraryComponents/CancelSaveButtons';
import { removeStudentFromClassroom } from '../../../../redux/slices/teacherSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { TeacherData } from '../../../../redux/types/teacherTypes';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import EditIcon from '@mui/icons-material/Edit';
import { getModuleStatus } from '../../ClassroomDashboardHelpers/getModuleStatus';
import { generateModuleLessonName } from '../../ClassroomDashboardHelpers/generateModuleLessonName';
import { getRowStatus } from '../../ClassroomDashboardHelpers/getRowStatus';
import { getRowImprovement } from '../../ClassroomDashboardHelpers/getRowImprovement';
import { NoStudent } from '../NoStudent';

export const Board: React.FC<BoardProps> = ({
  studentsData,
  classroomId,
  classroomName,
}) => {
  const teacherData: TeacherData | null = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );
  const token: string | null = useSelector(
    (state: RootState) => state.auth.accessToken,
  );

  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme<Theme>();

  const [tableData, setTableData] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [student, setStudent] = useState({
    firstName: '',
    lastName: '',
    studentId: '',
    userName: '',
  });
  const [newLessons, setNewLessons] = useState<string[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState({
    isOpen: false,
    title: '',
    text: '',
  });

  useEffect(() => {
    if (Array.isArray(studentsData)) {
      setTableData(
        studentsData.map((student, studentIndex) => {
          const modulesCompletionObj = student.modulesCompletion.flatMap(
            (moduleData, moduleIndex) => {
              return moduleData.lessonsCompletion.map<Record<string, number>>(
                (lesson, lessonIndex) => {
                  const name = generateModuleLessonName(
                    moduleData.moduleName,
                    lesson.lessonName,
                  );
                  return { [name]: lesson.completionPercentage };
                },
              );
            },
          );

          return {
            student: `${student.studentFirstName} ${student.studentLastName}`,
            pretest: `${student.preTestScorePercentage}`,
            finalTest: `${student.courseTestScorePercentage}`,
            id: `${student.studentId}`,
            firstName: `${student.studentFirstName}`,
            lastName: `${student.studentLastName}`,
            improvement: `${student.scoreChangePercentage}`,
            userName: `${student.studentUserName}`,
            ...Object.assign({}, ...modulesCompletionObj),
          };
        }),
      );
    }
  }, [studentsData]);

  useEffect(() => {
    if (Array.isArray(studentsData)) {
      const allModules: Set<string> = new Set();

      studentsData.forEach((student) => {
        student.modulesCompletion?.forEach((moduleData, moduleIndex) => {
          moduleData.lessonsCompletion?.forEach((lesson, lessonIndex) => {
            const moduleName = generateModuleLessonName(
              moduleData.moduleName,
              lesson.lessonName,
            );
            allModules.add(moduleName);
          });
        });
      });

      setNewLessons(Array.from(allModules));
    }
  }, [studentsData]);

  const handleEditStudent = (data: any) => {
    const { firstName, lastName, id, userName } = data;
    setOpen(true);
    setStudent({
      firstName: firstName,
      lastName: lastName,
      studentId: id,
      userName: userName,
    });
  };

  const columns: any = useMemo<MRT_ColumnDef<TableData[] | any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        enableColumnActions: false,
        muiTableBodyCellProps: {
          sx: { color: '#000', fontWeight: 600, fontSize: '16px' },
        },
        size: 120,
        Cell: ({ cell }: any) => {
          const name = cell?.row?.original?.firstName ?? '';
          return (
            <>
              <AccountCircleIcon
                sx={{
                  width: '35px',
                  height: '35px',
                  mr: '5px',
                  fill: '#746E6F',
                }}
              />
              {name}
            </>
          );
        },
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        enableColumnActions: false,
        muiTableBodyCellProps: {
          sx: { color: '#746E6F', fontWeight: 400, fontSize: '16px' },
        },
        size: 120,
      },
      {
        accessorKey: 'pretest',
        header: 'Pretest Score',
        enableColumnActions: false,
        Cell: ({ cell }: any) => {
          const status = cell?.row?.original?.pretest ?? '';
          return getRowStatus(status);
        },
        size: 150,
      },
      {
        accessorKey: 'finalTest',
        header: 'Course Test Score',
        enableColumnActions: false,
        Cell: ({ cell }: any) => {
          const status = cell?.row?.original?.finalTest ?? '';
          return getRowStatus(status);
        },
        size: 160,
      },
      {
        accessorKey: 'improvement',
        header: 'Score Change',
        enableColumnActions: false,
        Cell: ({ cell }: any) => {
          const improvementIncomplete =
            cell.row.original.pretest === '0' ||
            cell.row.original.finalTest === '0';
          const status = improvementIncomplete
            ? null
            : cell?.row?.original?.improvement ?? '';
          return getRowImprovement(status);
        },
      },
      ...newLessons.map((moduleName) => {
        return {
          accessorKey: moduleName,
          header: moduleName,
          enableColumnActions: false,
          size: 160,
          Cell: ({ cell }: any) => {
            const status = cell?.row?.original[moduleName] ?? 0;
            return getModuleStatus(status);
          },
        };
      }),
    ],
    [newLessons],
  );

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(tableData);
    download(csvConfig)(csv);
  };

  const confirmAction = () => {
    dispatch(
      removeStudentFromClassroom({
        teacherId: teacherData?.id || '',
        classroomId: classroomId,
        studentId: student.studentId,
        token: token,
      }),
    )
      .then(() => {
        handleCloseModal();
        window.location.href = `/educator/classroomDashboard/${teacherData?.id}/${classroomId}`;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveStudent = (student: any) => {
    const { firstName, lastName, id, userName } = student;
    setStudent({
      firstName: firstName,
      lastName: lastName,
      studentId: id,
      userName: userName,
    });
    setShowConfirmationDialog({
      isOpen: true,
      title: 'Remove From Class',
      text: `Are you sure you want to remove ${student.student} from ${classroomName}? Once this action is done, ${student.firstName} will need to enter a new class code to join a new class.`,
    });
  };

  const handleCloseModal = () => {
    setShowConfirmationDialog((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <StyledBoard data-testid="student-table-content">
      {studentsData && studentsData.length > 0 ? (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableStickyHeader
          enableRowActions
          layoutMode="grid"
          data-test="student-colum-content"
          muiTableBodyCellProps={{
            sx: {
              flex: '0 0 auto',
              [theme.breakpoints.up('sm')]: {
                minWidth: '150px',
              },
              [theme.breakpoints.up('md')]: {
                minWidth: '160px',
              },
              [theme.breakpoints.up('lg')]: {
                minWidth: '180px',
              },
              [theme.breakpoints.up('xl')]: {
                minWidth: '220px',
              },
              [theme.breakpoints.up('xxl')]: {
                minWidth: '220px',
              },
            },
          }}
          enablePinning
          initialState={{
            columnPinning: { left: ['firstName', 'lastName'] },
            sorting: [
              {
                id: 'firstName',
                desc: false,
              },
            ],
            density: 'compact',
          }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: '#F4F6F8',
              fontWeight: 500,
              color: '#637381',
              fontSize: '16px',
              flex: '0 0 auto',
              justifyContent: 'center',
              [theme.breakpoints.up('sm')]: {
                minWidth: '150px',
              },
              [theme.breakpoints.up('md')]: {
                minWidth: '160px',
              },
              [theme.breakpoints.up('lg')]: {
                minWidth: '180px',
              },
              [theme.breakpoints.up('xl')]: {
                minWidth: '220px',
              },
              [theme.breakpoints.up('xxl')]: {
                minWidth: '220px',
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => ({})}
          renderRowActions={({ row }) => (
            <Box>
              <IconButton onClick={() => handleRemoveStudent(row?.original)}>
                <PersonRemoveIcon />
              </IconButton>
              <IconButton onClick={() => handleEditStudent(row?.original)}>
                <EditIcon />
              </IconButton>
            </Box>
          )}
          positionToolbarAlertBanner="bottom"
          renderBottomToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
              >
                Export All Data
              </Button>
            </Box>
          )}
        />
      ) : (
        <NoStudent />
      )}
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        showCloseButton={false}
      >
        <EditStudent
          onClose={() => setOpen(false)}
          student={student}
          classroomId={classroomId}
          classroomName={classroomName}
        />
      </Modal>
      <Modal
        isOpen={showConfirmationDialog.isOpen}
        onClose={handleCloseModal}
        showCloseButton={false}
        maxWidth={400}
      >
        <Box
          sx={{
            borderRadius: '0.5rem',
            minHeight: '20vh',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ mt: 1.5, fontWeight: '600' }}
            gutterBottom
          >
            {showConfirmationDialog.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            {showConfirmationDialog.text}
          </Typography>
          <CancelSaveButtons
            onCancel={handleCloseModal}
            onSave={confirmAction}
            styles={{
              justifyContent: 'center',
              marginTop: '2rem',
              bottom: '20px',
            }}
          />
        </Box>
      </Modal>
    </StyledBoard>
  );
};
