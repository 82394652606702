export const routeIDs = {
  // Educator
  EDUCATOR_CREATE_CLASSROOM: '/educator/createClassroom',
  EDUCATOR_CLASSROOM_ACTIVITIES: '/educator/classroomActivities',
  EDUCATOR_CLASSROOM_INSTRUCTIONAL_MATERIALS:
    '/educator/instructionalMaterials',
  EDUCATOR_CLASSROOM_DASHBOARD: '/educator/classroomDashboard',
  EDUCATOR_VIDEO_LIBRARY: '/educator/videoLibrary',
  EDUCATOR_BOOK_STORE: '/educator/bookStore',
  EDUCATOR_TEST_ANSWER: '/educator/testAnswer',
  EDUCATOR_ADDITIONAL_RESOURCES: '/educator/additionalResources',
  EDUCATOR_HOME: '/educator/home',
  EDUCATOR_RESOURCES_LIST: '/educator/resourcesList',
  EDUCATOR_CLASSROOMS: '/educator/classrooms',
  EDUCATOR_LIST_CLASSROOMS: '/classrooms',
  EDUCATOR_REDIRECT: '/educator',
  EDUCATOR_REDIRECT_LOGIN: '/login?role=teacher',
  EDUCATOR_REDIRECT_CREATE_ACCOUNT: '/createAccount?role=teacher',
  EDUCATOR_COACHING_VIDEOS: '/educator/coachingVideos',
  EDUCATOR_PRE_BUILT_ASSESSMENTS: '/educator/preBuiltAssessments',
  EDUCATOR_WORKSHEETS: '/educator/worksheets',
  EDUCATOR_CAREER_VIDEOS: '/educator/careerVideos',
  EDUCATOR_PROJECTS: '/educator/projects',
  EDUCATOR_CHAT: '/educator/chat',
  EDUCATOR_PREMIUM_CHOOSEPLAN: '/educator/premium/choosePlan',
  EDUCATOR_PREMIUM_SUBSCRIPTION_SUCCESS: '/educator/premium/success',
  // Student
  STUDENT: '/student',
  STUDENT_OTP_CLASS_CODE: '/student/otpClassCode',
  STUDENT_RESET_PASSWORD: '/student/resetPassword',
  STUDENT_REDIRECT_LOGIN: '/login?role=student',
  STUDENT_REDIRECT_CREATE_ACCOUNT: '/createAccount?role=student',
  // Globals
  PRIVACY_POLICY: '/privacyPolicy',
  COOKIE_POLICY: '/cookiePolicy',
  TERM_AND_CONDITION: '/TermAndCondition',
  THANK_YOU_SCREEN: '/thankYouScreen',
  FORGOT_PASSWORD: '/forgotPassword',
  ROLE_SELECTION_SCREEN: '/',
  LOGIN: '/login',
  CREATE_ACCOUNT: '/createAccount',
  // Guest
  GUEST_SELECTION_COURSES: '/guestSelectionCourses',
  // Page not Found
  PAGE_NOT_FOUND: '/page-not-found',
};
