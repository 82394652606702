import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CircularProgress } from '@mui/material';

export const renderStartIcon = (
  isLoadingLocked: boolean,
  isLocked: boolean,
) => {
  if (isLoadingLocked) {
    return <CircularProgress size={24} sx={{ color: '#003366' }} />;
  } else if (!isLocked) {
    return <LockOpenIcon sx={{ color: '#003366' }} />;
  } else {
    return <LockOutlinedIcon sx={{ color: '#003366' }} />;
  }
};
