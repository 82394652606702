import React from 'react';
import { Box, Typography } from '@mui/material';
import LemmyInjured from '../../Assets/Lemmy_Injured.png';
import { IErrorPageProps } from './types';

export const ErrorPage = ({ status, title, description }: IErrorPageProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box position={'relative'} m=" 0 auto 0 auto">
        <Box
          component={'img'}
          src={LemmyInjured}
          sx={{
            position: 'absolute',
            width: '200px',
            bottom: '-40px',
            left: '-100px',
          }}
        />
      </Box>
      <Typography variant="h1">{status}</Typography>
      <Typography variant="h4" textAlign={'center'}>
        {title}
      </Typography>
      <Typography variant="h6" textAlign={'center'}>
        {description}
      </Typography>
    </Box>
  );
};
