export const initialValues = (licenseKeyCode: string) => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    stateID: '',
    gradeID: '',
    organizationName: '',
    schoolName: '',
    referralCode: '',
    districtID: '',
    licenseKey: licenseKeyCode || '',
  };
};
