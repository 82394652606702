import React from 'react';
import Box from '@mui/material/Box';
import { IPageContainerProps } from './types';

export const PageContainer = ({ children, styles }: IPageContainerProps) => {
  return (
    <Box
      data-testid="content-questions"
      sx={{
        minHeight: '50vh',
        margin: 'auto',
        maxWidth: '1600px',
        ...styles,
      }}
    >
      {children}
    </Box>
  );
};
