import { AnswerPlace } from '../../../redux/types/lessonsTypes';

export const answerOptionMultivalued = (
  answerPlace: AnswerPlace,
  multiValue: string[],
) => {
  const op1 = answerPlace?.answerOptions?.find(
    (op) => op.answerId === multiValue[0],
  );
  const op2 = answerPlace?.answerOptions?.find(
    (op) => op.answerId === multiValue[1],
  );
  return [
    {
      answerId: op1?.answerId as string,
      openEntryAnswerText: '',
    },
    {
      answerId: op2?.answerId as string,
      openEntryAnswerText: '',
    },
  ];
};
