import { Question } from '../../../redux/types/quizTypes';

export const handleSaveDataSentence = (
  currentQuestion: Question,
  multiValue: string[],
) => {
  const orderAnswerPlaces = currentQuestion.answerPlaces[0].sortOrder || 0;
  let newMultiValue;
  if (orderAnswerPlaces === 1) {
    newMultiValue = multiValue.reverse();
  } else {
    newMultiValue = multiValue;
  }
  let dataAnswer;
  if (multiValue?.length > 1) {
    dataAnswer = {
      answerPlaceAnswers: [
        {
          answerPlaceId: currentQuestion.answerPlaces[0]?.answerPlaceId,
          studentAnswers: [
            {
              answerId: newMultiValue[0],
              openEntryAnswerText: null,
            },
          ],
        },
        {
          answerPlaceId: currentQuestion.answerPlaces[1]?.answerPlaceId,
          studentAnswers: [
            {
              answerId: newMultiValue[1],
              openEntryAnswerText: null,
            },
          ],
        },
      ],
    };
  } else {
    dataAnswer = {
      answerPlaceAnswers: [
        {
          answerPlaceId: currentQuestion.answerPlaces[0]?.answerPlaceId,
          studentAnswers: [
            {
              answerId: multiValue[0],
              openEntryAnswerText: null,
            },
          ],
        },
      ],
    };
  }

  return dataAnswer;
};
