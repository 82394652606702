import { Box, Typography } from '@mui/material';
import React from 'react';
import { ITileCardProps } from '../../types';
import { TileCard } from '../TileCard/TileCard';

export const TileCardContent = ({ courses, title }: ITileCardProps) => {
  const sortedCourses = courses?.sort(
    (a, b) => a.tileUISortOrder - b.tileUISortOrder,
  );
  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: '20px', ml: '10px', fontWeight: 600, fontSize: '1.25rem' }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: { xs: 'center', sm: 'flex-start' },
        }}
      >
        {sortedCourses?.map((curriculum: any) => (
          <Box key={curriculum.id} sx={{ mb: 2 }}>
            <TileCard
              label={curriculum.name}
              url={`/educator/resourcesList/${curriculum.id}`}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 260,
                maxHeight: 180,
                fontWeight: 700,
                marginLeft: '20px',
                p: 1,
                transition: 'box-shadow 0.3s ease-in-out',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 4px rgba(0, 51, 102, 0.1)',
                background: 'linear-gradient(180deg, #fff 0%, #f9f9f9 100%)',
                '&:hover': {
                  boxShadow: '0px 0px 10px 0px #003366',
                },
              }}
              labelStyles={{ fontWeight: 700, fontSize: '16px' }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
