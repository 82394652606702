import React from 'react';
import { useSelector } from 'react-redux';
import { getAPreBuiltAssessments } from '../../../redux/slices/resourcesSlices';
import { RootState } from '../../../redux/store';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const PrebuiltAssessments = () => {
  const { preBuiltAssessments } = useSelector(
    (state: RootState) => state.resources,
  );

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={preBuiltAssessments}
      curriculumId={curriculumId}
      getResourceAction={getAPreBuiltAssessments}
      resourceTypeLabel={'preBuiltAssessments'}
      label="Pre-Built Assessment"
    />
  );
};
