import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../../LibraryComponents/PageContainer';
import { SingleQuestion } from '../../../Quiz/components/SingleQuestions/SingleQuestions';
import { ArrowBack } from '@mui/icons-material';
import { Spinner } from '../../../../LibraryComponents/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { saveLocalLessonProgressStudent } from '../../../../redux/slices/studentSlice';
import { updateOrInsertLesson } from '../../StudentHelpers';
import { MultiSelect } from '../../../Quiz/components/MultiSelect/MultiSelect';
import { OpenEntry } from '../../../Quiz/components/OpenEntry/OpenEntry';
import { SentenceCompletion } from '../../../Quiz/components/SentenceCompletion/SentenceCompletion';
import { IAnswerData } from '../../../../redux/types/quizTypes';
import {
  saveAnswerQuestionLesson,
  saveAnswersLocallyLesson,
} from '../../../../redux/slices/lessonsSlice';
import { ILessonProgressProps } from './types';
import {
  findAnswerOption,
  handleQuestionType,
  handleSaveDataMultiOpen,
  handleSaveDataSentence,
  hasHTMLTags,
  sanitizeHTML,
} from './LessonProgressHelpers';

export const LessonProgressComponent = ({
  lessonProgress,
  indexLesson,
  multiValue,
  submitting,
  handlePrev,
  setSubmitting,
  handleNext,
  setMultiValue,
  lessonName,
  totalLessons = 0,
  setIndexLesson,
  lessonProgressId,
  progressIndexLesson,
  handleChanges,
  openEntryValue,
  setOpenEntryValue,
}: ILessonProgressProps) => {
  const { progress } = useSelector((state: RootState) => state.student);
  const { answerLocalLesson } = useSelector((state: RootState) => state.lesson);
  const { userId: studentId, token } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useDispatch<AppDispatch>();

  const lessonId = lessonProgressId;
  const lessonProgressLocal = progress.find(
    (item) => item.lessonId === lessonId,
  );

  const [isCompleted, setIsCompleted] = useState(false);
  const [answerText, setAnswerText] = useState('');

  useEffect(() => {
    if (lessonProgressLocal) {
      const newIndexLesson = lessonProgressLocal.completed
        ? 0
        : progressIndexLesson || 0;
      setIndexLesson(newIndexLesson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonProgressId]);

  useEffect(() => {
    if (!lessonProgressLocal?.completed) {
      const data = updateOrInsertLesson(
        progress,
        lessonId,
        progressIndexLesson,
        indexLesson,
        totalLessons,
        false,
      );
      dispatch(
        saveLocalLessonProgressStudent({
          data,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonProgressId]);

  const handleSaveLessonProgress = () => {
    if (
      lessonProgressLocal &&
      lessonProgressLocal?.counterLesson > indexLesson
    ) {
      handleNext();
      return;
    }
    if (
      !lessonProgressLocal?.completed &&
      lessonProgressLocal?.counterLesson !== lessonProgressLocal?.totalLessons
    ) {
      const data = updateOrInsertLesson(
        progress,
        lessonId,
        progressIndexLesson,
        indexLesson,
        totalLessons,
        true,
      );
      dispatch(
        saveLocalLessonProgressStudent({
          data,
        }),
      );
    }
    handleNext();
  };

  const noEmptyOrUndefinedValues = multiValue?.every(
    (item) => item !== '' && item !== undefined,
  );

  const question = lessonProgress[indexLesson]?.question;

  const questionType = handleQuestionType(question?.questionTypeId);
  const answerOption = question
    ? findAnswerOption(
        questionType,
        openEntryValue,
        multiValue,
        question.answerPlaces[0],
      )
    : { answerId: 0 };

  useEffect(() => {
    const studentAnswers = question?.answerPlaces[0]?.studentAnswers;
    if (studentAnswers?.length > 0) {
      if (studentAnswers.length === 1) {
        setMultiValue([studentAnswers[0]?.answerId]);
        setAnswerText(studentAnswers[0]?.openEntryAnswerText);
      } else {
        setMultiValue([
          studentAnswers[0]?.answerId,
          studentAnswers[1]?.answerId,
        ]);
      }
      setIsCompleted(true);
      setSubmitting(true);
    }
    if (studentAnswers?.length === 0) {
      setIsCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleSubmitButtonDisabled = () => {
    const currentQuestionType = questionType;
    if (
      currentQuestionType === 'multi' &&
      multiValue?.length < 2 &&
      noEmptyOrUndefinedValues
    ) {
      return true;
    }
    if (multiValue.length === 0) {
      return true;
    }
  };

  const handleSaveAnswerLessonQuestions = async () => {
    const currentQuestion = question.answerPlaces[0];
    const questionId = question.questionId;
    const saveAnswer = async (data: IAnswerData) => {
      try {
        await dispatch(
          saveAnswerQuestionLesson({
            studentId,
            questionId,
            lessonId,
            token,
            data,
          }),
        );
        await dispatch(saveAnswersLocallyLesson({ data }));
      } catch (err) {
        console.error('Error saving the quiz answer:', err);
      }
    };

    if (questionType === 'sentence') {
      const dataAnswer: any = handleSaveDataSentence(
        currentQuestion,
        multiValue,
      );

      await saveAnswer(dataAnswer);
    } else {
      const data: IAnswerData = handleSaveDataMultiOpen({
        answerPlace: question.answerPlaces,
        answerOption: answerOption?.answerId as string,
        questionType,
        multiValue,
      });
      saveAnswer(data);
    }
    setSubmitting(true);
  };

  useEffect(() => {
    const answerPlaceId = question?.answerPlaces?.[0]?.answerPlaceId;

    const findAnswer = () => {
      if (answerLocalLesson.length > 0) {
        return answerLocalLesson.find((item) => {
          return item.answerPlaceAnswers.some(
            (answer: any) => answer.answerPlaceId === answerPlaceId,
          );
        });
      }
      return null;
    };

    const filteredObject = findAnswer();

    if (filteredObject) {
      const firstStudentAnswerId =
        filteredObject.answerPlaceAnswers[0]?.studentAnswers[0]?.answerId;
      const textValue =
        filteredObject.answerPlaceAnswers[0]?.studentAnswers[0]
          ?.openEntryAnswerText || '';
      if (firstStudentAnswerId) {
        console.log(textValue);

        setMultiValue([firstStudentAnswerId]);
        setAnswerText(textValue);
        setIsCompleted(true);
        setSubmitting(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexLesson]);

  return (
    <Box
      sx={{
        background: '#003366',
        height: { xs: '85vh', sm: '100%' },
        paddingTop: '0.1px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: '40px',
        }}
      >
        <Typography sx={{ fontSize: '32px', color: '#fff', fontWeight: 700 }}>
          {lessonName}
        </Typography>
      </Box>
      {!lessonProgress && <Spinner />}
      <PageContainer
        styles={{
          background: '#fff',
          width: {
            xs: '90%',
            md: '642px',
          },
          padding: '20px',
          height: 'auto',
          position: 'relative',
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: { xs: '32px', sm: '0' },
          m: { xs: '0 10px', sm: '0' },
          borderRadius: '10px',
        }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          style={{
            minHeight: 'inherit',
          }}
        >
          <Grid
            item
            xs
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            {lessonProgress[indexLesson]?.contentText &&
            hasHTMLTags(lessonProgress[indexLesson]?.contentText) ? (
              <Box
                sx={{ img: { width: '100%' } }}
                dangerouslySetInnerHTML={sanitizeHTML(
                  lessonProgress[indexLesson]?.contentText ?? '',
                )}
              />
            ) : (
              <Typography
                variant="h5"
                color={'#003366'}
                sx={{ marginBottom: '30px', textAlign: 'center' }}
              >
                {lessonProgress[indexLesson]?.contentText
                  ? lessonProgress[indexLesson]?.contentText
                  : ''}
              </Typography>
            )}
            {lessonProgress[indexLesson]?.question && (
              <Box sx={{ margin: { xs: '0', sm: '20px 0 20px 40px' } }}>
                {(() => {
                  switch (questionType) {
                    case 'multi':
                      return (
                        <MultiSelect
                          key={question?.questionId}
                          questionText={
                            lessonProgress
                              ? lessonProgress[indexLesson]?.question
                                  ?.questionText
                              : ''
                          }
                          questionExplanation={question?.questionExplanation}
                          answerOptions={
                            question?.answerPlaces[0]?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                    case 'single':
                      return (
                        <SingleQuestion
                          key={question?.questionId}
                          questionText={
                            lessonProgress
                              ? lessonProgress[indexLesson]?.question
                                  ?.questionText
                              : ''
                          }
                          questionExplanation={question?.questionExplanation}
                          answerOptions={
                            question?.answerPlaces[0]?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                    case 'open':
                      return (
                        <OpenEntry
                          key={question?.questionId}
                          questionText={
                            lessonProgress
                              ? lessonProgress[indexLesson]?.question
                                  ?.questionText
                              : ''
                          }
                          questionExplanation={question?.questionExplanation}
                          answerOptions={
                            question?.answerPlaces[0]?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                          handleChange={handleChanges}
                          setOpenEntryValue={setOpenEntryValue}
                          answerText={answerText}
                        />
                      );
                    default:
                      return (
                        <SentenceCompletion
                          key={question?.questionId}
                          questionText={
                            lessonProgress
                              ? lessonProgress[indexLesson]?.question
                                  ?.questionText
                              : ''
                          }
                          questionExplanation={question?.questionExplanation}
                          answerOptions={
                            question?.answerPlaces[0]?.answerOptions
                          }
                          answer={[]}
                          answerPlaces={question.answerPlaces}
                          multiValue={multiValue}
                          submitting={submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                  }
                })()}
              </Box>
            )}
          </Grid>
          <Grid item width={'100%'}>
            <Box
              sx={{
                width: { xs: '100%' },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: '#000',
                  minWidth: '120px',
                }}
                onClick={handlePrev}
                startIcon={<ArrowBack />}
                disabled={indexLesson === 0}
              >
                Prev
              </Button>
              <Typography sx={{ color: '#9C27B0' }}>
                {indexLesson + 1} of {totalLessons + 1}
              </Typography>
              <Box>
                {lessonProgress[indexLesson]?.question && !submitting ? (
                  <Box>
                    <Button
                      variant="contained"
                      sx={{ background: '#003366' }}
                      onClick={handleSaveAnswerLessonQuestions}
                      disabled={handleSubmitButtonDisabled()}
                    >
                      Submit answer
                    </Button>
                  </Box>
                ) : (
                  <Button
                    data-testid="lesson-button-next"
                    onClick={handleSaveLessonProgress}
                    variant="contained"
                    sx={{ background: '#FA4729', minWidth: '120px' }}
                    disabled={
                      !!lessonProgress[indexLesson]?.question && !submitting
                    }
                  >
                    next
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </Box>
  );
};
