import { Box } from '@mui/material';
import { getHarveyBalls } from './getHarveyBalls';

export const getModuleStatus = (status: number): JSX.Element => {
  if (status === 0) {
    return (
      <Box
        sx={{
          color: '#696969',
          fontWeight: 400,
          fontSize: '16px',
        }}
      >
        Not Started
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          width: '40px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {getHarveyBalls(status)}
      </Box>
    );
  }
};
