import { QuestionType } from '../../../constants/QuestionType';
import { Question } from '../../../redux/types/lessonsTypes';

export const handleQuestionType = (
  questionsData: Question[],
  indexLesson: number,
) => {
  const questionTypeId = questionsData[indexLesson]?.questionTypeId;
  switch (questionTypeId) {
    case QuestionType.MultiSelect:
      return 'multi';
    case QuestionType.SingleSelect:
      return 'single';
    case QuestionType.OpenEntry:
      return 'open';
    case QuestionType.SentenceCompletion:
      return 'sentence';
    default:
      return null;
  }
};
