import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getChildrenBooks } from '../../../redux/slices/resourcesSlices';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const ChildrenBooks = () => {
  const { childrenBooks } = useSelector((state: RootState) => state.resources);

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={childrenBooks}
      curriculumId={curriculumId}
      getResourceAction={getChildrenBooks}
      resourceTypeLabel={'bookStore'}
      label="Lemmy’s Bookstore"
    />
  );
};
