import { Box } from '@mui/material';

export const getRowStatus = (status: string): JSX.Element => (
  <Box
    sx={{
      color: '#696969',
      fontWeight: 400,
      fontSize: '16px',
    }}
  >
    {status && status !== '0' ? `${status}%` : 'No Score'}
  </Box>
);
