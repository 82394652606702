import DOMPurify from 'dompurify';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { termAndConditions } from '../../termsAndConditions';
import { useGTM } from '../../hooks/useGTM';

export const TermAndCondition = () => {
  const { pageView } = useGTM();
  useEffect(() => {
    pageView('/TermAndCondition');
  }, []);
  return (
    <Box
      sx={{ padding: '20px', marginLeft: '20px', marginRight: '20px' }}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(termAndConditions),
      }}
    />
  );
};
