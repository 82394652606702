import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IAnswerData, QuizState } from '../types/quizTypes';

const initialQuiz = {
  studentId: '',
  studentFirstName: '',
  studentLastName: '',
  userName: '',
  quizId: '',
  quizName: '',
  questions: [],
};
const initialState: QuizState = {
  status: 'idle',
  preQuizStatus: 'idle',
  postQuizStatus: 'idle',
  preQuizDefinition: initialQuiz,
  postQuizDefinition: initialQuiz,
  answerLocal: [],
  answerLocalPreGuest: {
    correctAnswers: 0,
    answerLocal: [],
  },
  answerLocalPostGuest: {
    correctAnswers: 0,
    answerLocal: [],
  },
  statusQuizResponse: null,
};

const API_URL = process.env.REACT_APP_API_URL;

export const getPreQuizDefinition = createAsyncThunk(
  'quiz/getPreQuizDefinition',
  async (
    {
      studentId,
      token,
    }: {
      studentId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/students/${studentId}/preQuiz`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const saveAnswerPostQuiz = createAsyncThunk(
  'quiz/saveAnswerPostQuiz',
  async (
    {
      studentId,
      questionId,
      token,
      data,
    }: {
      studentId: string | null;
      questionId: string;
      token: string | null;
      data: IAnswerData;
    },
    { rejectWithValue },
  ) => {
    const payload = data;
    const response = await fetch(
      `${API_URL}/students/${studentId}/postQuiz/questions/${questionId}/answers`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const saveAnswerPreQuiz = createAsyncThunk(
  'quiz/saveAnswerPreQuiz',
  async (
    {
      studentId,
      questionId,
      token,
      data,
    }: {
      studentId: string | null;
      questionId: string;
      token: string | null;
      data: IAnswerData;
    },
    { rejectWithValue },
  ) => {
    const payload = data;
    const response = await fetch(
      `${API_URL}/students/${studentId}/preQuiz/questions/${questionId}/answers`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);
export const getPostQuizDefinition = createAsyncThunk(
  'quiz/getPostQuizDefinition',
  async (
    {
      studentId,
      token,
    }: {
      studentId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/students/${studentId}/postQuiz`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const savesAnswerQuestion = createAsyncThunk(
  'quiz/savesAnswerQuestion',
  async (
    {
      studentId,
      lessonID,
      questionId,
      token,
      data,
    }: {
      studentId: string | null;
      token: string | null;
      lessonID: string | null;
      questionId: string | null;
      data: string | null;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      data,
    };
    const response = await fetch(
      `${API_URL}/students/${studentId}/lessons/${lessonID}/questions/${questionId}/answers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const saveAnswersLocallyQuiz = createAsyncThunk(
  'quiz/saveAnswersLocallyQuiz',
  ({ data }: { data: any }) => {
    return data;
  },
);

export const saveAnswersLocallyPreQuizGuest = createAsyncThunk(
  'quiz/saveAnswersLocallyPreQuizGuest',
  ({ data, counter }: { data: any; counter: number }) => {
    return { data, counter };
  },
);

export const saveAnswersLocallyPostQuizGuest = createAsyncThunk(
  'quiz/saveAnswersLocallyPostQuizGuest',
  ({ data, counter }: { data: any; counter: number }) => {
    return { data, counter };
  },
);

export const resetAnswersLocallyQuiz = createAsyncThunk(
  'quiz/resetAnswersLocallyQuiz',
  () => {
    return [];
  },
);
export const resetAnswersLocallyGuest = createAsyncThunk(
  'quiz/resetAnswersLocallyGuest',
  () => {
    return [];
  },
);
export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPreQuizDefinition.pending, (state) => {
        state.status = 'loading';
        state.preQuizStatus = 'loading';
      })
      .addCase(getPreQuizDefinition.fulfilled, (state, action) => {
        state.status = 'idle';
        state.preQuizStatus = 'idle';
        state.preQuizDefinition = action.payload;
        state.statusQuizResponse = null;
      })
      .addCase(getPreQuizDefinition.rejected, (state, action) => {
        state.status = 'failed';
        state.preQuizStatus = 'failed';
        state.preQuizDefinition = initialQuiz;
        state.statusQuizResponse = action.payload;
      })
      .addCase(saveAnswerPreQuiz.pending, (state) => {
        state.status = 'loading';
        state.preQuizStatus = 'loading';
      })
      .addCase(saveAnswerPreQuiz.fulfilled, (state, action) => {
        state.status = 'idle';
        state.preQuizStatus = 'idle';
        state.statusQuizResponse = null;
      })
      .addCase(saveAnswerPreQuiz.rejected, (state, action) => {
        state.status = 'failed';
        state.preQuizStatus = 'failed';
        state.statusQuizResponse = action.payload;
      })
      .addCase(savesAnswerQuestion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(savesAnswerQuestion.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusQuizResponse = null;
      })
      .addCase(savesAnswerQuestion.rejected, (state, action) => {
        state.status = 'failed';
        state.statusQuizResponse = action.payload;
      })
      .addCase(saveAnswerPostQuiz.pending, (state) => {
        state.status = 'loading';
        state.postQuizStatus = 'loading';
      })
      .addCase(saveAnswerPostQuiz.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusQuizResponse = null;
      })
      .addCase(saveAnswerPostQuiz.rejected, (state, action) => {
        state.status = 'failed';
        state.postQuizStatus = 'failed';
        state.statusQuizResponse = action.payload;
      })
      .addCase(getPostQuizDefinition.pending, (state) => {
        state.status = 'loading';
        state.postQuizStatus = 'loading';
      })
      .addCase(getPostQuizDefinition.fulfilled, (state, action) => {
        state.status = 'idle';
        state.postQuizStatus = 'idle';
        state.postQuizDefinition = action.payload;
        state.statusQuizResponse = null;
      })
      .addCase(getPostQuizDefinition.rejected, (state, action) => {
        state.status = 'failed';
        state.postQuizStatus = 'failed';
        state.postQuizDefinition = initialQuiz;
        state.statusQuizResponse = action.payload;
      })
      .addCase(saveAnswersLocallyQuiz.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveAnswersLocallyQuiz.fulfilled, (state, action) => {
        state.status = 'idle';
        state.answerLocal.push(action.payload);
      })
      .addCase(saveAnswersLocallyQuiz.rejected, (state) => {
        state.status = 'failed';
        state.answerLocal = [];
      })
      .addCase(resetAnswersLocallyQuiz.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetAnswersLocallyQuiz.fulfilled, (state, action) => {
        state.status = 'idle';
        state.answerLocal = action.payload;
      })
      .addCase(resetAnswersLocallyQuiz.rejected, (state) => {
        state.status = 'failed';
        state.answerLocal = [];
      })
      .addCase(saveAnswersLocallyPreQuizGuest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveAnswersLocallyPreQuizGuest.fulfilled, (state, action) => {
        state.status = 'idle';
        state.answerLocalPreGuest.answerLocal.push(action.payload.data);
        state.answerLocalPreGuest.correctAnswers += action.payload.counter;
      })
      .addCase(saveAnswersLocallyPreQuizGuest.rejected, (state) => {
        state.status = 'failed';
        state.answerLocalPreGuest.answerLocal = [];
        state.answerLocalPreGuest.correctAnswers = 0;
      })
      .addCase(saveAnswersLocallyPostQuizGuest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveAnswersLocallyPostQuizGuest.fulfilled, (state, action) => {
        state.status = 'idle';
        state.answerLocalPostGuest.answerLocal.push(action.payload.data);
        state.answerLocalPostGuest.correctAnswers += action.payload.counter;
      })
      .addCase(saveAnswersLocallyPostQuizGuest.rejected, (state) => {
        state.status = 'failed';
        state.answerLocalPostGuest.answerLocal = [];
        state.answerLocalPostGuest.correctAnswers = 0;
      })
      .addCase(resetAnswersLocallyGuest.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetAnswersLocallyGuest.fulfilled, (state, action) => {
        state.status = 'idle';
        state.answerLocalPostGuest.answerLocal = [];
        state.answerLocalPostGuest.correctAnswers = 0;
        state.answerLocalPreGuest.answerLocal = [];
        state.answerLocalPreGuest.correctAnswers = 0;
      })
      .addCase(resetAnswersLocallyGuest.rejected, (state) => {
        state.status = 'failed';
        state.answerLocalPostGuest.answerLocal = [];
        state.answerLocalPostGuest.correctAnswers = 0;
        state.answerLocalPreGuest.answerLocal = [];
        state.answerLocalPreGuest.correctAnswers = 0;
      });
  },
});

export default quizSlice.reducer;
