import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { TileCard } from '../TileCard';

export interface IResourcesListContentProps {
  label: string;
  icon: ReactNode;
  url: string;
  isLink: boolean;
  target: string;
  order?: number;
  onClick?: () => void;
  isPremium?: boolean;
  tileToolTipsMsg?: string;
  subText?: string;
}

export const ResourcesListContent = ({
  label,
  url,
  icon,
  isLink,
  target,
  order,
  onClick,
  isPremium,
  tileToolTipsMsg,
  subText,
}: IResourcesListContentProps) => {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={2}
      marginBottom={2}
      order={order}
      position={'relative'}
    >
      <TileCard
        isLink={isLink}
        label={label}
        url={url}
        target={target}
        isPremium={isPremium}
        tileToolTipsMsg={tileToolTipsMsg}
        subText={subText}
        onClick={onClick}
        styles={{
          border: '1px solid #e0e0e0',
          background: 'linear-gradient(180deg, #fff 0%, #f9f9f9 100%)',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0px 0px 10px 0px #003366',
          },
        }}
      >
        {icon}
      </TileCard>
    </Grid>
  );
};
