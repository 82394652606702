import { Question } from '../../../redux/types/quizTypes';

export const answerCompleted = (
  orderQuestions: Question[],
  indexLesson: number,
) => {
  const sortOrdersWithStudentAnswers = orderQuestions
    .filter((question) =>
      question.answerPlaces.some(
        (answerPlace) => answerPlace.studentAnswers?.length > 0,
      ),
    )
    .map((question) => question.sortOrder);
  const isAnswerCompleted =
    sortOrdersWithStudentAnswers.length === indexLesson + 1;
  return isAnswerCompleted;
};
