import React from 'react';
import { useFormik } from 'formik';
import { Button, FormControl, Typography, Box, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import lemmyLogo from '../../Assets/Lemmy_Base.png';
import { FormsControl } from '../../GlobalComponents/Form/FormControl';
import { RootState, useAppDispatch } from '../../redux/store';
import { changePasswordStudent } from '../../redux/slices/studentSlice';
import _ from 'lodash';
import { changePasswordTeacher } from '../../redux/slices/teacherSlice';
import { Toast } from '../Toast';
import { changePasswordSchema } from './schema';
import { IChangePasswordProps } from './types';
import SaveIcon from '@mui/icons-material/Save';

export const ChangePassword = ({
  handleClose,
  closeToast,
  setOpenToast,
  openToast,
}: IChangePasswordProps) => {
  const { userId, token, role, accessToken } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useAppDispatch();

  const handleChangePassword = (userType: string) =>
    _.debounce((values) => {
      const { newPassword, currentPassword } = values;
      const currentToken = userType === 'teacher' ? accessToken : token;
      const changePasswordAction: any =
        userType === 'teacher' ? changePasswordTeacher : changePasswordStudent;
      dispatch(
        changePasswordAction({
          oldPassword: currentPassword,
          newPassword,
          [`${userType}Id`]: userId,
          token: currentToken,
        }),
      )
        .then(() => {
          setOpenToast({
            open: true,
            msg: 'Password successfully changed',
            severity: 'success',
          });
          handleClose();
        })
        .catch((error: any) => {
          setOpenToast({
            open: true,
            msg: ' Error changing password',
            severity: 'error',
          });
          console.error(error);
        });
    }, 1000);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      const userType = role === 'teacher' ? 'teacher' : 'student';
      const handleChangePasswordForUser = handleChangePassword(userType);
      handleChangePasswordForUser(values);
      formik.resetForm();
    },
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#003366',
          position: 'relative',
          minHeight: '140px',
          borderRadius: '10px 10px 0 0 ',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1.5,
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
          }}
          gutterBottom
        >
          Change Password
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '90px',
          }}
        >
          <Avatar sx={{ width: '80px', height: 'min-content' }}>
            <img
              src={lemmyLogo}
              alt="educator-logo"
              width={'60px'}
              height={'100%'}
            />
          </Avatar>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Toast
          isOpen={openToast.open}
          severity={openToast.severity}
          onClose={() => closeToast(true)}
          styles={{
            width: { xs: '86%', sm: '100%' },
            textAlign: 'center',
            top: 40,
          }}
          errorMessage={openToast.msg}
        />
      </Box>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '300px', md: '500px' },
          }}
        >
          <FormsControl
            control="input"
            type="password"
            name="currentPassword"
            label="Current Password"
            formik={formik}
          />
          <FormsControl
            control="input"
            type="password"
            name="newPassword"
            label="New Password"
            formik={formik}
          />
          <FormsControl
            control="input"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            formik={formik}
          />
          <FormControl
            fullWidth
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
            variant="outlined"
          >
            <Button
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                mr: '20px',
                fontSize: '16px',
                borderColor: '#003366',
                color: '#003366',
                fontWeight: 700,
              }}
              onClick={handleClose}
              data-testid="cancel-change-password"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              data-testid="save-change-password"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: '16px',
                minWidth: '97px',
                background: '#003366',
                fontWeight: 700,
              }}
              endIcon={<SaveIcon />}
            >
              Save
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
