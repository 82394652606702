import React from 'react';
import { Input } from './Input/Input';
import { FormControl } from '@mui/material';
import { SelectForm } from './Select/Select';

export const FormsControl = (props: any) => {
  const { control, ...rest } = props;

  switch (control) {
    case 'input':
      return (
        <FormControl fullWidth sx={{ mb: 1, mt: 1 }} variant="outlined">
          <Input {...rest} />
        </FormControl>
      );
    case 'select':
      return (
        <FormControl fullWidth sx={{ mb: 1, mt: 1 }} variant="outlined">
          <SelectForm {...rest} />
        </FormControl>
      );
    default:
      return null;
  }
};
