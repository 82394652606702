import { Box, Typography } from '@mui/material';
import React from 'react';
import { INewChatProps } from '../../types';
import teacherLemmy from '../../../../Assets/TeacherLemmy.png';
import { getDateFormat } from '../../ChatUIHelpers/GetDateFormat';

export const NewChat = ({
  currentDate,
  messages,
  isColumnReversed,
}: INewChatProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isColumnReversed ? 'column' : 'column-reverse',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          marginBottom: '10px',
          width: '100%',
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              textAlign: message?.isUser ? 'right' : 'left',
              margin: '0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: message.isUser ? 'end' : 'start',
                flexDirection: 'column',
              }}
            >
              <Typography variant="body2" sx={{ color: 'gray', ml: 6 }}>
                {getDateFormat(new Date(message.dateTime))}
              </Typography>{' '}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: message.isUser ? 'end' : 'start',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {!message.isUser && (
                  <Box
                    component={'img'}
                    src={teacherLemmy}
                    alt="teacher lemmy"
                    width={50}
                    height={'100%'}
                  />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: '#e0e0e0',
                    padding: '10px',
                    borderRadius: '8px',
                    width: 'auto',
                    maxWidth: { xs: '100%', sm: '50%' },
                  }}
                >
                  {message.message}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        {new Date(currentDate).toLocaleDateString()}
      </Typography>
    </Box>
  );
};
