import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IClassProgressData, ILessonProgressData } from '../types/lessonsTypes';
import { IAnswerData } from '../types/quizTypes';

interface ILessonsState {
  classProgress: IClassProgressData;
  lessonProgress: ILessonProgressData;
  status: 'idle' | 'loading' | 'failed';
  statusLessonResponse: number | null | unknown;
  answerLocalLesson: any[];
}

const initialState: ILessonsState = {
  status: 'idle',
  classProgress: {
    classroomId: '',
    classroomName: '',
    classroomCode: '',
    curriculumId: '',
    curriculumName: '',
    studentId: '',
    studentFirstName: '',
    studentLastName: '',
    studentUserName: '',
    preQuiz: {
      quizId: '',
      quizName: '',
      completionPercentage: 0,
    },
    postQuiz: {
      quizId: '',
      quizName: '',
      completionPercentage: 0,
    },
    modulesCompletion: [],
  },
  lessonProgress: {
    studentId: '',
    studentFirstName: '',
    studentLastName: '',
    lessonId: '',
    lessonName: '',
    lessonDescription: '',
    lessonProgress: 0,
    lessonContents: [],
    progressContentId: '',
  },
  statusLessonResponse: null,
  answerLocalLesson: [],
};

const API_URL = process.env.REACT_APP_API_URL;

export const getClassProgress = createAsyncThunk(
  'lessons/getClassProgress',
  async (
    {
      studentId,
      token,
    }: {
      studentId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await fetch(
        `${API_URL}/students/${studentId}/classProgress`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (!response.ok) {
        return rejectWithValue(response.status);
      }
      return await response.json();
    } catch (error: any) {
      if (error?.status === 401) {
        const cookies = document.cookie.split(';');
        cookies.forEach((cookie) => {
          const cookieParts = cookie.split('=');
          const cookieName = cookieParts[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
      } else {
        console.error(error);
      }
    }
  },
);

export const getLessonProgress = createAsyncThunk(
  'lessons/getLessonProgress',
  async (
    {
      studentId,
      token,
      lessonId,
    }: {
      studentId: string | null;
      token: string | null;
      lessonId: string;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/students/${studentId}/lessons/${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const savesLessonProgress = createAsyncThunk(
  'lessons/savesLessonProgress',
  async (
    {
      studentId,
      token,
      lessonId,
      contentId,
    }: {
      studentId: string | null;
      token: string | null;
      lessonId: string;
      contentId: string;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/students/${studentId}/lessons/${lessonId}/progress`,
      {
        method: 'POST',
        body: JSON.stringify({ contentId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const resetLesson = createAsyncThunk('lessons/resetLesson', async () => {
  return 'reset Lesson';
});

export const saveAnswerQuestionLesson = createAsyncThunk(
  'quiz/saveAnswerQuestionLesson',
  async (
    {
      studentId,
      lessonId,
      questionId,
      token,
      data,
    }: {
      studentId: string | null;
      questionId: string;
      token: string | null;
      lessonId: string;
      data: IAnswerData;
    },
    { rejectWithValue },
  ) => {
    const payload = data;
    const response = await fetch(
      `${API_URL}/students/${studentId}/lessons/${lessonId}/questions/${questionId}/answers`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      return rejectWithValue(response.status);
    }
    return await response.json();
  },
);

export const saveAnswersLocallyLesson = createAsyncThunk(
  'quiz/saveAnswersLocallyLesson',
  ({ data }: { data: any }) => {
    return { data };
  },
);

export const resetAnswersLocallyLesson = createAsyncThunk(
  'quiz/resetAnswersLocallyLesson',
  () => {
    return [];
  },
);

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClassProgress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getClassProgress.fulfilled, (state, action) => {
        state.status = 'idle';
        state.classProgress = action.payload;
        state.statusLessonResponse = null;
      })
      .addCase(getClassProgress.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
      })
      .addCase(getLessonProgress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLessonProgress.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lessonProgress = action.payload;
        state.statusLessonResponse = null;
      })
      .addCase(getLessonProgress.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
      })
      .addCase(savesLessonProgress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(savesLessonProgress.fulfilled, (state) => {
        state.status = 'idle';
        state.statusLessonResponse = null;
      })
      .addCase(savesLessonProgress.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
      })
      .addCase(resetLesson.fulfilled, (state) => {
        state.status = 'idle';
        state.classProgress = initialState.classProgress;
        state.lessonProgress = initialState.lessonProgress;
        state.statusLessonResponse = null;
      })
      .addCase(saveAnswerQuestionLesson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveAnswerQuestionLesson.fulfilled, (state) => {
        state.status = 'idle';
        state.statusLessonResponse = null;
      })
      .addCase(saveAnswerQuestionLesson.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
      })
      .addCase(saveAnswersLocallyLesson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveAnswersLocallyLesson.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusLessonResponse = null;
        state.answerLocalLesson.push(action.payload.data);
      })
      .addCase(saveAnswersLocallyLesson.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
        state.answerLocalLesson = [];
      })
      .addCase(resetAnswersLocallyLesson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetAnswersLocallyLesson.fulfilled, (state, action) => {
        state.status = 'idle';
        state.statusLessonResponse = null;
        state.answerLocalLesson = [];
      })
      .addCase(resetAnswersLocallyLesson.rejected, (state, action) => {
        state.status = 'failed';
        state.statusLessonResponse = action.payload;
        state.answerLocalLesson = [];
      });
  },
});

export default lessonsSlice.reducer;
