import React from 'react';
import { useSelector } from 'react-redux';
import { getCoachingVideos } from '../../../redux/slices/resourcesSlices';
import { RootState } from '../../../redux/store';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const CoachingVideos = () => {
  const { coachingVideos } = useSelector((state: RootState) => state.resources);

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={coachingVideos}
      curriculumId={curriculumId}
      getResourceAction={getCoachingVideos}
      resourceTypeLabel={'coachingVideos'}
      label="Coaching Videos"
    />
  );
};
