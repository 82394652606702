import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../../LibraryComponents/PageContainer';
import { SingleQuestion } from '../../../Quiz/components/SingleQuestions/SingleQuestions';
import { QuestionType } from '../../../../constants/QuestionType';
import { MultiSelect } from '../../../Quiz/components/MultiSelect/MultiSelect';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { saveLocalLessonProgress } from '../../../../redux/slices/guestSlices';
import { AppDispatch, RootState } from '../../../../redux/store';
import {
  hasHTMLTags,
  sanitizeHTML,
} from '../LessonProgress/LessonProgressHelpers';

export interface ILessonProgressProps {
  lessonProgress: any;
  indexLesson: number;
  multiValue: string[];
  submitting: boolean;
  handleChanges: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePrev: () => void;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  setMultiValue: React.Dispatch<React.SetStateAction<string[]>>;
  totalLessons: number | null;
  setIndexLesson: React.Dispatch<React.SetStateAction<number>>;
}

export const GuestLessonComponent = ({
  lessonProgress,
  indexLesson,
  multiValue,
  submitting,
  handleChanges,
  handlePrev,
  setSubmitting,
  handleNext,
  setMultiValue,
  totalLessons = 0,
  setIndexLesson,
}: ILessonProgressProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { progress, curriculums } = useSelector(
    (state: RootState) => state.guest,
  );
  const [counterLessons, setCounterLessons] = useState<number>(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const lessonId = lessonProgress[0]?.lessonId;
  const lessonProgressLocal = progress.find(
    (item) => item.lessonId === lessonId,
  );

  useEffect(() => {
    if (lessonProgressLocal) {
      const newIndexLesson = lessonProgressLocal.completed
        ? 0
        : lessonProgressLocal.counterLesson;
      setIndexLesson(newIndexLesson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  const handleSaveLessonProgress = () => {
    setCounterLessons(counterLessons + 1);
    handleNext();
  };

  const updateOrInsertLesson = (
    array: {
      lessonId: string;
      counterLesson: number;
      totalLessons: number;
      completed: boolean;
    }[],
    lessonId: string,
  ) => {
    let isCompleted = false;
    if (!array) {
      array = [];
    }
    let found = false;
    const newArray = array?.map(
      (item: {
        lessonId: string;
        counterLesson: number;
        totalLessons: number;
        completed: boolean;
      }) => {
        if (item.lessonId === lessonId && !item.completed) {
          if (item.totalLessons === indexLesson) {
            isCompleted = true;
          }
          found = true;
          return {
            ...item,
            counterLesson: item.counterLesson + 1,
            completed: isCompleted,
          };
        }
        return item;
      },
    );
    if (!found) {
      newArray.push({
        lessonId,
        counterLesson: 0,
        totalLessons: totalLessons ?? 0,
        completed: isCompleted,
      });
    }

    return newArray;
  };

  useEffect(() => {
    if (!lessonProgressLocal?.completed) {
      const data = updateOrInsertLesson(progress, lessonId);
      dispatch(
        saveLocalLessonProgress({
          data,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterLessons, lessonId]);

  const noEmptyOrUndefinedValues = multiValue?.every(
    (item) => item !== '' && item !== undefined,
  );
  const handleQuestionType = () => {
    const questionTypeId =
      lessonProgress[indexLesson]?.question?.questionTypeId;
    switch (questionTypeId) {
      case QuestionType.MultiSelect:
        return 'multi';
      case QuestionType.SingleSelect:
        return 'single';
      default:
        return null;
    }
  };
  const handleSubmitButtonDisabled = () => {
    const currentQuestionType = handleQuestionType();
    if (
      currentQuestionType === 'multi' &&
      multiValue?.length < 2 &&
      noEmptyOrUndefinedValues
    ) {
      return true;
    }
    if (multiValue.length === 0) {
      return true;
    }
  };

  const getLessonById = (lessonId: string) => {
    for (const module of curriculums.modules) {
      const lessons = module.lessons;
      for (const lesson of lessons) {
        if (lesson.lessonId === lessonId) {
          return lesson;
        }
      }
    }
    return null;
  };
  const lessonName = getLessonById(lessonProgress[0].lessonId);

  return (
    <Box
      sx={{
        background: '#75BAFF',
        height: { xs: '85vh', sm: '100vh' },
        paddingTop: '0.1px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '40px',
          left: '40px',
        }}
      >
        <Typography sx={{ fontSize: '32px', color: '#fff' }}>
          {lessonName?.lessonName ?? ''}
        </Typography>
      </Box>
      <PageContainer
        styles={{
          background: '#fff',
          width: { xs: '100%', sm: '642px' },
          padding: '20px',
          height: 'auto',
          position: 'relative',
          minHeight: '40vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: { xs: '32px', sm: '0' },
          m: { xs: '0 10px', sm: '0' },
        }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          style={{
            minHeight: 'inherit',
          }}
        >
          <Grid
            item
            xs
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            {lessonProgress[indexLesson]?.contentText &&
            hasHTMLTags(lessonProgress[indexLesson]?.contentText) ? (
              <Box
                sx={{ img: { width: '100%' } }}
                dangerouslySetInnerHTML={sanitizeHTML(
                  lessonProgress[indexLesson]?.contentText ?? '',
                )}
              />
            ) : (
              <Typography
                variant="h5"
                color={'#003366'}
                sx={{ marginBottom: '30px', textAlign: 'center' }}
              >
                {lessonProgress[indexLesson]?.contentText
                  ? lessonProgress[indexLesson]?.contentText
                  : ''}
              </Typography>
            )}
            {lessonProgress[indexLesson]?.question && (
              <Box sx={{ margin: { xs: '0', sm: '20px 0 20px 40px' } }}>
                {(() => {
                  switch (handleQuestionType()) {
                    case 'single':
                      return (
                        <SingleQuestion
                          key={lessonProgress[indexLesson].question?.questionId}
                          questionText={
                            lessonProgress[indexLesson].question?.questionText
                          }
                          questionExplanation={
                            lessonProgress[indexLesson].question
                              ?.questionExplanation
                          }
                          answerOptions={
                            lessonProgress[indexLesson].question
                              ?.answerPlaces[0]?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                          data-testid="single-question-lessons"
                        />
                      );
                    case 'multi':
                      return (
                        <MultiSelect
                          key={lessonProgress[indexLesson].question?.questionId}
                          questionText={
                            lessonProgress
                              ? lessonProgress[indexLesson]?.question
                                  ?.questionText
                              : ''
                          }
                          questionExplanation={
                            lessonProgress[indexLesson].question
                              ?.questionExplanation
                          }
                          answerOptions={
                            lessonProgress[indexLesson].question
                              ?.answerPlaces[0]?.answerOptions
                          }
                          multiValue={multiValue}
                          submitting={submitting}
                          disabled={isCompleted ? true : submitting}
                          isCompleted={isCompleted}
                          setMultiValue={setMultiValue}
                        />
                      );
                  }
                })()}
              </Box>
            )}
          </Grid>
          <Grid item width={'100%'}>
            <Box
              sx={{
                width: { xs: 'auto', sm: '100%' },
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'space-between' },
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <Button
                data-testid="prev-button-lesson"
                variant="contained"
                sx={{
                  background: '#000',
                  marginRight: '20px',
                  minWidth: '120px',
                }}
                onClick={handlePrev}
                startIcon={<ArrowBack />}
                disabled={indexLesson === 0}
              >
                Prev
              </Button>
              <Typography
                data-testid="lesson-answer-number"
                sx={{ color: '#9C27B0' }}
              >
                {indexLesson + 1} of {totalLessons && totalLessons + 1}
              </Typography>
              <Box>
                {lessonProgress[indexLesson]?.question && !submitting ? (
                  <Box>
                    <Button
                      data-testid="submit-button-lesson"
                      variant="contained"
                      sx={{ background: '#003366' }}
                      onClick={() => setSubmitting(true)}
                      disabled={handleSubmitButtonDisabled()}
                    >
                      Submit answer
                    </Button>
                  </Box>
                ) : (
                  <Button
                    data-testid="next-button-lesson"
                    onClick={handleSaveLessonProgress}
                    variant="contained"
                    sx={{ background: '#FA4729', minWidth: '120px' }}
                    disabled={
                      !!lessonProgress[indexLesson]?.question && !submitting
                    }
                  >
                    next
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PageContainer>
    </Box>
  );
};
