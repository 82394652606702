import React, { useState } from 'react';
import { Logout, Settings, Info } from '@mui/icons-material';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ITeacherMenuOptions } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import useDataCleanup from '../../hooks/useDataCleanup';
import { AccountSettings } from '../../Pages/AccountSettings';
import Modal from '../Modal/Modal';
import { AppDispatch, RootState } from '../../redux/store';
import { logoutTeacher } from '../../redux/slices/teacherSlice';
import { Profile } from '../../Pages/Profile';
import { routeIDs } from '../../Routes/RouteIds.routes';
import useResize from '../../hooks/useResize';

export const TeacherMenuOptions = ({
  setAnchorEl,
  anchorEl,
  open,
  setShowAbout,
}: ITeacherMenuOptions) => {
  const { userId: teacherId, accessToken } = useSelector(
    (state: RootState) => state.auth,
  );

  const { width } = useResize();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { removeData } = useDataCleanup();

  const [showAccountSetting, setShowAccountSetting] = useState(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      removeData();
      dispatch(logoutTeacher({ teacherId, token: accessToken }))
        .then((res) => {
          if (res?.payload?.status === 500) {
            return navigate(routeIDs.PAGE_NOT_FOUND);
          }
        })
        .catch((err) => console.error(err));
      dispatch(logout());
      navigate(routeIDs.LOGIN);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => setShowProfile(true)}
          sx={{ color: '#003366' }}
        >
          <Avatar sx={{ background: '#003366' }} /> Profile
        </MenuItem>
        <MenuItem
          onClick={() => setShowAccountSetting(true)}
          data-testid="teacher-account-settings-button"
          sx={{ color: '#003366' }}
        >
          <Settings
            fontSize="large"
            sx={{ marginLeft: '-4px', marginRight: '4px', color: '#003366' }}
          />
          Account Settings
        </MenuItem>
        <MenuItem
          data-testid="teacher-about-button"
          onClick={() => setShowAbout(true)}
          sx={{ color: '#003366' }}
        >
          <ListItemIcon>
            <Info fontSize="large" sx={{ color: '#003366' }} />
          </ListItemIcon>
          About
        </MenuItem>
        <Divider />
        <MenuItem
          data-testid="teacher-logout-button"
          onClick={handleLogout}
          sx={{ color: '#003366' }}
        >
          <ListItemIcon>
            <Logout fontSize="large" sx={{ color: '#003366' }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Modal
        isOpen={showAccountSetting}
        onClose={() => setShowAccountSetting(false)}
        showCloseButton={false}
        maxWidth={width < 600 ? 400 : 600}
      >
        <AccountSettings setShowAccountSetting={setShowAccountSetting} />
      </Modal>
      <Modal
        isOpen={showProfile}
        onClose={() => setShowProfile(false)}
        showCloseButton={false}
        maxWidth={width < 600 ? 400 : 600}
      >
        <Profile setShowProfile={setShowProfile} />
      </Modal>
    </div>
  );
};
