/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClassProgress,
  resetLesson,
  savesLessonProgress,
} from '../../redux/slices/lessonsSlice';
import { AppDispatch, RootState } from '../../redux/store';
import {
  resetAnswersLocallyQuiz,
  saveAnswerPostQuiz,
  saveAnswerPreQuiz,
  saveAnswersLocallyQuiz,
  saveAnswersLocallyPreQuizGuest,
  saveAnswersLocallyPostQuizGuest,
} from '../../redux/slices/quiz';
import { IAnswerData, IQuizData, Question } from '../../redux/types/quizTypes';
import { QuizCompleted } from '../Quiz/components/QuizCompleted';
import { debounce } from 'lodash';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { RenderContent } from './components/RenderContent';
import { AsideComponent } from './components/AsideComponent/AsideComponent';
import { resetLessonGuest } from '../../redux/slices/guestSlices';
import { useGTM } from '../../hooks/useGTM';
import {
  answerCompleted,
  findAnswerOption,
  handleQuestionType,
  handleSaveDataMultiOpen,
  handleSaveDataSentence,
} from './StudentHelpers';
import { useMatch, useNavigate } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';

export const Student = () => {
  const { pageView } = useGTM();
  const [progressSaveIndexLesson, setProgressSaveIndexLesson] =
    useState<number>(0);
  const match = useMatch('/student/otpClassCode');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [multiValue, setMultiValue] = useState<string[]>([]);
  const [indexLesson, setIndexLesson] = useState<number>(0);
  const [minimized, setMinimized] = useState(4);
  const [questions, setQuestions] = useState<IQuizData>();
  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<{
    done: boolean;
    type: 'question' | 'lesson' | null;
    name: string;
  }>({ done: false, type: null, name: '' });
  const [isPreQuiz, setIsPreQuiz] = useState(false);
  const [openEntryValue, setOpenEntryValue] = useState('');
  const {
    preQuizDefinition,
    postQuizDefinition,
    answerLocalPostGuest,
    answerLocalPreGuest,
  } = useSelector((state: RootState) => state.quiz);
  const [answersLocalGuest, setAnswersLocalGuest] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    userId: studentId,
    token,
    role,
  } = useSelector((state: RootState) => state.auth);
  const { classProgress, lessonProgress } = useSelector(
    (state: RootState) => state.lesson,
  );
  const { lesson, preQuizzes, postQuizzes } = useSelector(
    (state: RootState) => state.guest,
  );
  const [isLessonCompleted, setIsLessonCompleted] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const lessonIdToFind = lessonProgress?.lessonId;
    const lessonWithCompletion = classProgress?.modulesCompletion
      .flatMap((module) => module?.lessonsCompletion)
      .find((lesson) => lesson?.lessonId === lessonIdToFind);

    if (lessonWithCompletion) {
      const completionPercentage = lessonWithCompletion?.completionPercentage;
      if (completionPercentage === 100 && !showQuestions) setIndexLesson(0);
      setIsLessonCompleted(completionPercentage === 100);
    } else {
      setIsLessonCompleted(false);
    }
  }, [classProgress?.modulesCompletion, lessonProgress?.lessonId]);

  const debouncedGetClassProgress = debounce(() => {
    dispatch(getClassProgress({ studentId, token }))
      .then((res) => {
        if ((res.payload === 404 && match !== null) || res.payload === 500) {
          navigate(routeIDs.PAGE_NOT_FOUND);
        }
      })
      .catch((err) => console.error(err));
  }, 500);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    pageView('/student');
    if (studentId && role !== 'guest') debouncedGetClassProgress();
  }, [isCompleted.done]);

  useEffect(() => {
    if (studentId && role !== 'guest') debouncedGetClassProgress();
  }, [studentId, role, isCompleted.done]);

  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(fakeLoading);
  }, [isLoading]);

  const handleResetQuestions = () => {
    setOpenEntryValue('');
    setMultiValue([]);
    setSubmitting(false);
  };

  const handleSaveLesson = () => {
    const lessonId = lessonProgress.lessonId;
    const orderLessons = lessonProgress.lessonContents
      .slice()
      .sort((a, b) => a.sortOrder - b.sortOrder);
    const contentId = orderLessons[indexLesson + 1]?.contentId;
    if (isLessonCompleted) return;

    try {
      dispatch(
        savesLessonProgress({
          studentId,
          token,
          lessonId,
          contentId,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const orderQuestions = (questions: Question[]) => {
    return questions?.slice().sort((a, b) => a.sortOrder - b.sortOrder);
  };

  useEffect(() => {
    setAnswersLocalGuest(
      isPreQuiz ? answerLocalPreGuest : answerLocalPostGuest,
    );
  }, [answerLocalPostGuest, answerLocalPreGuest, isPreQuiz]);

  const handleSaveQuizGuest = (quizData: any) => {
    const orderedQuestions = orderQuestions(quizData.questions);
    const questionType = handleQuestionType(orderedQuestions, indexLesson);
    const currentQuestion = orderedQuestions[indexLesson];
    const answerPlace = currentQuestion?.answerPlaces[0];
    const answerOption = findAnswerOption(
      questionType,
      answerPlace,
      openEntryValue,
      multiValue,
    );
    const isValuePresent = answerPlace?.answerOptions.some(async (obj) =>
      multiValue.includes(obj.answerId),
    );
    const isCompleted =
      answersLocalGuest &&
      answersLocalGuest?.answerLocal?.length === indexLesson;
    if (!currentQuestion || !answerPlace || !isValuePresent || !isCompleted) {
      return;
    }

    const saveAnswer = async (data: any) => {
      try {
        let counter = 0;
        const studentAnswers = await data.answerPlaceAnswers[0].studentAnswers;
        const answerOptions = answerPlace.answerOptions;
        switch (questionType) {
          case 'single':
          case 'sentence':
            const selectedAnswer = answerOptions?.find(
              (op) => op?.answerId === studentAnswers[0]?.answerId,
            );
            counter = selectedAnswer?.isCorrect ? 1 : 0;
            break;

          case 'multi':
            const areAllCorrect = studentAnswers.every((answer: any) =>
              answerOptions.some(
                (op) => op.answerId === answer.answerId && op.isCorrect,
              ),
            );
            counter = areAllCorrect ? 1 : 0;
            break;

          case 'open':
            const studentOpenAnswer = studentAnswers[0].openEntryAnswerText;
            counter =
              studentOpenAnswer === answerOptions[0]?.answerText ? 1 : 0;
            break;

          default:
            break;
        }

        const saveAnswersAction = isPreQuiz
          ? saveAnswersLocallyPreQuizGuest
          : saveAnswersLocallyPostQuizGuest;

        dispatch(saveAnswersAction({ data, counter }));
      } catch (err) {
        console.error('Error saving the quiz answer:', err);
      }
    };

    if (questionType === 'sentence') {
      const dataAnswer: any = handleSaveDataSentence(
        currentQuestion,
        multiValue,
      );

      saveAnswer(dataAnswer);
    } else {
      const data: IAnswerData = handleSaveDataMultiOpen({
        answerPlace,
        answerOption,
        questionType,
        multiValue,
      });

      saveAnswer(data);
    }
  };

  const handleSaveQuiz = async ({
    typeQuiz,
    dispatchAction,
  }: {
    typeQuiz: IQuizData;
    dispatchAction: any;
  }) => {
    const orderedQuestions = orderQuestions(typeQuiz.questions);
    const questionType = handleQuestionType(orderedQuestions, indexLesson);
    const currentQuestion = orderedQuestions[indexLesson];
    const answerPlace = currentQuestion?.answerPlaces[0];
    const answerOption = findAnswerOption(
      questionType,
      answerPlace,
      openEntryValue,
      multiValue,
    );

    const isValuePresent = answerPlace?.answerOptions.some(async (obj) =>
      multiValue.includes(obj.answerId),
    );
    const orderQuestion = questions && orderQuestions(questions?.questions);
    const isCompleted =
      orderQuestion &&
      orderQuestion[indexLesson]?.answerPlaces[0]?.studentAnswers?.length > 0;
    if (
      !currentQuestion ||
      !answerPlace ||
      !isValuePresent ||
      isCompleted ||
      answerCompleted(orderedQuestions, indexLesson)
    ) {
      return;
    }
    const questionId = currentQuestion.questionId;
    const saveAnswer = async (data: IAnswerData) => {
      try {
        await dispatch(saveAnswersLocallyQuiz({ data }));
        await dispatch(dispatchAction({ studentId, questionId, token, data }));
        await debouncedGetClassProgress();
      } catch (err) {
        console.error('Error saving the quiz answer:', err);
      }
    };

    if (questionType === 'sentence') {
      const dataAnswer: any = handleSaveDataSentence(
        currentQuestion,
        multiValue,
      );

      await saveAnswer(dataAnswer);
    } else {
      const data: IAnswerData = handleSaveDataMultiOpen({
        answerPlace,
        answerOption,
        questionType,
        multiValue,
      });
      saveAnswer(data);
    }
  };

  const isLastQuestion = questions?.questions?.length === indexLesson;
  useEffect(() => {
    if (isLastQuestion && questions?.quizName) {
      setIsCompleted({
        done: true,
        type: 'question',
        name: questions?.quizName,
      });
    }
  }, [isLastQuestion, questions?.quizName]);

  const handleSubmit = () => {
    const hasNextQuestion =
      questions && questions?.questions?.length > indexLesson;
    const saveQuizIfNeeded = (typeQuiz: IQuizData, dispatchAction: any) => {
      if (progressSaveIndexLesson === 0) {
        handleSaveQuiz({ typeQuiz, dispatchAction });
      }
    };

    if (hasNextQuestion && showQuestions) {
      if (role === 'guest' && progressSaveIndexLesson === 0) {
        handleSaveQuizGuest(isPreQuiz ? preQuizzes : postQuizzes);
      }

      const quizDefinition = isPreQuiz ? preQuizDefinition : postQuizDefinition;
      const saveFunction = isPreQuiz ? saveAnswerPreQuiz : saveAnswerPostQuiz;

      saveQuizIfNeeded(quizDefinition, saveFunction);
      setSubmitting(true);
      return;
    }
  };

  const handleNext = () => {
    const hasNextLesson =
      lessonProgress &&
      lessonProgress?.lessonContents?.length > indexLesson + 1;
    const isLastLesson =
      lessonProgress?.lessonContents?.length === indexLesson + 1;
    const hasNextQuestion =
      questions && questions?.questions?.length > indexLesson;
    const isLastLessonGuest = lesson?.length === indexLesson + 1;
    const hasNextLessonGuest = lesson && lesson?.length > indexLesson + 1;

    setProgressSaveIndexLesson(
      progressSaveIndexLesson < 0 ? progressSaveIndexLesson + 1 : 0,
    );
    if (isLastLesson && !showQuestions) {
      setIsCompleted({ done: true, type: 'lesson', name: 'lesson' });
      return;
    }

    if (isLastLessonGuest && !showQuestions) {
      setIsCompleted({
        done: true,
        type: 'lesson',
        name: 'Lesson',
      });
      return;
    }
    if (hasNextQuestion && showQuestions) {
      if (role === 'guest' && progressSaveIndexLesson === 0) {
        handleSaveQuizGuest(isPreQuiz ? preQuizzes : postQuizzes);
      }
      handleResetQuestions();
      setIndexLesson((prev) => (hasNextQuestion ? prev + 1 : 0));
      return;
    }

    if (hasNextLesson && role !== 'guest' && !showQuestions) {
      if (progressSaveIndexLesson === 0) handleSaveLesson();
      handleResetQuestions();
      setIndexLesson((prev) => (hasNextLesson ? prev + 1 : 0));
    }

    if (hasNextLessonGuest && role === 'guest' && !showQuestions) {
      handleResetQuestions();
      setIndexLesson((prev) => (hasNextLessonGuest ? prev + 1 : 0));
      return;
    }
  };

  const handlePrev = () => {
    const hasPrevLesson = indexLesson > 0;
    const hasPrevQuestion = questions && questions?.questions?.length > 0;
    const hasPreLessonGuest = lesson && lesson?.length > 0;
    const hasPrevLessonProgress =
      lessonProgress && lessonProgress.lessonContents?.length > 0;

    setProgressSaveIndexLesson(progressSaveIndexLesson - 1);

    if (showQuestions) {
      setIndexLesson((prev) =>
        hasPrevQuestion ? prev - 1 : lessonProgress.lessonContents.length - 1,
      );
    } else if (hasPrevQuestion) {
      setIndexLesson((prev) =>
        hasPrevLesson ? prev - 1 : lessonProgress.lessonContents.length - 1,
      );
    } else if (hasPreLessonGuest) {
      setIndexLesson((prev) =>
        hasPreLessonGuest ? prev - 1 : lesson?.length - 1,
      );
    } else if (hasPrevLessonProgress) {
      setIndexLesson((prev) =>
        hasPrevLessonProgress
          ? prev - 1
          : lessonProgress?.lessonContents?.length - 1,
      );
    }

    handleResetQuestions();
  };

  const handleChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    id?: string,
  ) => {
    setMultiValue([e.target.value]);
  };

  const [open, setOpen] = useState(false);

  const toggleDrawer = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    if (role === 'student') {
      dispatch(resetLessonGuest());
    } else {
      dispatch(resetLesson());
    }
  }, [role]);

  useEffect(() => {
    const orderQuestion = orderQuestions(questions?.questions as any);
    const filterQuestions = orderQuestion?.filter((question) =>
      question.answerPlaces.find(
        (answer) => answer?.studentAnswers?.length > 0,
      ),
    );
    dispatch(resetAnswersLocallyQuiz());

    function selectFields(item: any) {
      if (item.studentAnswers?.length === 0) {
        return;
      } else {
        return dispatch(
          saveAnswersLocallyQuiz({
            data: {
              answerPlaceAnswers: [
                {
                  answerPlaceId: item.answerPlaceId,
                  studentAnswers: item.studentAnswers,
                },
              ],
            },
          }),
        );
      }
    }
    const filterAnswer = (answerItem: any) => {
      if (answerItem?.answerPlaces[0].studentAnswers.length > 0) {
        return dispatch(
          saveAnswersLocallyQuiz({
            data: {
              answerPlaceAnswers: [
                {
                  answerPlaceId: answerItem.answerPlaces[0].answerPlaceId,
                  studentAnswers: answerItem.answerPlaces[0].studentAnswers,
                },
                {
                  answerPlaceId: answerItem.answerPlaces[1].answerPlaceId,
                  studentAnswers: answerItem.answerPlaces[1].studentAnswers,
                },
              ],
            },
          }),
        );
      }
    };

    filterQuestions?.flatMap((answer) => {
      if (answer?.answerPlaces?.length === 2) {
        filterAnswer(answer);
      } else {
        return answer.answerPlaces.map(selectFields);
      }
    });
  }, [dispatch, questions?.questions]);

  useEffect(() => {
    if (studentId && role !== 'guest') debouncedGetClassProgress();
    setProgressSaveIndexLesson(0);
  }, [isPreQuiz]);

  useEffect(() => {
    const filterQuestions = questions
      ? questions.questions?.filter((question) =>
          question.answerPlaces.find(
            (answer) => answer?.studentAnswers?.length > 0,
          ),
        )
      : [];
    if (questions?.questions?.length === filterQuestions?.length) {
      setIndexLesson(0);
    } else {
      setIndexLesson(filterQuestions.length);
    }
  }, [isPreQuiz, questions]);

  return (
    <Grid
      container
      height={'100%'}
      data-testid="questions-grid"
      position={'relative'}
      bgcolor={'#003366'}
    >
      <Button
        onClick={() => toggleDrawer(!open)}
        sx={{
          display: { lg: 'none', xs: 'block' },
          position: 'absolute',
          zIndex: 111,
        }}
      >
        <KeyboardDoubleArrowRightIcon sx={{ color: '#fff' }} />
      </Button>
      <AsideComponent
        minimized={minimized}
        isLoading={isLoading}
        open={open}
        toggleDrawer={toggleDrawer}
        setMinimized={setMinimized}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        setIsCompleted={setIsCompleted}
        setIndexLesson={setIndexLesson}
        handleResetQuestions={handleResetQuestions}
        setShowQuestions={setShowQuestions}
        setIsPreQuiz={setIsPreQuiz}
        setQuestions={setQuestions}
      />

      {isCompleted.done ? (
        <Grid item xs={12} sm={minimized === 1 ? 11 : 8}>
          <QuizCompleted
            isPreQuiz={isPreQuiz}
            totalQuestions={questions?.questions?.length as number}
            type={isCompleted.type}
            label={isCompleted.name}
            isCompleted={isCompleted}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={minimized === 1 ? 11 : 8}>
          <RenderContent
            questions={questions}
            indexLesson={indexLesson}
            multiValue={multiValue}
            submitting={submitting}
            handleChanges={handleChanges}
            handlePrev={handlePrev}
            setSubmitting={setSubmitting}
            handleNext={handleNext}
            lessonProgress={lessonProgress}
            lesson={lesson}
            showQuestions={showQuestions}
            setMultiValue={setMultiValue}
            setOpenEntryValue={setOpenEntryValue}
            setIndexLesson={setIndexLesson}
            isPreQuiz={isPreQuiz}
            handleSubmit={handleSubmit}
            openEntryValue={openEntryValue}
            minimized={minimized}
          />
        </Grid>
      )}
    </Grid>
  );
};
