import { Box, IconButton } from '@mui/material';
import React from 'react';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import useResize from '../../../../hooks/useResize';

export const Minimized = ({
  minimized,
  setMinimized,
  setOpen,
}: {
  minimized: number;
  setMinimized: React.Dispatch<React.SetStateAction<number>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { width } = useResize();
  const handleMinimizedLeft = () => {
    if (width < 600) {
      setOpen(false);
    } else {
      setMinimized(1);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        width: '100%',
        marginRight: minimized === 1 ? '0' : '20px',
      }}
    >
      {minimized > 2 && (
        <IconButton aria-label="chevronLeftIcon" onClick={handleMinimizedLeft}>
          <KeyboardDoubleArrowLeftIcon />
        </IconButton>
      )}
      {minimized >= 1 && minimized < 4 && (
        <IconButton
          aria-label="chevronLeftIcon"
          onClick={() => setMinimized(4)}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      )}
    </Box>
  );
};
