export const initialValues = (classroomCode: string) => {
  return {
    classroomCode: classroomCode || '',
    lastName: '',
    firstName: '',
    userName: '',
    password: '',
    confirmPassword: '',
    preQuizId: '',
  };
};
