import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Spinner } from '../../LibraryComponents/Spinner';
import { useFormik } from 'formik';
import { chatSchema } from './schema';
import { IMessage, IRequestMessage } from './types';
import { groupedData } from './ChatUIHelpers/GroupedMessages';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { BackButton } from './Components/BackButton';
import { PageContainer } from '../../LibraryComponents/PageContainer';
import {
  getAIChatHistory,
  handleAIChatMessage,
} from '../../redux/slices/teacherSlice';
import { debounce } from 'lodash';
import { AIChatForm } from './Components/AIChatForm';
import { Toast } from '../../LibraryComponents/Toast';
import { ChatWindow } from './Components/ChatWindow';
import { scrollToBottom } from './ChatUIHelpers/ScrollToBottom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { useNavigate } from 'react-router-dom';
import useDataCleanup from '../../hooks/useDataCleanup';

export const ChatUI: React.FC = () => {
  const { accessToken: token, userId: teacherId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { aiChatHistory } = useSelector((state: RootState) => state.teacher);
  const { removeData } = useDataCleanup();

  const [isLoading, setIsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState<IMessage[]>([]);
  const [requestMessage, setRequestMessage] = useState<IRequestMessage[]>([]);
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const paperRef = useRef<HTMLDivElement>(null);

  const debouncedGetAIChatHistory = debounce(() => {
    setIsChatHistoryLoading(true);
    dispatch(getAIChatHistory({ teacherId, token }))
      .then((res: any) => {
        if (res?.payload?.status === 401) {
          removeData();
          navigate(routeIDs.LOGIN);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setOpenToast(true);
        setIsChatHistoryLoading(false);
        removeData();
        navigate(routeIDs.LOGIN);
        console.error(error);
      })
      .finally(() => setIsChatHistoryLoading(false));
  }, 500);

  const handleStateErrors = (err: any) => {
    setIsLoading(false);
    setSaveLoading(false);
    setOpenToast(true);
    return console.error(err);
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: chatSchema,
    onSubmit: async ({ message }) => {
      setIsLoading(true);
      setSaveLoading(true);
      try {
        await dispatch(handleAIChatMessage({ message, teacherId, token }))
          .then((res: any) => {
            setIsLoading(false);
            setSaveLoading(false);
            if (res.payload.status !== 401 && res.error) {
              return setOpenToast(true);
            }
            if (res.payload.status === 401) {
              return window.location.reload();
            }
            const botMessage: IRequestMessage = {
              id: new Date().getTime(),
              message: res?.payload?.responseMessage || '',
              dateTime: res?.payload?.responseDateTime,
              isUser: false,
            };

            setRequestMessage((prevMessages) => [...prevMessages, botMessage]);
            scrollToBottom(paperRef);
            formik.resetForm();
          })
          .catch((err) => {
            handleStateErrors(err);
          });
      } catch (err) {
        handleStateErrors(err);
      }
    },
  });

  useEffect(() => {
    if (aiChatHistory) {
      setChatHistory(aiChatHistory);
    }
  }, [aiChatHistory]);

  const groupedChatHistory = groupedData(chatHistory) || [];

  useEffect(() => {
    debouncedGetAIChatHistory();
  }, []);

  useEffect(() => {
    scrollToBottom(paperRef);
  }, [requestMessage, aiChatHistory, chatHistory, isChatHistoryLoading]);

  return (
    <Box>
      <PageContainer
        styles={{
          minHeight: '85%',
          overflow: 'auto',
          pb: 3,
          width: '100%',
          height: '100%',
        }}
      >
        {(isLoading || isChatHistoryLoading) && (
          <Spinner isCircularProgress={isChatHistoryLoading ? true : false} />
        )}
        <BackButton label="LemoneyAI" />
        <ChatWindow
          paperRef={paperRef}
          aiChatHistory={aiChatHistory}
          groupedChatHistory={groupedChatHistory}
          requestMessage={requestMessage}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Toast
            isOpen={openToast}
            severity="error"
            onClose={() => setOpenToast(false)}
            styles={{ width: '100%', textAlign: 'center', top: 0 }}
            errorMessage="Oops, looks like there was a problem responding to your question, please try again! If the problem persists, please reach out to us."
          />
        </Box>
        <AIChatForm
          formik={formik}
          saveLoading={saveLoading}
          setRequestMessage={setRequestMessage}
        />
      </PageContainer>
    </Box>
  );
};
