export const getDateFormat = (currentDate: Date) => {
  if (!(currentDate instanceof Date)) {
    return '';
  }

  const daysWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayWeek = daysWeek[currentDate.getDay()];

  let hour = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const ampm = hour >= 12 ? 'PM' : 'AM';

  hour = hour % 12;
  hour = hour ? hour : 12;

  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  const formattedDate = `${dayWeek} ${hour}:${minutesStr} ${ampm}`;

  return formattedDate;
};
