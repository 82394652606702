import React, { useEffect, useState } from 'react';
import { Box, Grid, List, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import { AppDispatch } from '../../../redux/store';
import {
  getPostDefinitionQuiz,
  getPreDefinitionQuiz,
} from '../../../redux/slices/resourcesSlices';
import { QuizCard } from '../components/QuizCard';
import { CoursesList } from '../components/CoursesList';
import { IQuestionsDefinitions } from '../../../redux/types/resourcesTypes';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { useGTM } from '../../../hooks/useGTM';
import { debounce } from 'lodash';
import { ListResourcesEmpty } from '../../Classroom/components/ListResourcesEmpty';

export const QuizAnswer = () => {
  const { pageView } = useGTM();
  const dispatch = useDispatch<AppDispatch>();
  const [questions, setQuestions] = useState<IQuestionsDefinitions | null>();
  const [searchParams] = useSearchParams();
  const [id] = useState<string | null>(searchParams.get('id'));
  const [typeQuiz] = useState<string | null>(searchParams.get('type'));
  const [isLoading, setIsLoading] = useState(true);
  let order = 0;

  const debouncedFetchQuizData = debounce(async () => {
    if (!id || !typeQuiz) {
      return;
    }

    setIsLoading(true);

    try {
      let quizData;

      if (typeQuiz === 'preQuiz') {
        quizData = await dispatch(getPreDefinitionQuiz(id as string));
      } else if (typeQuiz === 'postQuiz') {
        quizData = await dispatch(getPostDefinitionQuiz(id as string));
      } else {
        return;
      }

      setQuestions(quizData.payload);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, 500);

  useEffect(() => {
    pageView('/educator/testAnswer');
    debouncedFetchQuizData();

    return () => {
      debouncedFetchQuizData.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderQuestions = questions?.questions
    .slice()
    .sort((a, b) => a.sortOrder - b.sortOrder);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <PageContainer
      styles={{
        maxWidth: '100%',
        height: '100vh',
        width: '100%',
        minHeight: '100vh',
        ml: { xl: '30px' },
      }}
    >
      <Grid container justifyContent={'center'}>
        <Grid item xs={12}>
          {questions?.quizName && <CoursesList />}
        </Grid>
        <Grid item xs={12} sx={{ maxHeight: '80vh' }}>
          <Box sx={{ padding: '20px' }}>
            <Typography
              variant="h4"
              sx={{
                textDecoration: 'underline',
                textAlign:
                  orderQuestions && orderQuestions?.length === 0
                    ? 'center'
                    : 'start',
              }}
            >
              {questions?.quizName}
            </Typography>
            {orderQuestions && orderQuestions?.length > 0 ? (
              <List>
                {orderQuestions?.map((question) => (
                  <QuizCard
                    key={question.questionId}
                    answer={question.questionText}
                    answerPlaces={question.answerPlaces}
                    questionExplanation={question.questionExplanation}
                    sortOrder={order++}
                  />
                ))}
              </List>
            ) : (
              <ListResourcesEmpty label={questions?.quizName ?? ''} />
            )}
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};
