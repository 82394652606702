import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const BackButton = ({ label }: { label: string }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        mb: 1,
      }}
    >
      <Button
        sx={{
          color: '#003366',
          fontWeight: '600',
          flex: '0 0 auto',
        }}
        onClick={() => navigate(-1)}
        startIcon={
          <ArrowBackIcon
            sx={{
              fill: '#003366',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
            }}
          />
        }
      >
        Back
      </Button>
      <Typography
        variant="h5"
        color="#7F7F7F"
        fontWeight={700}
        sx={{
          textAlign: 'center',
          flexGrow: 1,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
