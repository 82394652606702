import React from 'react';
import { Box, Divider, ListItem, Typography } from '@mui/material';
import { IQuizCardProps, IAnswerPlace, IAnswer } from '../../types';
import { generateAlphabetArray } from '../../quizHelpers/generateAlphabetArray';

const styles = {
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '1rem',
  },
  questionText: {
    paddingBottom: '1rem',
  },
  answersWrapper: {
    display: 'flex',
    marginBottom: '1rem',
  },
  individualAnswerWrapper: {
    mr: 4,
  },
  explanationText: {
    paddingTop: '.5rem',
  },
  explanationLabel: {
    fontWeight: 700,
  },
};

export const QuizCard: React.FC<IQuizCardProps> = ({
  answer,
  answerPlaces,
  questionExplanation,
  sortOrder,
}) => {
  const alphabetArray = generateAlphabetArray();

  return (
    <Box>
      <ListItem sx={styles.listItem}>
        <Typography sx={styles.questionText} variant="h6">
          {sortOrder + 1}. {answer.replace(/%\d+%/g, '___________')}{' '}
        </Typography>
        <Box sx={styles.answersWrapper}>
          {answerPlaces.map(renderAnswerPlace(alphabetArray))}
        </Box>
        <Typography sx={styles.explanationText}>
          <Typography component={'span'} sx={styles.explanationLabel}>
            Explanation
          </Typography>
          : {questionExplanation}
        </Typography>
      </ListItem>
      <Divider />
    </Box>
  );
};

const renderAnswerPlace =
  (alphabetArray: string[]) => (answerPlace: IAnswerPlace) =>
    (
      <Box key={answerPlace.answerPlaceId} sx={styles.individualAnswerWrapper}>
        {answerPlace.answerOptions.map(renderAnswerOption(alphabetArray))}
      </Box>
    );

const renderAnswerOption =
  (alphabetArray: string[]) => (answer: IAnswer, index: number) =>
    (
      <Typography
        sx={{
          color: answer.isCorrect ? 'green' : 'inherit',
          marginBottom: '0.5rem',
        }}
        key={answer.answerId}
      >
        <span>{alphabetArray[index]}.</span> {answer.answerText}
      </Typography>
    );
