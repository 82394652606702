import styled from 'styled-components';

export const StHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 0 10px;
`;

export const LemonyLogoButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
`;

export const LemonyLogoText = styled.span`
  font-weight: 600;
  font-family: ui-sans-serif;
  font-size: 1.4rem;
  margin-left: 0.4rem;
  color: #003366;
`;
export const UserIcon = styled.div`
  background: #003366;
  color: white;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
`;
