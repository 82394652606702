import React, { useEffect } from 'react';
import { GetStarted } from '../../../../LibraryComponents/GetStarted';
import { QuestionTest } from '../QuestionsTest/QuestionTest';
import { LessonProgressComponent } from '../LessonProgress';
import { GuestLessonComponent } from '../GuestLessonComponent';
import { IQuizData } from '../../../../redux/types/quizTypes';
import { ILessonProgressData } from '../../../../redux/types/lessonsTypes';
import { ILessonData } from '../../../../redux/types/guestTypes';

export interface IRenderContentProps {
  lessonProgress: ILessonProgressData;
  questions: IQuizData | undefined;
  lesson: ILessonData[] | null;
  showQuestions: boolean;
  indexLesson: number;
  multiValue: string[];
  submitting: boolean;
  handleChanges: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePrev: () => void;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  setMultiValue: React.Dispatch<React.SetStateAction<string[]>>;
  setOpenEntryValue: React.Dispatch<React.SetStateAction<string>>;
  setIndexLesson: React.Dispatch<React.SetStateAction<number>>;
  isPreQuiz: boolean;
  handleSubmit: () => void;
  openEntryValue: string;
  minimized: number;
}

export const RenderContent = ({
  lessonProgress,
  questions,
  lesson,
  showQuestions,
  indexLesson,
  multiValue,
  submitting,
  handleChanges,
  handlePrev,
  setSubmitting,
  handleNext,
  setMultiValue,
  setOpenEntryValue,
  setIndexLesson,
  isPreQuiz,
  handleSubmit,
  openEntryValue,
  minimized,
}: IRenderContentProps) => {
  const totalLessons = lessonProgress?.lessonContents?.length - 1;
  const totalQuestions = questions && questions?.questions?.length + 1;
  const noLessonContents = lessonProgress?.lessonContents?.length === 0;
  const noQuestions = !questions?.questions;
  const noLessonGuest = !lesson;
  const totalLessonsGuest = lesson ? lesson.length - 1 : 0;
  function sortBySortOrder<T extends { sortOrder: number }>(data: T[]): T[] {
    return data.slice().sort((a, b) => a.sortOrder - b.sortOrder);
  }

  const orderedLessonProgress = sortBySortOrder(lessonProgress.lessonContents);

  const orderedGuestLessons = lesson ? sortBySortOrder(lesson) : [];

  const orderedQuestions = questions?.questions
    ? sortBySortOrder(questions.questions)
    : [];

  const progressContentId = lessonProgress.progressContentId;

  const lessonContent = lessonProgress?.lessonContents.find(
    (content) => content.contentId === progressContentId,
  );

  const progressIndexLesson = lessonContent?.sortOrder ?? 0;

  useEffect(() => {
    setIndexLesson(progressIndexLesson);
  }, [progressIndexLesson, setIndexLesson, lessonProgress?.lessonContents]);

  if (noLessonContents && noQuestions && noLessonGuest) {
    return <GetStarted label="Select a lesson to get started!" ifLeftArrow />;
  } else if (showQuestions && questions) {
    return (
      <QuestionTest
        data-testid="question-container"
        questions={orderedQuestions}
        indexLesson={indexLesson}
        multiValue={multiValue}
        submitting={submitting}
        handleChanges={handleChanges}
        handlePrev={handlePrev}
        setSubmitting={setSubmitting}
        handleNext={handleNext}
        setMultiValue={setMultiValue}
        setOpenEntryValue={setOpenEntryValue}
        totalQuestions={totalQuestions}
        isPreQuiz={isPreQuiz}
        setIndexLesson={setIndexLesson}
        handleSubmit={handleSubmit}
        minimized={minimized}
      />
    );
  } else if (
    lessonProgress?.lessonContents &&
    lessonProgress?.lessonContents?.length > 0
  ) {
    return (
      <LessonProgressComponent
        lessonProgress={orderedLessonProgress}
        indexLesson={indexLesson}
        multiValue={multiValue}
        submitting={submitting}
        handlePrev={handlePrev}
        setSubmitting={setSubmitting}
        handleNext={handleNext}
        setMultiValue={setMultiValue}
        totalLessons={totalLessons}
        lessonName={lessonProgress?.lessonName}
        setIndexLesson={setIndexLesson}
        lessonProgressId={lessonProgress.lessonId}
        progressIndexLesson={lessonContent?.sortOrder as number}
        handleChanges={handleChanges}
        openEntryValue={openEntryValue}
        setOpenEntryValue={setOpenEntryValue}
        minimized={minimized}
      />
    );
  } else if (lesson && lesson?.length > 0) {
    return (
      <GuestLessonComponent
        lessonProgress={orderedGuestLessons}
        indexLesson={indexLesson}
        multiValue={multiValue}
        submitting={submitting}
        handleChanges={handleChanges}
        handlePrev={handlePrev}
        setSubmitting={setSubmitting}
        handleNext={handleNext}
        setMultiValue={setMultiValue}
        totalLessons={totalLessonsGuest}
        setIndexLesson={setIndexLesson}
      />
    );
  } else return null;
};
