import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Grid from '@mui/material/Grid';
import { ClassroomCard } from '../ClassroomCard';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../redux/store';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { GetStarted } from '../../../LibraryComponents/GetStarted';
import { getClassrooms } from '../../../redux/slices/classroomSlice';
import { useGTM } from '../../../hooks/useGTM';
import { Classroom } from './types';
import { Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ClassroomOverview: React.FC = () => {
  const { classrooms } = useSelector((state: RootState) => state.classroom);
  const { userId, accessToken, role } = useSelector(
    (state: RootState) => state.auth,
    shallowEqual,
  );
  const TeacherData = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );

  const { pageView } = useGTM();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const name = TeacherData
    ? TeacherData.firstName + ' ' + TeacherData.lastName
    : '';

  const [isLoading, setIsLoading] = useState(true);

  const debouncedGetClassrooms = debounce(() => {
    setIsLoading(true);
    dispatch(
      getClassrooms({
        teacherId: userId,
        token: accessToken,
      }),
    )
      .then()
      .catch((err) => console.error(err));
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    pageView('/');

    if (role === 'teacher') {
      debouncedGetClassrooms();
    }
  }, [userId, accessToken]);

  const goToCreateClassroom = () => {
    navigate(routeIDs.EDUCATOR_CREATE_CLASSROOM);
  };

  const handleClassroomRemoved = () => {
    debouncedGetClassrooms();
  };

  const handleClassroomRenamed = (newName: string) => {
    debouncedGetClassrooms();
  };

  const handleClassroomLState = () => {
    debouncedGetClassrooms();
  };

  const compareClassroomsByCreationDateDesc = (
    classroomA: Classroom,
    classroomB: Classroom,
  ): number => {
    const dateA = new Date(classroomA.createdDate);
    const dateB = new Date(classroomB.createdDate);

    return dateB.getTime() - dateA.getTime();
  };

  const sortedClassrooms = classrooms
    ?.slice()
    ?.sort(compareClassroomsByCreationDateDesc);

  return (
    <Box sx={{ margin: 2, ml: { xl: 6 } }}>
      {(isLoading || !classrooms) && <Spinner />}
      {classrooms?.length === 0 && !isLoading ? (
        <Box sx={{ height: '80vh' }}>
          <GetStarted
            label={`Click "Create Classroom" to get started!`}
            isClassroom={true}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 6,
              mt: 2,
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="text"
              sx={{
                color: '#003366',
                fontWeight: 600,
                textTransform: 'none',
                fontSize: { xs: '0.85rem', sm: '1rem' },
              }}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    fill: '#003366',
                    width: { xs: '20px', sm: '25px' },
                    height: { xs: '20px', sm: '25px' },
                  }}
                />
              }
              onClick={() => navigate('/educator/home')}
            >
              Back
            </Button>
            <Typography
              variant="h5"
              fontWeight={700}
              align="center"
              sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
            >
              Explore your Classrooms!
            </Typography>
            <Button
              variant="contained"
              sx={{ background: '#003366', borderRadius: '12px' }}
              onClick={goToCreateClassroom}
              startIcon={<AddIcon />}
            >
              Create Classroom
            </Button>
          </Box>
          <Grid
            container
            sx={{
              margin: { xs: '16px 0 16px 0', md: 2 },
              maxWidth: '100%',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            {classrooms &&
              sortedClassrooms?.map((classroom, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={classroom.classroomId}
                    marginBottom={2}
                    sx={{ marginRight: { xl: 7 } }}
                  >
                    <ClassroomCard
                      teacherId={userId as string}
                      classroomId={classroom.classroomId}
                      classroomCode={classroom.classroomCode}
                      classroomTitle={classroom.classroomName}
                      studentCount={classroom.studentsCount}
                      isLocked={classroom.isLocked}
                      onClassroomRemoved={handleClassroomRemoved}
                      onClassroomRenamed={handleClassroomRenamed}
                      handleClassroomLState={handleClassroomLState}
                      setIsLoading={setIsLoading}
                      index={i}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
