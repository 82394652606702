import { Box, Typography } from '@mui/material';
import React from 'react';
import { IChatProps } from '../../types';
import teacherLemmy from '../../../../Assets/TeacherLemmy.png';
import { getDateFormat } from '../../ChatUIHelpers/GetDateFormat';

export const Chat = ({
  currentDate,
  messages,
  isColumnReversed,
}: IChatProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isColumnReversed ? 'column' : 'column-reverse',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          marginBottom: '10px',
          width: '100%',
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              textAlign: message.requestMessage ? 'right' : 'left',
              margin: '0',
            }}
          >
            <Box>
              <Typography variant="body2" sx={{ color: 'gray', mr: 1 }}>
                {getDateFormat(new Date(message.requestDateTime))}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '8px',
                    width: 'auto',
                  }}
                >
                  {message.requestMessage}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'start',
                flexDirection: 'column',
              }}
            >
              <Typography variant="body2" sx={{ color: 'gray', ml: 6 }}>
                {getDateFormat(new Date(message.responseDateTime))}
              </Typography>{' '}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  component={'img'}
                  src={teacherLemmy}
                  alt="teacher lemmy"
                  width={50}
                  height={'100%'}
                />{' '}
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: '#e0e0e0',
                    padding: '10px',
                    borderRadius: '8px',
                    width: 'auto',
                    maxWidth: { xs: '100%', sm: '50%' },
                    textAlign: 'start',
                  }}
                >
                  {message.responseMessage}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Typography variant="body2" sx={{ color: 'gray' }}>
        {new Date(currentDate).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })}
      </Typography>
    </Box>
  );
};
