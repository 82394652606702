import { ICurriculums } from '../../../redux/types/resourcesTypes';

export interface ITileCardProps {
  courses: ICurriculums[] | undefined;
  isFullCourse: boolean;
  title: string;
}

export enum ExternalUrlIdentifier {
  ContactUs = 0,
  EducatorReferral,
  InviteBanker,
  PlatformInstructions,
  AskAnExpert,
}
