import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ListItemTest } from '../ListItemTest';
import { ExpandMore } from '@mui/icons-material';
import {
  ModulesCompletion,
  Question,
} from '../../../../redux/types/lessonsTypes';
import { BorderLinearProgress } from '../../../../LibraryComponents/BorderLinearProgress';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { resetAnswersLocallyLesson } from '../../../../redux/slices/lessonsSlice';
import { Spinner } from '../../../../LibraryComponents/Spinner';
import { IClassProgressProps } from '../../types';

export const ClassProgressComponent = ({
  classProgress,
  getPreQuiz,
  preQuizId,
  expanded,
  handleChange,
  minimized,
  handleLessonProgress,
  getPostQuiz,
  setIsCompleted,
}: IClassProgressProps) => {
  const { preQuizDefinition, postQuizDefinition } = useSelector(
    (state: RootState) => state.quiz,
  );
  const { progress } = useSelector((state: RootState) => state.student);

  const dispatch = useDispatch<AppDispatch>();

  const [lessonId, setLessonId] = useState<null | string>(null);

  const orderModules = (module: ModulesCompletion[]) => {
    const newModules = module.slice().sort((a, b) => a.sortOrder - b.sortOrder);
    return newModules;
  };

  const handleLabelButton = (percentage: number): string => {
    if (percentage === 100) {
      return 'Review';
    } else if (percentage > 0) {
      return 'Continue';
    } else {
      return 'Start';
    }
  };

  const calculatePercentage = (numerator: number, denominator: number) => {
    const percentage = (numerator / denominator) * 100;
    const roundedPercentage = Math.round(percentage);
    return roundedPercentage;
  };

  const countCorrectQuestions = (questions: Question[]): number => {
    let correctCount = 0;

    if (questions) {
      for (const question of questions) {
        if (question.isCorrect) {
          correctCount++;
        }
      }
    }

    const percentage = calculatePercentage(correctCount, questions.length);
    return isNaN(percentage) ? 0 : percentage;
  };

  const calculateLessonProgressPercentage = (lessonId: string): number => {
    const lesson = progress.find((item) => item.lessonId === lessonId);
    if (!lesson) {
      return 0;
    }

    const { counterLesson, totalLessons } = lesson;
    const percentage = calculatePercentage(
      counterLesson as number,
      totalLessons as number,
    );

    return percentage;
  };

  const handleResetAnswerLesson = () => {
    setIsCompleted({ done: false, type: null, name: '' });
    dispatch(resetAnswersLocallyLesson());
  };
  if (!classProgress?.classroomCode) {
    return <Spinner />;
  }

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{ minWidth: '100%', display: minimized === 1 ? 'none' : '' }}
    >
      <Typography
        sx={{ paddingLeft: '16px', color: '#003366' }}
        variant="h5"
        component="div"
      >
        {classProgress?.classroomName}
      </Typography>

      <List sx={{ overflow: 'hidden', width: '100%' }}>
        <ListItemTest
          title="Pre Test"
          labelBtn={`${handleLabelButton(
            classProgress?.preQuiz.completionPercentage,
          )} Pre-Test`}
          handleClick={getPreQuiz}
          preQuizId={preQuizId}
          completionPercentage={classProgress?.preQuiz?.completionPercentage}
          countCorrectQuestions={countCorrectQuestions(
            preQuizDefinition.questions,
          )}
          questions={preQuizDefinition?.questions}
        />

        {classProgress?.modulesCompletion?.map((module: any, index: any) => (
          <ListItem key={module.moduleId}>
            <Accordion
              data-testid="list-of-lesson"
              expanded={expanded === module.moduleId}
              onChange={handleChange(module.moduleId)}
              sx={{ width: '100%' }}
            >
              <AccordionSummary
                data-testid="lesson-button"
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  background: '#F7F8FA',
                  width: '100%',
                  transition: 'box-shadow 0.3s ease',
                  '&:hover': {
                    background: '#e3e3e3',
                    transform: 'scale(1.02)',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography color={'#003366'}>
                    {module.moduleName ? module.moduleName : `Module ${index}`}
                  </Typography>
                  <Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={module.completionPercentage || 0}
                    />
                  </Box>
                </Box>
              </AccordionSummary>
              {orderModules(module?.lessonsCompletion).map(
                (lesson: any, i: number) => (
                  <AccordionDetails
                    sx={{
                      background:
                        lessonId === lesson.lessonId ? '#d7dadc' : '#F7F8FA',
                      paddingLeft: minimized === 1 ? '10px' : '40px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      cursor: 'pointer',
                      transition: 'box-shadow 0.3s ease',
                      '&:hover': {
                        background: '#e3e3e3',
                        transform: 'scale(1.02)',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    key={lesson.lessonId}
                    onClick={() => {
                      setLessonId(lesson.lessonId);
                      handleResetAnswerLesson();
                      return handleLessonProgress(lesson.lessonId);
                    }}
                  >
                    <Box key={lesson.lessonId}>
                      <Typography color={'#003366'}>
                        {lesson.lessonName}
                      </Typography>
                      <Box sx={{ margin: '5px 0 20px 0' }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            calculateLessonProgressPercentage(
                              lesson.lessonId,
                            ) ||
                            lesson.completionPercentage ||
                            0
                          }
                        />
                      </Box>
                    </Box>
                  </AccordionDetails>
                ),
              )}
            </Accordion>
            <Box>
              <BorderLinearProgress variant="determinate" value={50} />
            </Box>
          </ListItem>
        ))}
        <ListItemTest
          title="Course Test"
          labelBtn={`${handleLabelButton(
            classProgress?.postQuiz?.completionPercentage,
          )} Course Test`}
          handleClick={getPostQuiz}
          preQuizId={classProgress?.preQuiz?.quizId}
          completionPercentage={classProgress?.postQuiz?.completionPercentage}
          countCorrectQuestions={countCorrectQuestions(
            postQuizDefinition.questions,
          )}
          questions={postQuizDefinition?.questions}
        />
      </List>
    </Grid>
  );
};
