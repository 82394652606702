import styled from 'styled-components';

export interface IModalsProps {
  maxWidth?: number;
}

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div<IModalsProps>`
  width: 90%;
  max-height: 97%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  overflow: auto;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0;
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;
