import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  ListItem,
  Typography,
} from '@mui/material';
import { BorderLinearProgress } from '../../../../LibraryComponents/BorderLinearProgress';
import { IListItemTest } from '../../types';

export const ListItemTest = ({
  title,
  labelBtn,
  handleClick,
  preQuizId,
  completionPercentage,
  countCorrectQuestions,
  questions,
}: IListItemTest) => {
  return (
    <ListItem>
      <Accordion expanded sx={{ width: '100%' }}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            background: '#d7dadc',
            width: '100%',
            '.Mui-expanded': {
              m: '0 !important',
              borderRadius: '8px !important',
            },
            padding: '8px',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: {
                    xs: 'none',
                    sm: 'space-around',
                    md: 'normal',
                  },
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                <Typography
                  sx={{
                    color: '#003366',
                    fontWeight: { xs: '600', lg: 'bold' },
                    fontSize: '16px',
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                >
                  {title}
                </Typography>
                {completionPercentage === 100 && questions.length > 0 && (
                  <Typography
                    sx={{
                      color: '#003366',
                      fontSize: '16px',
                      textAlign: { xs: 'start', md: 'center' },
                      flex: { xs: '1', sm: 'none', md: '1' },
                    }}
                  >
                    {`Test Score: ${countCorrectQuestions}%`}
                  </Typography>
                )}
              </Box>
              <Button
                data-testid="button-pretest/course"
                variant="contained"
                onClick={() => handleClick(preQuizId)}
                sx={{
                  background: '#003366',
                  fontSize: { xl: '14px', xs: '12px' },
                  fontWeight: 600,
                  borderRadius: '4px',
                  marginLeft: { sm: 0, md: '8px' },
                }}
              >
                {labelBtn}
              </Button>
            </Box>
            <Box sx={{ width: '100%', background: '#edeff2', mt: '10px' }}>
              <BorderLinearProgress
                variant="determinate"
                value={completionPercentage || 0}
                sx={{ width: '100%', margin: 'auto' }}
              />
            </Box>
          </Box>
        </AccordionSummary>
      </Accordion>
    </ListItem>
  );
};
