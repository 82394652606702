import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addTeacherLicenseKey,
  teacherRefreshLogin,
} from '../../../../redux/slices/teacherSlice';
import { AppDispatch } from '../../../../redux/store';
import { generateLicenseKeyErrorMessage } from '../../AccountSettingsHelpers/generateLicenseKeyErrorMessage';
import { loginRefresh } from '../../../../redux/slices/authSlice';
import { ILicenseKeyEntryProps } from '../../types';
import { ContactUs } from '../../../../LibraryComponents/ContactUs';

export const LicenseKeyEntry = ({
  open,
  setOpenLicenseKeyEntry,
  token,
  teacherId,
  refreshToken,
  renderMessageWithContactUs,
}: ILicenseKeyEntryProps) => {
  const [licenseKey, setLicenseKey] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [errorMessage, setErrorMessage] = useState('');
  const isGUID = (guid: string): boolean => {
    const guidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return guidRegex.test(guid);
  };

  const handleSubmit = async () => {
    try {
      const addTeacherLicense = await dispatch(
        addTeacherLicenseKey({ licenseKey, token, teacherId }),
      );

      if (!addTeacherLicense.payload) {
        const teacherRefresh = await dispatch(
          teacherRefreshLogin({ userId: teacherId, refreshToken }),
        );
        dispatch(loginRefresh(teacherRefresh.payload));
        setOpenLicenseKeyEntry(false);
        setErrorMessage('');
      } else {
        setErrorMessage(addTeacherLicense.payload.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = () => {
    setErrorMessage('');
    setOpenLicenseKeyEntry(false);
  };

  return (
    <Dialog
      sx={{ zIndex: '999999' }}
      open={open}
      onClose={() => setOpenLicenseKeyEntry(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '10px 20px',
          width: '450px',
          padding: '40px 20px',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '40px', fontWeight: 700 }}>
          Enter License Key
        </Typography>
        <TextField
          data-testid="input-license-key"
          fullWidth
          name="licenseKey"
          variant="standard"
          label="License Key"
          onChange={(e) => setLicenseKey(e.target.value)}
          onFocus={() => setErrorMessage('')}
        />
        {errorMessage && (
          <Box sx={{ width: '100%', mt: '10px', textAlign: 'center' }}>
            <Typography color={'error'}>
              {renderMessageWithContactUs(
                generateLicenseKeyErrorMessage(errorMessage) as string,
              )}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '40px',
            mb: '40px',
          }}
        >
          <Button
            variant="outlined"
            data-testid="cancel-license-key"
            sx={{ color: '#000', fontWeight: 'bold', borderColor: '#000' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            onClick={handleSubmit}
            disabled={!isGUID(licenseKey) && !licenseKey}
          >
            Submit
          </Button>
        </Box>
        <Typography fontWeight={600}>
          Don't have a license key? <ContactUs /> to purchase one!
        </Typography>
      </Box>
    </Dialog>
  );
};
