import { Box, Button, Dialog, Typography } from '@mui/material';
import React from 'react';
import { ContactUs } from '../ContactUs';
import lemmyConstruction from '../../Assets/lemon-construction.png';
import { IWorkingDialogProps } from './types';

export const WorkingDialog = ({
  open,
  handleClose,
  msgBeforeContactUs,
  mgsAfterContactUs,
}: IWorkingDialogProps) => {
  return (
    <Dialog sx={{ zIndex: '1200' }} open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '10px 20px',
          padding: '40px',
        }}
      >
        <Box
          component={'img'}
          src={lemmyConstruction}
          width={200}
          height={'100%'}
        />
        <Typography
          sx={{ marginBottom: '20px', fontSize: '18px', fontWeight: 600 }}
        >
          {msgBeforeContactUs} <ContactUs /> {''}
          {mgsAfterContactUs}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: '40px',
          }}
        >
          <Button
            variant="outlined"
            data-testid="cancel-delete-confirm-dialog"
            sx={{ width: '120px', color: '#003366', fontWeight: 500 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
