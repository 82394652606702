import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICurriculums,
  IDistricts,
  IExternalUrls,
  IGrades,
  IResource,
  IResourcesState,
  IStates,
} from '../types/resourcesTypes';

const initialState: IResourcesState = {
  status: 'idle',
  curriculums: [],
  preQuiz: null,
  postQuiz: null,
  states: null,
  grades: null,
  externalUrls: [],
  districts: null,
  classroomActivities: [],
  childrenBooks: [],
  videoResources: [],
  additionalResources: [],
  statusResourcesResponse: null,
  coachingVideos: [],
  preBuiltAssessments: [],
  worksheets: [],
  careerVideos: [],
  projects: [],
};

const API_URL = process.env.REACT_APP_API_URL;

export const getCurriculums = createAsyncThunk(
  'resources/getCurriculums',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/curriculums`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as ICurriculums[];
  },
);

export const getPreDefinitionQuiz = createAsyncThunk(
  'resources/getPreDefinitionQuiz',
  async (quizId: string, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/Quizzes/${quizId}`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return await response.json();
  },
);

export const getPostDefinitionQuiz = createAsyncThunk(
  'resources/getPostDefinitionQuiz',
  async (quizId: string, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/Quizzes/${quizId}`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return await response.json();
  },
);

export const getStates = createAsyncThunk(
  'resources/getStates',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/states`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IStates[];
  },
);

export const getGrades = createAsyncThunk(
  'resources/getGrades',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/grades`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IGrades[];
  },
);

export const getExternalUrls = createAsyncThunk(
  'resources/getExternalUrls',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/externalurls`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IExternalUrls[];
  },
);

export const getDistricts = createAsyncThunk(
  'resources/getDistricts',
  async (_, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/resources/districts`);
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IDistricts[];
  },
);

export const getClassroomActivities = createAsyncThunk(
  'resources/getClassroomActivities',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/classroomactivities?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getChildrenBooks = createAsyncThunk(
  'resources/getChildrenBooks',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/childrensbooks?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getVideoResources = createAsyncThunk(
  'resources/getVideoResources',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/videoresources?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getAdditionalResources = createAsyncThunk(
  'resources/getAdditionalResources',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/additionalresources?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getWorksheets = createAsyncThunk(
  'resources/getWorksheets',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/worksheets?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);
export const getAPreBuiltAssessments = createAsyncThunk(
  'resources/getAPreBuiltAssessments',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/prebuiltassessments?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getCoachingVideos = createAsyncThunk(
  'resources/getCoachingVideos',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/coachingvideos?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getCareerVideos = createAsyncThunk(
  'resources/getCareerVideos',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/careervideos?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const getProjects = createAsyncThunk(
  'resources/getProjects',
  async ({ curriculumId }: { curriculumId: string }, { rejectWithValue }) => {
    const response = await fetch(
      `${API_URL}/resources/projects?curriculumId=${curriculumId}`,
    );
    if (!response.ok) {
      return rejectWithValue({ status: response.status });
    }
    return (await response.json()) as IResource[];
  },
);

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurriculums.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCurriculums.fulfilled, (state, action) => {
      state.status = 'idle';
      state.curriculums = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getCurriculums.rejected, (state, action) => {
      state.status = 'failed';
      state.curriculums = null;
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getPreDefinitionQuiz.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getPreDefinitionQuiz.fulfilled, (state, action) => {
      state.status = 'idle';
      state.preQuiz = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getPreDefinitionQuiz.rejected, (state, action) => {
      state.status = 'failed';
      state.preQuiz = null;
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getPostDefinitionQuiz.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getPostDefinitionQuiz.fulfilled, (state, action) => {
      state.status = 'idle';
      state.postQuiz = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getPostDefinitionQuiz.rejected, (state, action) => {
      state.status = 'failed';
      state.postQuiz = null;
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getStates.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.status = 'idle';
      state.states = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getStates.rejected, (state, action) => {
      state.status = 'failed';
      state.states = null;
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getGrades.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getGrades.fulfilled, (state, action) => {
      state.status = 'idle';
      state.grades = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getGrades.rejected, (state, action) => {
      state.status = 'failed';
      state.grades = null;
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getExternalUrls.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getExternalUrls.fulfilled, (state, action) => {
      state.status = 'idle';
      state.externalUrls = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getExternalUrls.rejected, (state, action) => {
      state.status = 'failed';
      state.externalUrls = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getDistricts.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getDistricts.fulfilled, (state, action) => {
      state.status = 'idle';
      state.districts = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getDistricts.rejected, (state, action) => {
      state.status = 'failed';
      state.districts = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getClassroomActivities.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getClassroomActivities.fulfilled, (state, action) => {
      state.status = 'idle';
      state.classroomActivities = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getClassroomActivities.rejected, (state, action) => {
      state.status = 'failed';
      state.classroomActivities = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getChildrenBooks.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getChildrenBooks.fulfilled, (state, action) => {
      state.status = 'idle';
      state.childrenBooks = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getChildrenBooks.rejected, (state, action) => {
      state.status = 'failed';
      state.childrenBooks = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getVideoResources.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getVideoResources.fulfilled, (state, action) => {
      state.status = 'idle';
      state.videoResources = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getVideoResources.rejected, (state, action) => {
      state.status = 'failed';
      state.videoResources = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getAdditionalResources.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAdditionalResources.fulfilled, (state, action) => {
      state.status = 'idle';
      state.additionalResources = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getAdditionalResources.rejected, (state, action) => {
      state.status = 'failed';
      state.additionalResources = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getCoachingVideos.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCoachingVideos.fulfilled, (state, action) => {
      state.status = 'idle';
      state.coachingVideos = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getCoachingVideos.rejected, (state, action) => {
      state.status = 'failed';
      state.coachingVideos = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getAPreBuiltAssessments.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getAPreBuiltAssessments.fulfilled, (state, action) => {
      state.status = 'idle';
      state.preBuiltAssessments = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getAPreBuiltAssessments.rejected, (state, action) => {
      state.status = 'failed';
      state.preBuiltAssessments = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getWorksheets.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getWorksheets.fulfilled, (state, action) => {
      state.status = 'idle';
      state.worksheets = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getWorksheets.rejected, (state, action) => {
      state.status = 'failed';
      state.worksheets = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getCareerVideos.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getCareerVideos.fulfilled, (state, action) => {
      state.status = 'idle';
      state.careerVideos = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getCareerVideos.rejected, (state, action) => {
      state.status = 'failed';
      state.careerVideos = [];
      state.statusResourcesResponse = action.payload;
    });
    builder.addCase(getProjects.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.status = 'idle';
      state.projects = action.payload;
      state.statusResourcesResponse = null;
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.status = 'failed';
      state.projects = [];
      state.statusResourcesResponse = action.payload;
    });
  },
});

export default resourcesSlice.reducer;
