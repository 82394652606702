import { SubscriptionStatusType } from '../../../constants/SubscriptionStatusType';
import { formatDateToOrdinal } from './formatDateToOrdinal';
import { LICENSE_STATUS_MESSAGES } from './licenseStatusMessage';

export const generateLicenseMessage = (
  isPremium: boolean,
  userSubscriptionStatus: SubscriptionStatusType,
  expirationDate: Date | null,
) => {
  //If they're not premium and there isn't an expiration date, they've never been premium.
  if (!expirationDate) {
    return {
      message: LICENSE_STATUS_MESSAGES.NO_LICENSE.message,
      color: LICENSE_STATUS_MESSAGES.NO_LICENSE.color,
    };
  }

  const expiredDate = new Date(expirationDate);
  expiredDate.setDate(expiredDate.getDate() + 1); //This is to correct for UTC translation
  expiredDate.setHours(0, 0, 0, 0);
  const currentDateObj = new Date();
  currentDateObj.setHours(0, 0, 0, 0);

  //If they're expired, it doesn't matter if they have a subscription or not, they're expired so send that message
  if (expiredDate < currentDateObj) {
    return {
      message: LICENSE_STATUS_MESSAGES.LICENSE_EXPIRED.message.replace(
        '{ExpirationDate}',
        formatDateToOrdinal(expiredDate),
      ),
      color: LICENSE_STATUS_MESSAGES.LICENSE_EXPIRED.color,
    };
  }

  if (userSubscriptionStatus === SubscriptionStatusType.Cancelled) {
    return {
      message: LICENSE_STATUS_MESSAGES.CANCELLED_SUBSCRIPTION.message,
      color: LICENSE_STATUS_MESSAGES.CANCELLED_SUBSCRIPTION.color,
    };
  }

  if (userSubscriptionStatus === SubscriptionStatusType.PastDue) {
    return {
      message: LICENSE_STATUS_MESSAGES.PAST_DUE_SUBSCRIPTION.message,
      color: LICENSE_STATUS_MESSAGES.PAST_DUE_SUBSCRIPTION.color,
    };
  }

  if (userSubscriptionStatus === SubscriptionStatusType.Active) {
    return {
      message: LICENSE_STATUS_MESSAGES.ACTIVE_SUBSCRIPTION.message,
      color: LICENSE_STATUS_MESSAGES.ACTIVE_SUBSCRIPTION.color,
    };
  }

  const timeDifference = expiredDate.getTime() - currentDateObj.getTime();
  const daysUntilExpiration = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  if (daysUntilExpiration <= 120) {
    return {
      message: LICENSE_STATUS_MESSAGES.LICENSE_EXPIRING.message.replace(
        '{ExpirationDate}',
        formatDateToOrdinal(expiredDate),
      ),
      color: LICENSE_STATUS_MESSAGES.LICENSE_EXPIRING.color,
    };
  }
  if (isPremium) {
    return {
      message: LICENSE_STATUS_MESSAGES.LICENSE_VALID.message.replace(
        '{ExpirationDate}',
        formatDateToOrdinal(expiredDate),
      ),
      color: LICENSE_STATUS_MESSAGES.LICENSE_VALID.color,
    };
  }
  return {
    message: LICENSE_STATUS_MESSAGES.NO_LICENSE.message,
    color: LICENSE_STATUS_MESSAGES.NO_LICENSE.color,
  };
};
