import React from 'react';
import { ClassroomOverview } from '../Pages/Classroom/ClassroomOverview';
import { Student } from '../Pages/Student';
import { CreateClassroom } from '../Pages/Classroom/CreateClassroom';
import { ClassroomDashboard } from '../Pages/ClassroomDashboard';
import { ClassroomActivities } from '../Pages/Classroom/ClassroomActivities';
import { VideoLibrary } from '../Pages/Classroom/VideoLibrary/VideoLibrary';
import { ChildrenBooks } from '../Pages/Classroom/ChildrenBooks';
import { OTPClassCode } from '../Pages/Classroom/ClassCode';
import { EducatorPrivateRouter, StudentPrivateRouter } from './Private.Routes';
import { PrivacyPolicy } from '../Pages/PrivacyPolicy';
import { CookiePolicy } from '../Pages/CookiePolicy';
import { TermAndCondition } from '../Pages/TermAndConditions';
import { QuizAnswer } from '../Pages/Quiz/QuizAnswer';
import { ThankYouScreen } from '../Pages/ThankYouScreen';
import { GuestSelectionCourses } from '../Pages/Guest/GuestSelectionCourses';
import { ForgotPassword } from '../Pages/ForgotPassword';
import { ErrorPage } from '../GlobalComponents/ErrorPage';
import { routeIDs } from './RouteIds.routes';
import { ResetPassword } from '../Pages/Student/ResetPassword';
import { ConfirmForgotPassword } from '../Pages/ConfirmForgotPassword';
import { AdditionalResources } from '../Pages/Classroom/AdditionalResources';
import { ClassroomRedesign } from '../Pages/ClassroomTile';
import { ResourcesList } from '../Pages/ClassroomTile/Components/ResourcesList';
import { RoleSelectionScreen } from '../Pages/RoleSelectionScreen';
import { Login } from '../Pages/Login';
import { CreateAccount } from '../Pages/CreateAccount';
import { Navigate } from 'react-router-dom';
import { CoachingVideos } from '../Pages/Classroom/CoachingVideos';
import { PrebuiltAssessments } from '../Pages/Classroom/PrebuiltAssessments';
import { Worksheets } from '../Pages/Classroom/Worksheets';
import { CareerVideos } from '../Pages/Classroom/CareerVideo';
import { Projects } from '../Pages/Classroom/Projects';
import { ChatUI } from '../Pages/ChatUI';
import { ChoosePlan } from '../PremiumSubscription/ChoosePlan';
import { SelfCheckoutUnderConstruction } from '../PremiumSubscription/SelfCheckoutUnderConstruction/SelfCheckoutUnderConstruction';

const isGuest: boolean = false;

const guestRoutes = !isGuest
  ? []
  : [
      {
        id: routeIDs.GUEST_SELECTION_COURSES,
        path: 'guestSelectionCourses',
        element: <GuestSelectionCourses />,
      },
    ];

export const Routes = [
  {
    index: true,
    id: routeIDs.ROLE_SELECTION_SCREEN,
    element: <RoleSelectionScreen />,
  },
  {
    id: routeIDs.STUDENT,
    path: 'student',
    element: (
      <StudentPrivateRouter>
        <Student />
      </StudentPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_CREATE_CLASSROOM,
    path: 'educator/createClassroom',
    element: (
      <EducatorPrivateRouter>
        <CreateClassroom />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: `${routeIDs.EDUCATOR_CLASSROOM_DASHBOARD}/:teacherId/:classroomId`,
    path: 'educator/classroomDashboard/:teacherId/:classroomId',
    element: (
      <EducatorPrivateRouter>
        <ClassroomDashboard />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_CLASSROOM_ACTIVITIES,
    path: 'educator/classroomActivities',
    element: (
      <EducatorPrivateRouter>
        <ClassroomActivities />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_VIDEO_LIBRARY,
    path: 'educator/videoLibrary',
    element: (
      <EducatorPrivateRouter>
        <VideoLibrary />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_BOOK_STORE,
    path: 'educator/bookStore',
    element: (
      <EducatorPrivateRouter>
        <ChildrenBooks />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.STUDENT_OTP_CLASS_CODE,
    path: 'student/otpClassCode',
    element: (
      <StudentPrivateRouter>
        <OTPClassCode />
      </StudentPrivateRouter>
    ),
  },
  {
    id: routeIDs.PRIVACY_POLICY,
    path: 'privacyPolicy',
    element: <PrivacyPolicy />,
  },
  {
    id: routeIDs.COOKIE_POLICY,
    path: 'cookiePolicy',
    element: <CookiePolicy />,
  },
  {
    id: routeIDs.TERM_AND_CONDITION,
    path: 'TermAndCondition',
    element: <TermAndCondition />,
  },
  {
    id: routeIDs.EDUCATOR_TEST_ANSWER,
    path: 'educator/testAnswer',
    element: (
      <EducatorPrivateRouter>
        <QuizAnswer />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.THANK_YOU_SCREEN,
    path: 'thankYouScreen',
    element: <ThankYouScreen />,
  },
  {
    id: routeIDs.FORGOT_PASSWORD,
    path: 'forgotPassword',
    element: <ForgotPassword />,
  },
  {
    id: routeIDs.STUDENT_RESET_PASSWORD,
    path: 'student/resetPassword',
    element: <ResetPassword />,
  },
  {
    path: 'confirmForgotPassword',
    element: <ConfirmForgotPassword />,
  },
  {
    id: routeIDs.EDUCATOR_ADDITIONAL_RESOURCES,
    path: '/educator/additionalResources',
    element: (
      <EducatorPrivateRouter>
        <AdditionalResources />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_HOME,
    path: '/educator/home',
    element: (
      <EducatorPrivateRouter>
        <ClassroomRedesign />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_RESOURCES_LIST,
    path: '/educator/resourcesList/:curriculumID',
    element: (
      <EducatorPrivateRouter>
        <ResourcesList />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_CLASSROOMS,
    path: '/educator/classrooms',
    element: (
      <EducatorPrivateRouter>
        <ClassroomOverview />
      </EducatorPrivateRouter>
    ),
  },
  ...guestRoutes,
  {
    id: routeIDs.LOGIN,
    path: 'login',
    element: <Login />,
  },
  {
    id: routeIDs.CREATE_ACCOUNT,
    path: 'createAccount',
    element: <CreateAccount />,
  },
  {
    id: routeIDs.EDUCATOR_REDIRECT,
    path: '/educator',
    element: <Navigate to="/educator/home" />,
  },
  {
    id: routeIDs.EDUCATOR_REDIRECT_LOGIN,
    path: '/educator/login',
    element: <Navigate to="/login?role=teacher" />,
  },
  {
    id: routeIDs.EDUCATOR_REDIRECT_CREATE_ACCOUNT,
    path: '/educator/createAccount',
    element: <Navigate to="/createAccount?role=teacher" />,
  },
  {
    id: routeIDs.STUDENT_REDIRECT_LOGIN,
    path: '/student/login',
    element: <Navigate to="/login?role=student" />,
  },
  {
    id: routeIDs.STUDENT_REDIRECT_CREATE_ACCOUNT,
    path: '/student/createAccount',
    element: <Navigate to="/createAccount?role=student" />,
  },
  {
    id: routeIDs.EDUCATOR_COACHING_VIDEOS,
    path: 'educator/coachingVideos',
    element: (
      <EducatorPrivateRouter>
        <CoachingVideos />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_PRE_BUILT_ASSESSMENTS,
    path: 'educator/preBuiltAssessments',
    element: (
      <EducatorPrivateRouter>
        <PrebuiltAssessments />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_WORKSHEETS,
    path: '/educator/worksheets',
    element: (
      <EducatorPrivateRouter>
        <Worksheets />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_PROJECTS,
    path: 'educator/projects',
    element: (
      <EducatorPrivateRouter>
        <Projects />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_CAREER_VIDEOS,
    path: '/educator/careerVideos',
    element: (
      <EducatorPrivateRouter>
        <CareerVideos />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_CHAT,
    path: '/educator/chat',
    element: (
      <EducatorPrivateRouter>
        <ChatUI />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_PREMIUM_CHOOSEPLAN,
    path: '/educator/premium/choosePlan',
    element: (
      <EducatorPrivateRouter>
        <ChoosePlan />
      </EducatorPrivateRouter>
    ),
  },
  {
    id: routeIDs.EDUCATOR_PREMIUM_SUBSCRIPTION_SUCCESS,
    path: '/educator/premium/success',
    element: <SelfCheckoutUnderConstruction />,
  },
  {
    id: routeIDs.PAGE_NOT_FOUND,
    path: '*',
    element: (
      <ErrorPage
        status={404}
        title="Page Not Found"
        description="Sorry, the page you are looking for does not exist."
      />
    ),
  },
];
