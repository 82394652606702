export const generateModuleLessonName = (
  moduleName: string,
  lessonName: string,
) => {
  if (lessonName === 'Introduction') {
    return `${moduleName} - ${lessonName}`;
  } else {
    return lessonName;
  }
};
