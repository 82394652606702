import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import lemmyLogo from '../../../Assets/Lemmy_Base.png';
import { useAppDispatch } from '../../../redux/store';
import useDataCleanup from '../../../hooks/useDataCleanup';
import { studentResetPassword } from '../../../redux/slices/studentSlice';
import { logout } from '../../../redux/slices/authSlice';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { useSearchParams } from 'react-router-dom';
import { useGTM } from '../../../hooks/useGTM';

const changePasswordSchema = Yup.object().shape({
  username: Yup.string().required(),
  currentPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must contain at least one uppercase letter, one lowercase letter, one number and one symbol',
    ),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must contain at least one uppercase letter, one lowercase letter, one number and one symbol',
    )
    .required(),
  confirmPassword: Yup.string()
    .min(8, 'Confirm Password must be at least 8 characters')
    .oneOf([Yup.ref('newPassword'), ''], 'The password does not match')
    .required(),
});

export const ResetPassword = () => {
  const { pageView } = useGTM();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isResetPending: boolean = !!searchParams.get('reset');
  const navigate = useNavigate();
  const { removeData } = useDataCleanup();
  const [saveLoading, setSaveLoading] = useState(false);
  useEffect(() => {
    pageView('/student/resetPassword');
  }, []);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      username: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      setSaveLoading(true);
      const { username, newPassword, currentPassword } = values;
      if (!currentPassword && !isResetPending) {
        formik.setFieldError(
          'currentPassword',
          'current password is a required field',
        );
        return;
      }
      await dispatch(
        studentResetPassword({
          newPassword,
          username,
        }),
      )
        .then(() => {
          removeData();
          logout();
        })
        .catch((error: any) => {
          console.error(error);
        });
      navigate('/login?reset=successfully');
      setSaveLoading(false);
      formik.resetForm();
    },
  });
  const labelBtn = isResetPending ? 'Submit' : 'Save';
  return (
    <Box sx={{ margin: 2 }}>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <img src={lemmyLogo} alt="educator-logo" width={80} />
        </Box>
        <Typography
          variant="h4"
          sx={{ mt: 1.5, textAlign: 'center', fontWeight: '600' }}
          gutterBottom
        >
          Reset Password
        </Typography>
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '300px', md: '400px' },
          }}
        >
          <FormsControl
            control="input"
            type="text"
            name="username"
            label="Username"
            formik={formik}
          />
          {!isResetPending && (
            <FormsControl
              control="input"
              type="password"
              name="currentPassword"
              label="Current Password"
              formik={formik}
            />
          )}
          <FormsControl
            control="input"
            type="password"
            name="newPassword"
            label="New Password"
            formik={formik}
          />
          <FormsControl
            control="input"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            formik={formik}
          />
          <FormControl
            fullWidth
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
            variant="outlined"
          >
            <Button
              variant="outlined"
              sx={{ mt: 3, mb: 2, mr: '20px', fontSize: '16px' }}
              onClick={() => navigate(routeIDs.LOGIN)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: '16px', minWidth: '97px' }}
              disabled={saveLoading}
            >
              {saveLoading ? <CircularProgress size={24} /> : labelBtn}
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
