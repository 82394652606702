import { useState, useEffect } from 'react';

type TResize = {
  width: number;
  height: number;
};
const useResize = () => {
  const [size, setSize] = useState<TResize>({ height: 0, width: 0 });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

export default useResize;
