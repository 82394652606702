import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  password: Yup.string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\\/^&*])(?=.{8,})/,
      'Password must contain a minimum of 8 characters and at least one uppercase letter, one lowercase letter, one number and one symbol.',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'The password does not match')
    .required('Required'),
  referralCode: Yup.string(),
  stateID: Yup.string().required('Required'),
  gradeID: Yup.string().required('Required'),
  schoolName: Yup.string().required('Required'),
  licenseKey: Yup.string(),
});
