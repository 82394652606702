import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import { ReactComponent as SchoolABC } from '../../../../Assets/school-abc.svg';
import { OtherSidebarConfig } from '../../SidebarConfig';
import { AppDispatch, RootState } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getExternalUrls } from '../../../../redux/slices/resourcesSlices';
import { ExternalUrlIdentifier } from '../../types';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockIcon from '@mui/icons-material/Lock';
import { PremiumDialog } from '../../../../LibraryComponents/PremiumDialog';
import { routeIDs } from '../../../../Routes/RouteIds.routes';

export const DrawerComponent = ({
  toggleDrawer,
  setTypePdf,
  setShowPDFViewer,
}: {
  toggleDrawer: (isOpen: false) => void;
  setTypePdf: React.Dispatch<React.SetStateAction<string>>;
  setShowPDFViewer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const externalUrls = useSelector(
    (state: RootState) => state.resources.externalUrls,
  );
  const { userIsPremium } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);

  const debouncedGetExternalUrls = debounce(
    () => dispatch(getExternalUrls()),
    500,
  );

  useEffect(() => {
    if (externalUrls.length === 0) {
      debouncedGetExternalUrls();
    }
  }, []);

  const handleExternalUrl = (urlId: ExternalUrlIdentifier) => {
    const foundUrl = externalUrls.find((url) => url.urlId === urlId);
    return foundUrl?.url ?? '';
  };

  return (
    <Box
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
      width={'100%'}
    >
      <PremiumDialog
        open={openPremiumDialog}
        handleClose={() => setOpenPremiumDialog(false)}
      />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ pr: 0 }}>
            <Box
              sx={{
                display: 'flex',
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
              }}
              component={Link}
              to={routeIDs.EDUCATOR_HOME}
            >
              <ListItemIcon sx={{ alignSelf: 'center' }}>
                <HomeIcon sx={{ color: '#003366' }} />
              </ListItemIcon>
              <ListItemText
                primary={'Home'}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                }}
              />
            </Box>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ pr: 0 }}
            onClick={() => {
              if (!userIsPremium) {
                return setOpenPremiumDialog(true);
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
              }}
              data-testid="Classrooms"
              component={!userIsPremium ? Box : Link}
              to={'/educator/classrooms'}
            >
              <ListItemIcon sx={{ alignSelf: 'center' }}>
                <ListAltIcon sx={{ color: '#003366' }} />
              </ListItemIcon>
              <ListItemText
                primary={'Classrooms'}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                }}
              />
              {!userIsPremium && (
                <ListItemIcon sx={{ alignSelf: 'center' }}>
                  <LockIcon sx={{ fill: '#000', padding: '0 15px' }} />
                </ListItemIcon>
              )}
            </Box>
          </ListItemButton>
        </ListItem>
        <Divider />
        {OtherSidebarConfig.map((config) => (
          <ListItem
            data-testid="menu-platform-instructions"
            key={config.label}
            disablePadding
          >
            <ListItemButton
              sx={{ pr: 0 }}
              data-testid={config.label}
              onClick={() => {
                if (!userIsPremium && config.isPremium) {
                  return setOpenPremiumDialog(true);
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%',
                }}
                component={!userIsPremium && config.isPremium ? Box : Link}
                to={handleExternalUrl(config.urlId as ExternalUrlIdentifier)}
              >
                <ListItemIcon sx={{ alignSelf: 'center' }}>
                  {config.icon}
                </ListItemIcon>
                <ListItemText
                  primary={config.label}
                  primaryTypographyProps={{
                    fontWeight: 'medium',
                  }}
                />
                {!userIsPremium && config.isPremium && (
                  <ListItemIcon sx={{ alignSelf: 'center' }}>
                    <LockIcon sx={{ fill: '#000', padding: '0 15px' }} />
                  </ListItemIcon>
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
