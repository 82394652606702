import React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  FormControl,
  Typography,
  Box,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import lemmyLogo from '../../Assets/Lemmy_Base.png';
import { RootState, useAppDispatch } from '../../redux/store';
import {
  cancelStripeSubscription,
  teacherRefreshLogin,
} from '../../redux/slices/teacherSlice';
import { Toast } from '../Toast';
import { ICancelSubscriptionProps } from './types';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

export const CancelSubscription = ({
  handleClose,
  closeToast,
  setOpenToast,
  openToast,
}: ICancelSubscriptionProps) => {
  const { userId, token, role, accessToken } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useAppDispatch();

  const handleCancelSubscription = (values: any) => {
    const { reason, details } = values;
    const currentToken = role === 'teacher' ? accessToken : token;
    dispatch(
      cancelStripeSubscription({
        teacherId: userId,
        token: currentToken,
        reason: reason,
        details: details,
      }),
    )
      .then(() => {
        setOpenToast({
          open: true,
          msg: 'Subscription successfully canceled',
          severity: 'success',
        });
        handleClose();
      })
      .catch((error: any) => {
        setOpenToast({
          open: true,
          msg: 'Error canceling subscription',
          severity: 'error',
        });
        console.error(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      reason: '',
      details: '',
    },
    onSubmit: (values) => {
      handleCancelSubscription(values);
      formik.resetForm();
    },
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#003366',
          position: 'relative',
          minHeight: '140px',
          borderRadius: '10px 10px 0 0 ',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
          }}
          gutterBottom
        >
          Cancel Subscription
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '90px',
          }}
        >
          <Avatar sx={{ width: '80px', height: 'min-content' }}>
            <img
              src={lemmyLogo}
              alt="educator-logo"
              width={'60px'}
              height={'100%'}
            />
          </Avatar>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Toast
          isOpen={openToast.open}
          severity={openToast.severity}
          onClose={() => closeToast(true)}
          styles={{
            width: { xs: '86%', sm: '100%' },
            textAlign: 'center',
            top: 40,
          }}
          errorMessage={openToast.msg}
        />
      </Box>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component={'form'}
          onSubmit={formik.handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: { xs: '300px', md: '500px' },
          }}
        >
          <Typography variant="h6" marginBottom={'2rem'}>
            We're sad to see you go! In order to better serve you, please tell
            us the main reason you're canceling:
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="I'm no longer a teacher"
                control={<Radio />}
                label="Leaving teaching"
              />
              <FormControlLabel
                value="I switched to a course that is not supported"
                control={<Radio />}
                label="My course is not supported"
              />
              <FormControlLabel
                value="There are not enough materials for my course"
                control={<Radio />}
                label="Not enough premium materials for my course"
              />
              <FormControlLabel
                value="Premium is too expensive"
                control={<Radio />}
                label="It's too expensive"
              />
              <FormControlLabel
                value="The premium materials are not high enough quality"
                control={<Radio />}
                label="The premium materials are not high enough quality"
              />
              <FormControlLabel
                value="The app is too confusing / hard to use"
                control={<Radio />}
                label="The app is too confusing / hard to use"
              />
              <FormControlLabel
                value="I’m only using the free materials"
                control={<Radio />}
                label="I’m only using the free materials"
              />
              <FormControlLabel
                value="Missing Features"
                control={<Radio />}
                label="Missing features I need (please explain below)"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other (please explain below)"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            name="details"
            label="Do you have any other details to share?"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formik.values.details}
            onChange={formik.handleChange}
          />
          <FormControl
            fullWidth
            sx={{
              m: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
            variant="outlined"
          >
            <Button
              variant="outlined"
              sx={{
                mt: 3,
                mb: 2,
                mr: '20px',
                fontSize: '16px',
                borderColor: '#003366',
                color: '#003366',
                fontWeight: 700,
              }}
              onClick={handleClose}
              data-testid="undo-cancel-subscription"
              startIcon={<UndoIcon />}
            >
              Undo
            </Button>
            <Button
              type="submit"
              variant="contained"
              data-testid="submit-cancel-subscription"
              sx={{
                mt: 3,
                mb: 2,
                fontSize: '16px',
                minWidth: '97px',
                background: '#003366',
                fontWeight: 700,
              }}
              endIcon={<SaveIcon />}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
