import { Box, Card, CardContent, SxProps, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { PremiumDialog } from '../../../../LibraryComponents/PremiumDialog';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LemmyTooltip } from '../../../../LibraryComponents/LemmyTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { WorkingDialog } from '../../../../LibraryComponents/WorkingDialog';

export interface ITileCardProps {
  url: string;
  label: string;
  isLink?: boolean;
  children?: ReactNode;
  target?: string;
  styles?: SxProps;
  isPremium?: boolean;
  tileToolTipsMsg?: string;
  subText?: string;
  onClick?: () => void;
  labelStyles?: SxProps;
}

export const TileCard = ({
  url,
  label,
  isLink = false,
  children,
  target,
  styles,
  isPremium,
  tileToolTipsMsg,
  subText,
  onClick,
  labelStyles,
}: ITileCardProps) => {
  const { userIsPremium } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [openWorkingDialog, setOpenWorkingDialog] = useState(false);

  const handleClick = (url: string) => {
    if (onClick) onClick();
    if (!userIsPremium && isPremium) {
      return setOpenPremiumDialog(true);
    }
    if (!url || url === 'null') {
      return setOpenWorkingDialog(true);
    }
    if (!isLink) {
      navigate(url);
    } else if (isPremium && userIsPremium) {
      window.open(url, '_blank');
    } else if (isPremium && !userIsPremium) {
      setOpenPremiumDialog(true);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <PremiumDialog
        open={openPremiumDialog}
        handleClose={() => setOpenPremiumDialog(false)}
      />
      <WorkingDialog
        open={openWorkingDialog}
        handleClose={() => setOpenWorkingDialog(false)}
        msgBeforeContactUs="Oops, we're still working on this feature, please"
        mgsAfterContactUs="for help."
      />
      <Card
        sx={{
          maxHeight: 180,
          maxWidth: 220,
          height: 180,
          width: 220,
          px: 3,
          py: 2,
          borderRadius: 5,
          cursor: 'pointer',
          margin: '10px',
          background: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          textDecoration: 'none',
          m: 'auto',
          position: 'relative',
          ...styles,
        }}
        onClick={() => handleClick(url)}
      >
        {tileToolTipsMsg && (
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <LemmyTooltip
              tooltipText={tileToolTipsMsg}
              tooltipTrigger={
                <InfoIcon sx={{ fontSize: 24, color: '#003366' }} />
              }
              tooltipPlacement="top"
            />
          </Box>
        )}
        <CardContent
          data-testid="card-content"
          sx={{
            width: '100%',
            cursor: 'pointer',
            margin: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {children}
          <Typography
            variant="h6"
            sx={{
              color: '#003366',
              fontSize: '1.1rem',
              marginTop: '.5rem',
              ...labelStyles,
            }}
          >
            {label}
          </Typography>
          <Typography variant="body1" sx={{ color: '#003366' }}>
            {subText}
          </Typography>
        </CardContent>
        {isPremium && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              width: '100%',
              alignItems: 'center',
              position: 'absolute',
              bottom: '5%',
              left: '10px',
              height: '10px',
            }}
          >
            {!userIsPremium && (
              <LockIcon sx={{ color: '#OOO', pl: '15px' }} fontSize="large" />
            )}
            <Typography
              sx={{
                color: !userIsPremium ? '#FF5252' : '#4CAF50',
                maxWidth: '260px',
                fontSize: '16px',
                pl: '15px',
              }}
            >
              Premium Feature
            </Typography>
          </Box>
        )}
      </Card>
    </>
  );
};
