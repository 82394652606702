import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IAnswerOption } from '../../types';
import {
  hasHTMLTags,
  sanitizeHTML,
} from '../../../Student/components/LessonProgress/LessonProgressHelpers';

export interface ISentenceCompletionProps {
  questionText?: string;
  questionExplanation?: string;
  answerOptions?: IAnswerOption[];
  multiValue: string[];
  submitting: boolean;
  setMultiValue: React.Dispatch<React.SetStateAction<string[]>>;
  answerPlaces: any;
  isCompleted: boolean;
  answer: any;
}

interface AnswerPlace {
  answerOptions: IAnswerOption[];
}

export const SentenceCompletion: React.FC<ISentenceCompletionProps> = ({
  questionText,
  questionExplanation,
  multiValue,
  submitting,
  setMultiValue,
  answerPlaces,
  isCompleted,
  answer,
}) => {
  const [colors, setColors] = useState<string[]>([]);

  const orderAnswer = answerPlaces && sortBySortOrder(answerPlaces);
  const keyIndex = answerPlaces?.answerPlaceId || 0;

  useEffect(() => {
    const currentColors =
      answerPlaces?.map((answerPlace: AnswerPlace, index: number) => {
        if (submitting || isCompleted) {
          const selectedOption = answerPlace?.answerOptions.find(
            (opt: IAnswerOption) => opt.answerId === multiValue[index],
          );

          return selectedOption?.isCorrect ? 'green' : '#be1f4f';
        }
        return '#003366';
      }) || [];

    setColors(currentColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, isCompleted, answer, multiValue]);

  const handleDropdownChange = (index: number, value: string) => {
    const newValues = [...multiValue];
    newValues[index] = value;
    setMultiValue(newValues);
  };
  const orderAnswerPlaces = answerPlaces[0].sortOrder || 0;
  useEffect(() => {
    if (orderAnswerPlaces === 1 && multiValue.length > 0 && isCompleted) {
      multiValue.reverse();
    }
  }, [multiValue]);

  return (
    <div>
      <Box data-testid="sentence-completion" sx={{ width: '100%', mb: '20px' }}>
        <Box key={keyIndex}>
          {questionText && hasHTMLTags(questionText) ? (
            <Box
              sx={{ img: { width: '100%' } }}
              dangerouslySetInnerHTML={sanitizeHTML(questionText ?? '')}
            />
          ) : (
            <Typography
              variant="h5"
              color={'#003366'}
              sx={{ marginBottom: '30px' }}
            >
              {questionText?.split(/%\d+%/g).map((part, index) => {
                if (index === orderAnswer.length) {
                  return part;
                }
                return (
                  <>
                    {part}
                    <select
                      key={index}
                      name={`select-${index}`}
                      id={`select-${index}`}
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: 600,
                        padding: '5px 10px',
                        color: colors[answerPlaces[index]?.sortOrder],
                        border: `1px solid ${
                          colors[answerPlaces[index]?.sortOrder]
                        }`,
                      }}
                      value={multiValue[index] || ''}
                      disabled={isCompleted || submitting}
                      onChange={(e) =>
                        handleDropdownChange(index, e.target.value)
                      }
                    >
                      <option value=" ">--select one--</option>
                      {orderAnswer[index]?.answerOptions.map(
                        (option: IAnswerOption) => (
                          <option key={option.answerId} value={option.answerId}>
                            {option.answerText}
                          </option>
                        ),
                      )}
                    </select>
                  </>
                );
              })}
            </Typography>
          )}
        </Box>
        {(submitting || isCompleted) && (
          <Box>
            <Typography
              variant="h5"
              color={'#003366'}
              textAlign={'center'}
              fontWeight={600}
              data-testid="explanation-test"
            >
              Explanation
            </Typography>
            <Typography
              sx={{ marginBottom: '10px', color: '#003366', fontWeight: '600' }}
            >
              {questionExplanation}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

function sortBySortOrder<T extends { sortOrder: number }>(data: T[]): T[] {
  return data.slice().sort((a, b) => a.sortOrder - b.sortOrder);
}
