// src/store.ts
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import authReducer from './slices/authSlice';
import teacherReducer from './slices/teacherSlice'; // Add this line
import { useDispatch } from 'react-redux';
import studentReducer from './slices/studentSlice';
import lessonReducer from './slices/lessonsSlice';
import quizReducer from './slices/quiz';
import classroom from './slices/classroomSlice';
import resourcesReducer from './slices/resourcesSlices';
import guestReducer from './slices/guestSlices';

function createPersistConfig(key: string) {
  return {
    key,
    storage,
  };
}

const persistConfig = createPersistConfig('root');
const persistConfigClassroom = createPersistConfig('classroom');
const persistStudent = createPersistConfig('student');
const persistTeacher = createPersistConfig('teacher');
const persistGuest = createPersistConfig('guest');

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedClassroomReducer = persistReducer(
  persistConfigClassroom,
  classroom,
);
const persistedTeacherReducer = persistReducer(persistTeacher, teacherReducer);
const persistedStudentReducer = persistReducer(persistStudent, studentReducer);
const persistedGuestReducer = persistReducer(persistGuest, guestReducer);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    teacher: persistedTeacherReducer,
    student: persistedStudentReducer,
    lesson: lessonReducer,
    quiz: quizReducer,
    classroom: persistedClassroomReducer,
    resources: resourcesReducer,
    guest: persistedGuestReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);
