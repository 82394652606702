import * as Yup from 'yup';

export const classSchema = Yup.object().shape({
  className: Yup.string()
    .min(2, 'Class name must be at least 2 characters')
    .max(50, 'Class name cannot be greater than 50 characters')
    .required('Required'),
  state: Yup.string().required('Required'),
  grade: Yup.string().required('Required'),
  curriculum: Yup.string().required('Required'),
});
