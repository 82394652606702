import React from 'react';
import { MenuItem, Select, FormHelperText } from '@mui/material';
import { ISelectProps } from './types';

export const SelectForm = ({ name, label, formik, children }: ISelectProps) => {
  return (
    <>
      <Select
        fullWidth
        margin="dense"
        data-testid={name}
        displayEmpty
        id={name}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
      >
        <MenuItem disabled value="" sx={{ color: 'gray' }}>
          {label}
        </MenuItem>
        {children}
      </Select>
      {formik.touched[name] && Boolean(formik.errors[name]) && (
        <FormHelperText error id={`${name}-helper`}>
          {formik.errors[name]}
        </FormHelperText>
      )}
    </>
  );
};
