import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import { Avatar, Button } from '@mui/material';
import { LemmyTooltip } from '../../../LibraryComponents/LemmyTooltip/LemmyTooltip';
import Modal from '../../../LibraryComponents/Modal/Modal';
import { CancelSaveButtons } from '../../../LibraryComponents/CancelSaveButtons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  editStudentClassroom,
  resetStudentPassword,
} from '../../../redux/slices/classroomSlice';
import { Toast } from '../../../LibraryComponents/Toast';
import { TeacherData } from '../../../redux/types/teacherTypes';
import { studentSchema } from './schema';
import { IEditStudentProps, IToast } from './types';
import PersonIcon from '@mui/icons-material/Person';

export const EditStudent = ({
  onClose,
  student,
  classroomId,
}: IEditStudentProps) => {
  const teacherData: TeacherData | null = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );
  const token: string | null = useSelector(
    (state: RootState) => state.auth.accessToken,
  );
  const { accessToken, userId } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState({
    isOpen: false,
    title: '',
    text: '',
  });
  const [openModalError, setOpenModalError] = useState<IToast>({
    open: false,
    msg: '',
  });

  const handleResetPassword = () => {
    setShowConfirmationDialog({
      isOpen: true,
      title: 'Reset Student Password',
      text: `This will reset ${student.firstName} ${student.lastName}'s password. The next time they login, they will need to create a new password.`,
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: student.firstName || '',
      lastName: student.lastName || '',
      username: student.studentId || '',
    },
    validationSchema: studentSchema,
    onSubmit: (values) => {
      const { firstName, lastName } = values;
      dispatch(
        editStudentClassroom({
          teacherId: teacherData?.id ?? '',
          classroomId: classroomId,
          studentId: student.studentId,
          token,
          firstName,
          lastName,
        }),
      )
        .then(() => {
          handleCloseModal();
          window.location.href = `/educator/classroomDashboard/${teacherData?.id}/${classroomId}`;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });
  const handleCloseModal = () => {
    setShowConfirmationDialog((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleResetStudentPassword = () => {
    dispatch(
      resetStudentPassword({
        studentId: student.studentId,
        teacherId: userId,
        classroomId,
        token: accessToken,
      }),
    )
      .then()
      .catch((err) => setOpenModalError({ open: true, msg: err.message }));
    handleCloseModal();
  };

  return (
    <Box sx={{ margin: 'auto', width: { md: '600px', sm: '100%' } }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Toast
          isOpen={openModalError.open}
          severity="error"
          onClose={() =>
            setOpenModalError((prevState) => ({ ...prevState, open: false }))
          }
          styles={{ width: '400px', textAlign: 'center', top: '5px' }}
          errorMessage={openModalError.msg}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#003366',
          position: 'relative',
          minHeight: '140px',
          marginBottom: '60px',
          borderRadius: '10px 10px 0 0 ',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1.5,
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
          }}
          gutterBottom
        >
          Edit Student
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '90px',
          }}
        >
          <Avatar sx={{ width: '80px', height: 'min-content' }}>
            <PersonIcon
              fontSize="large"
              sx={{ width: '80px', height: '80px' }}
            />
          </Avatar>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              color: '#003366',
            }}
            gutterBottom
          >
            {' '}
            {student.userName}
          </Typography>
        </Box>
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={2}
            sx={{ margin: 1, maxWidth: '100%', width: 'auto' }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                id="firstName"
                name="firstName"
                label="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                id="lastName"
                name="lastName"
                label="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              ></TextField>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign={'end'}>
                <Button
                  sx={{ color: 'violet' }}
                  variant="text"
                  onClick={handleResetPassword}
                >
                  Reset Student's Password
                  <LemmyTooltip
                    tooltipText={`Click this button to reset your student’s password. They will be prompted to update their password the next time they login`}
                    tooltipTrigger={
                      <InfoIcon sx={{ fontSize: 15, color: '#003366' }} />
                    }
                    tooltipPlacement="right"
                  />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'end' }}
              >
                <Button
                  sx={{ color: '#003366', width: '8rem', mr: 2 }}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ background: '#003366', width: '8rem' }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Modal
              isOpen={showConfirmationDialog.isOpen}
              onClose={handleCloseModal}
              showCloseButton={false}
              maxWidth={400}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  borderRadius: '0.5rem',
                  minHeight: '20vh',
                  margin: 'auto',
                  textAlign: 'center',
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ mt: 1.5, fontWeight: '600' }}
                    gutterBottom
                  >
                    {showConfirmationDialog.title}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {showConfirmationDialog.text}
                  </Typography>
                </Box>
                <CancelSaveButtons
                  onCancel={handleCloseModal}
                  onSave={handleResetStudentPassword}
                  styles={{
                    justifyContent: 'center',
                    marginBottom: '1rem',
                  }}
                />
              </Box>
            </Modal>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
