import { Box, Button, Link, Typography } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getCurriculums } from '../../../redux/slices/resourcesSlices';
import { AppDispatch, RootState } from '../../../redux/store';
import { ICurriculums, IResource } from '../../../redux/types/resourcesTypes';
import { debounce } from 'lodash';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { useGTM } from '../../../hooks/useGTM';
import { ListResourcesEmpty } from '../components/ListResourcesEmpty';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ICommonResourcesProps } from './types';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import useDataCleanup from '../../../hooks/useDataCleanup';

export const CommonResources = ({
  curriculumId,
  tableData,
  getResourceAction,
  resourceTypeLabel,
  columnsData,
  label,
}: ICommonResourcesProps) => {
  const { curriculums } = useSelector((state: RootState) => state.resources);

  const { pageView } = useGTM();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { removeData } = useDataCleanup();

  const [isLoading, setIsLoading] = useState(true);

  const debouncedGetResources = debounce(
    () =>
      dispatch(getResourceAction({ curriculumId }))
        .then((res: any) => {
          if (res?.payload?.status === 401) {
            removeData();
            navigate(routeIDs.LOGIN);
          }
          setIsLoading(false);
        })
        .catch((error: any) => {
          removeData();
          navigate(routeIDs.LOGIN);
          setIsLoading(false);
          console.error(error);
        }),
    500,
  );
  const debouncedGetCurriculums = debounce(
    () =>
      dispatch(getCurriculums())
        .then()
        .catch((error) => {
          removeData();
          navigate(routeIDs.LOGIN);
          console.error(error);
        }),
    500,
  );

  useEffect(() => {
    if (curriculumId && curriculums?.length === 0) {
      debouncedGetCurriculums();
    }
  }, [curriculumId]);

  const curriculumFiltered = curriculums?.find(
    (curriculum: ICurriculums) => curriculum.id === curriculumId,
  );

  const [hideAddressableGrades, setHideAddressableGrades] = useState(false);
  useEffect(() => {
    pageView(`/educator/${resourceTypeLabel}`);
    debouncedGetResources();
  }, [curriculumId]);

  const viewActivity = (titleActivity: string, url: string): JSX.Element => (
    <Link
      href={url}
      sx={{ fontWeight: 600, fontSize: '16px', textDecoration: 'none' }}
    >
      {titleActivity}
    </Link>
  );

  const columns = useMemo<MRT_ColumnDef<IResource | any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        size: 150,
        Cell: ({ cell }: any) => {
          const titleActivity = cell?.row?.original?.title ?? '';
          const url = cell?.row?.original?.url ?? '';
          return viewActivity(titleActivity, url);
        },
      },
      {
        accessorKey: 'addressableGrades',
        header: 'Target Age Range',
        enableColumnActions: false,
        muiTableBodyCellProps: {
          sx: { fontSize: '16px' },
        },
        size: 100,
      },
      {
        accessorKey: 'topicsCovered',
        header: 'Topic(s) Covered',
        enableColumnActions: false,
        muiTableBodyCellProps: {
          sx: { fontSize: '16px' },
        },
        size: 100,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        enableColumnActions: false,
        muiTableBodyCellProps: {
          sx: { fontSize: '16px' },
        },
        size: 300,
        Cell: ({ cell }: any) => {
          const value = cell?.row?.original?.description ?? '';
          return (
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
            />
          );
        },
      },
      ...(columnsData ? columnsData : []),
    ],
    [],
  );

  useEffect(() => {
    setHideAddressableGrades(!curriculumId);
  }, [curriculumId]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      data-testid={label}
      marginTop={'40px'}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '90%',
          m: '0 0 40px 0 ',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {curriculumId && (
            <Button
              sx={{
                color: '#003366',
                fontWeight: '600',
                flex: '0 0 auto',
              }}
              onClick={() => navigate(-1)}
              startIcon={
                <ArrowBackIcon
                  sx={{
                    fill: '#003366',
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                  }}
                />
              }
            >
              Back
            </Button>
          )}
          <Typography
            variant="h5"
            color="#7F7F7F"
            fontWeight={600}
            sx={{
              flexGrow: 1,
              textAlign: 'center',
            }}
          >
            {label} {curriculumId && `for ${curriculumFiltered?.name}`}
          </Typography>
        </Box>
      </Box>
      <PageContainer
        styles={{
          minHeight: 'auto',
          width: '90%',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
        }}
      >
        {tableData.length > 0 ? (
          <MaterialReactTable
            key={
              hideAddressableGrades
                ? 'hideAddressableGrades'
                : 'showAddressableGrades'
            }
            columns={columns}
            data={tableData}
            enablePinning
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: '#DCDEE0',
                fontSize: '16px',
              },
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                let url = row?.original?.resourceURL;
                if (url && !url.match(/^https?:\/\//i)) {
                  url = `https://${url}`;
                }
                if (url) {
                  window.open(url, '_blank', 'noopener,noreferrer');
                }
              },
              sx: {
                cursor: 'pointer',
              },
            })}
            initialState={{
              density: 'comfortable',
              pagination: { pageIndex: 0, pageSize: 50 },
              sorting: [
                {
                  id: 'title',
                  desc: false,
                },
              ],
              columnVisibility: {
                addressableGrades: hideAddressableGrades,
              },
            }}
          />
        ) : (
          <ListResourcesEmpty label={label} />
        )}
      </PageContainer>
    </Box>
  );
};
