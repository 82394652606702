import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClassSummary } from './components/ClassSummary';
import { Board } from './components/Board';
import { Content } from './Styled.ClassroomDashboard';
import { debounce } from 'lodash';
import { Spinner } from '../../LibraryComponents/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getClassroomDashboard } from '../../redux/slices/classroomSlice';
import { useGTM } from '../../hooks/useGTM';

export const ClassroomDashboard = () => {
  const { dashboardData, statusResponse, status } = useSelector(
    (state: RootState) => state.classroom,
  );
  const { userId, accessToken } = useSelector((state: RootState) => state.auth);

  const { pageView } = useGTM();
  const { teacherId, classroomId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [classroomLock, setClassroomLock] = useState(false);

  const debouncedGetClassroomDashboard = debounce(() => {
    dispatch(
      getClassroomDashboard({
        teacherId: userId,
        classroomId,
        token: accessToken,
      }),
    )
      .then()
      .catch((err) => console.error(err));
  }, 500);

  const handleClassroomState = (): void => {
    setClassroomLock((prev: boolean) => !prev);
  };

  useEffect(() => {
    pageView(`/educator/classroomDashboard/${userId}/${classroomId}`);
    debouncedGetClassroomDashboard();
  }, [classroomLock]);

  useEffect(() => {
    if (statusResponse === 401) {
      navigate('/');
    }
  }, [statusResponse]);

  if (status === 'loading') return <Spinner />;

  return (
    <Content>
      {dashboardData && (
        <>
          <ClassSummary
            classroomData={dashboardData}
            classroomId={classroomId!}
            teacherId={teacherId!}
            isLocked={dashboardData?.isLocked}
            handleClassroomLState={handleClassroomState}
          />
          <Board
            studentsData={dashboardData.studentsClassProgress}
            classroomId={classroomId!}
            classroomName={dashboardData.classroomName}
          />
        </>
      )}
    </Content>
  );
};
