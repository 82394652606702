import { Box, Typography } from '@mui/material';
import React from 'react';
import { getDateFormat } from '../../ChatUIHelpers/GetDateFormat';
import teacherLemmy from '../../../../Assets/TeacherLemmy.png';

export const DefaultChat = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'start',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography variant="body2" sx={{ color: 'gray', ml: 6 }}>
        {getDateFormat(new Date())}
      </Typography>{' '}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          component={'img'}
          src={teacherLemmy}
          alt="teacher lemmy"
          width={50}
          height={'100%'}
        />{' '}
        <Typography
          variant="body1"
          sx={{
            backgroundColor: '#e0e0e0',
            padding: '10px',
            borderRadius: '8px',
            width: 'auto',
            maxWidth: { xs: '100%', sm: '50%' },
            textAlign: 'start',
          }}
        >
          Hi there! I can help answer common classroom questions. Type your
          question below!
        </Typography>
      </Box>
    </Box>
  );
};
