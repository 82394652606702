import React, { useState } from 'react';
import { Visibility, VisibilityOff, Edit } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { IInputProps } from './types';

export const Input = ({
  name,
  label,
  type,
  formik,
  variant,
  readOnly = false,
  isEditable = false,
  setIsEditable = () => false,
}: IInputProps) => {
  const [showPassword, setShowPassword] = useState(true);
  return (
    <TextField
      fullWidth
      margin="dense"
      type={type === 'password' && showPassword ? 'password' : 'text'}
      id={name}
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]} // Display the validation error message
      InputProps={{
        endAdornment:
          (type === 'password' && (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )) ||
          (isEditable && (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setIsEditable((prev: any) => ({ ...prev, [name]: false }))
                }
              >
                <Edit />
              </IconButton>
            </InputAdornment>
          )),
        readOnly: readOnly,
        autoComplete: name,
      }}
      variant={variant}
    />
  );
};
