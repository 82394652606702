export const groupedData = (messages: any) => {
  return messages.reduce((accumulator: any, currentValue: any) => {
    const {
      responseDateTime,
      messageID,
      requestDateTime,
      requestMessage,
      responseMessage,
    } = currentValue;

    const dateKey = requestDateTime.split('T')[0];

    const existingGroup = accumulator.find(
      (group: any) => group.dateKey === dateKey,
    );

    if (existingGroup) {
      existingGroup.messages.push({
        responseDateTime,
        messageID,
        requestDateTime,
        requestMessage,
        responseMessage,
      });
    } else {
      accumulator.push({
        dateKey,
        messages: [
          {
            responseDateTime,
            messageID,
            requestDateTime,
            requestMessage,
            responseMessage,
          },
        ],
      });
    }

    return accumulator;
  }, []);
};
