import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ICancelSaveButtonsProps } from './types';

export const CancelSaveButtons = ({
  onCancel,
  onSave,
  styles,
  endIconSave,
}: ICancelSaveButtonsProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        marginTop: '5rem',
        ...styles,
      }}
    >
      <Button
        sx={{ color: '#003366', width: '10rem', marginRight: '1rem' }}
        variant="outlined"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        sx={{ background: '#003366', width: '10rem' }}
        variant="contained"
        type="submit"
        onClick={onSave}
        endIcon={endIconSave}
      >
        Confirm
      </Button>
    </Box>
  );
};
