import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { getVideoResources } from '../../../redux/slices/resourcesSlices';
import { useSearchParams } from 'react-router-dom';
import { CommonResources } from '../CommonResources';

export const VideoLibrary = () => {
  const { videoResources } = useSelector((state: RootState) => state.resources);

  const [searchParams] = useSearchParams();
  const curriculumId = searchParams.get('curriculumId') ?? '';

  return (
    <CommonResources
      tableData={videoResources}
      curriculumId={curriculumId}
      getResourceAction={getVideoResources}
      resourceTypeLabel={'videoLibrary'}
      label="Instructional Videos"
    />
  );
};
