import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { handleQuestionStyles } from '../../quizHelpers';
import { AnswerOption } from '../../../../redux/types/lessonsTypes';
import {
  hasHTMLTags,
  sanitizeHTML,
} from '../../../Student/components/LessonProgress/LessonProgressHelpers';

export interface ISingleQuestionProps {
  questionText?: string;
  questionExplanation?: string;
  answerOptions?: AnswerOption[];
  multiValue: string[];
  submitting: boolean;
  disabled?: boolean;
  isCompleted: boolean;
  setMultiValue: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MultiSelect = ({
  questionText,
  questionExplanation,
  answerOptions,
  multiValue,
  submitting,
  disabled,
  isCompleted,
  setMultiValue,
}: ISingleQuestionProps) => {
  const handleClick = (value: string) => {
    if (multiValue.includes(value)) {
      const newValue = multiValue.filter((val) => val !== value);
      return setMultiValue(newValue);
    }
    if (multiValue.length < 2) {
      setMultiValue([...multiValue, value]);
    }
  };

  const sortedAnswerOptions = answerOptions
    ? [...answerOptions].sort((a, b) => {
        if (a.answerText.includes('None of the above')) return 1;
        if (b.answerText.includes('None of the above')) return -1;
        if (a.answerText.includes('All of the above')) return 1;
        if (b.answerText.includes('All of the above')) return -1;
        if (a.answerText.includes('Both of the above')) return 1;
        if (b.answerText.includes('Both of the above')) return -1;
        return 0;
      })
    : [];

  const handleColorCheck = (answerOption: AnswerOption) => {
    const isAnswerCorrect = answerOption.isCorrect;
    const isAnswerSelected = multiValue.includes(answerOption.answerId);

    if (isAnswerSelected) {
      if ((isAnswerCorrect && isCompleted) || (isAnswerCorrect && submitting)) {
        return 'green';
      } else if (
        (!isAnswerCorrect && submitting) ||
        (!isAnswerCorrect && isCompleted)
      ) {
        return 'red';
      }
    }

    return '#003366';
  };

  return (
    <Box data-testid="question-multi" sx={{ width: '100%', mb: '20px' }}>
      <Box sx={{ mb: '20px' }}>
        {questionText && hasHTMLTags(questionText) ? (
          <Box
            sx={{ img: { width: '100%' } }}
            dangerouslySetInnerHTML={sanitizeHTML(questionText ?? '')}
          />
        ) : (
          <Typography
            variant="h5"
            color={'#003366'}
            sx={{ marginBottom: '10px' }}
          >
            {questionText}
          </Typography>
        )}
      </Box>

      <FormControl sx={{ width: '100%' }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {sortedAnswerOptions?.map((option: AnswerOption) => (
            <FormControlLabel
              key={option.answerId}
              value={option.answerText}
              control={
                <Radio
                  checkedIcon={
                    <CheckCircleIcon
                      sx={{
                        fill: handleColorCheck(option),
                        background: '#fff',
                        borderRadius: '50px',
                      }}
                    />
                  }
                  disabled={disabled}
                  checked={multiValue.includes(option.answerId)}
                  onClick={() => handleClick(option.answerId)}
                />
              }
              label={option.answerText}
              sx={{
                background: handleQuestionStyles(
                  option,
                  multiValue,
                  submitting,
                  isCompleted,
                )?.bg,
                color: handleQuestionStyles(
                  option,
                  multiValue,
                  submitting,
                  isCompleted,
                )?.color,
                width: '90%',
                borderRadius: '20px',
                marginBottom: '5px',
                '.MuiFormControlLabel-label.Mui-disabled ': { color: '#000' },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {(submitting || isCompleted) && (
        <Box sx={{ mt: '10px' }}>
          <Typography
            variant="h5"
            color={'#003366'}
            textAlign={'center'}
            fontWeight={600}
            data-testid="explanation-test"
          >
            Explanation
          </Typography>
          <Typography
            sx={{ marginBottom: '10px', color: '#003366', fontWeight: '600' }}
          >
            {questionExplanation}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
