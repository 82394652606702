import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  Box,
  Typography,
  Avatar,
  Grid,
} from '@mui/material';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import studentLogo from '../../../Assets/Lemmy_Math.png';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { initialValues, signUpSchema } from './StudentCreateAccountHelpers';
import { RootState, useAppDispatch } from '../../../redux/store';
import { registerStudent } from '../../../redux/slices/studentSlice';
import { Toast } from '../../../LibraryComponents/Toast';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { routeIDs } from '../../../Routes/RouteIds.routes';
import { useGTM } from '../../../hooks/useGTM';
import HowToRegIcon from '@mui/icons-material/HowToReg';

export const StudentCreateAccount = () => {
  const { pageView } = useGTM();
  const { status } = useSelector((state: RootState) => state.student);
  const { userId, role: userRole } = useSelector(
    (state: RootState) => state.auth,
  );

  const [searchParams] = useSearchParams();
  const classroomCode = searchParams.get('code') ?? '';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    pageView('/student/createStudentAccount');
    if (userId) {
      navigate('/student');
    }
  }, [navigate, userId]);

  const formik = useFormik({
    initialValues: initialValues(classroomCode) || {},
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerStudent(values))
        .then((res: any) => {
          if (res.error) {
            setOpen(true);
            return setErrorMessage(
              res.payload.message.replace(/['"]+/g, '') ||
                'Oops, there was a problem creating your account. Please try again, or contact us if this error persists.',
            );
          }
          navigate(`${routeIDs.THANK_YOU_SCREEN}?lastPath=student`);
        })
        .catch((error) => {
          if (error) {
            setOpen(true);
            setErrorMessage(
              error.message ||
                'Oops, there was a problem creating your account. Please try again, or contact us if this error persists.',
            );
          }
        });
    },
  });

  const closeToast = (isOpen: boolean) => {
    isOpen ? setOpen(false) : setOpen(true);
    setErrorMessage(null);
  };

  const handleCreateAccountStudent = () => {
    navigate(`${routeIDs.CREATE_ACCOUNT}?role=teacher`);
  };

  useEffect(() => {
    if (userRole === 'student' && userId) {
      navigate(routeIDs.STUDENT);
    } else if (userRole === 'teacher' && userId) {
      navigate('/');
    }
  }, [dispatch, navigate, userRole]);

  return (
    <Box sx={{ margin: 2, minHeight: '100vh' }}>
      {status === 'loading' && <Spinner />}
      <PageContainer
        styles={{
          maxWidth: '500px',
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '10px',
          mt: 4,
          mb: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#003366',
              width: '100%',
              borderRadius: '10px 10px 0 0',
              minHeight: '140px',
              position: 'relative',
              mb: '20px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                fontWeight: '600',
                fontSize: { xs: '1.5rem', sm: '2.125rem' },
                color: '#fff',
              }}
              gutterBottom
            >
              Create Student Account
            </Typography>
            <Box sx={{ m: 'auto', position: 'absolute', top: '90px' }}>
              <Avatar
                sx={{
                  m: 'auto',
                  width: 80,
                  height: 80,
                }}
              >
                <Box sx={{ marginLeft: '6px', marginTop: '5px' }}>
                  <img
                    src={studentLogo}
                    alt="educator-logo"
                    width={120}
                    height={120}
                  />
                </Box>
              </Avatar>
              <Typography
                component={Link}
                variant="body2"
                color={'#9C27B0'}
                sx={{ textDecoration: 'none' }}
                to={`${routeIDs.CREATE_ACCOUNT}?role=teacher`}
                onClick={handleCreateAccountStudent}
              >
                Click here to create a teacher account instead
              </Typography>
            </Box>
          </Box>
          <Box
            component={'form'}
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '300px', md: '400px' },
              marginTop: '40px',
            }}
          >
            <FormsControl
              control="input"
              name="classroomCode"
              label="Class Code"
              formik={formik}
            />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FormsControl
                  control="input"
                  name="firstName"
                  label="First Name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormsControl
                  control="input"
                  name="lastName"
                  label="Last Name"
                  formik={formik}
                />
              </Grid>
            </Grid>
            <FormsControl
              control="input"
              name="userName"
              label="Username"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="password"
              name="password"
              label="Password"
              formik={formik}
            />
            <FormsControl
              control="input"
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              formik={formik}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Toast
                isOpen={open}
                severity="error"
                onClose={() => closeToast(true)}
                styles={{ width: '400px', textAlign: 'center', top: '10px' }}
                errorMessage={errorMessage}
              />
            </Box>
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: '16px',
                  background: '#003366',
                  fontWeight: 700,
                }}
                endIcon={<HowToRegIcon sx={{ fontSize: '24px' }} />}
              >
                Register
              </Button>
            </FormControl>
            <Typography variant="body2" mb={3}>
              Already have an account?{' '}
              <Typography
                component={Link}
                to={`${routeIDs.LOGIN}?role=student`}
                variant="body2"
                sx={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Sign in instead
              </Typography>
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    </Box>
  );
};
