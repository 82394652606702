import React, { ChangeEvent, useState } from 'react';
import { Box, Button, TextField, Typography, Dialog } from '@mui/material';
import { IDeleteConfirmationProps } from './types';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';

export const DeleteConfirmationDialog = ({
  dialogTitle,
  confirmationText,
  actionButtonLabel,
  handleDelete,
  handleCloseModal,
  open,
  mode = 'normal',
}: IDeleteConfirmationProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const validationString = mode === 'confirmation' ? 'DELETE' : '';

  return (
    <Dialog sx={{ zIndex: '999999' }} open={open} onClose={handleCloseModal}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          bgcolor: '#003366',
          width: '99%',
          textAlign: 'center',
          color: '#fff',
          p: '20px 0',
          m: '3px auto',
          borderRadius: '5px 5px 0 0',
        }}
      >
        {dialogTitle}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '20px',
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{
            marginBottom: '20px',
            fontSize: '16px',
            fontWeight: 500,
            color: '#003366',
          }}
        >
          {confirmationText}
        </Typography>
        {mode === 'confirmation' && (
          <>
            <Typography
              sx={{
                marginBottom: '10px',
                fontSize: '16px',
                fontWeight: 500,
                width: '100%',
                color: '#003366',
              }}
            >
              Type "{validationString}" to confirm
            </Typography>

            <TextField
              data-testid="input-confirm"
              fullWidth
              name="confirmation"
              onChange={handleChange}
            />
          </>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginTop: '40px',
          }}
        >
          <Button
            variant="outlined"
            data-testid="cancel-delete-confirm-dialog"
            sx={{
              color: '#003366',
              fontWeight: 'bold',
              borderColor: '#003366',
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            data-testid="delete-account"
            variant="contained"
            color="error"
            sx={{
              border: 'none',
              color: '#fff',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            disabled={
              mode === 'confirmation' ? inputValue !== validationString : false
            }
            onClick={handleDelete}
            endIcon={<FolderDeleteIcon />}
          >
            {actionButtonLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
