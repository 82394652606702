import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import lemmy from '../../Assets/Lemmy_Base.png';
import { Backdrop, Box, Typography } from '@mui/material';
import { ISpinnerProps } from './types';
import { GlobalStyle } from './styled';

export const Spinner = ({ isCircularProgress = true }: ISpinnerProps) => {
  return (
    <Backdrop
      open={true}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99999 }}
    >
      {isCircularProgress ? (
        <CircularProgress color="inherit" />
      ) : (
        <GlobalStyle
          sx={{
            position: 'relative',
            animation: '$pulse 2s infinite',
          }}
        >
          <Box sx={{ position: 'relative', animation: 'pulse 2s infinite' }}>
            <img src={lemmy} alt="Lemmy" width={80} />
            <Typography
              variant="body1"
              sx={{
                position: 'absolute',
                bottom: '85%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                textAlign: 'center',
                backgroundColor: '#000',
                width: 'max-content',
                p: '5px',
                borderRadius: '10px',
              }}
            >
              Lemmy is thinking....
            </Typography>
          </Box>
        </GlobalStyle>
      )}
    </Backdrop>
  );
};
