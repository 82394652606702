import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { TeacherCreateAccount } from '../Teacher/TeacherCreateAccount';
import { StudentCreateAccount } from '../Student/StudentCreateAccount';

export const CreateAccount = () => {
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) {
      navigate(`${routeIDs.ROLE_SELECTION_SCREEN}?isCreateAccount=true`);
    }
  }, [role]);

  const renderLoginComponent = () => {
    if (role === 'student') {
      return <StudentCreateAccount />;
    } else if (role === 'teacher') {
      return <TeacherCreateAccount />;
    }
    return null;
  };

  return <>{renderLoginComponent()}</>;
};
