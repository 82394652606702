import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Sidebar } from './GlobalComponents/Sidebar/Sidebar';
import { GlobalErrorPage } from './GlobalComponents/GlobalErrorPage/GlobalErrorPage';
import { Routes } from './Routes/Routes';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import CacheBuster from 'react-cache-buster';
import version from '../package.json';
import { Spinner } from './LibraryComponents/Spinner';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Sidebar />,
    errorElement: <GlobalErrorPage />,
    children: Routes,
  },
]);

const isProduction = process.env.REACT_APP_ENV === 'production';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CacheBuster
        currentVersion={version.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Spinner />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router}></RouterProvider>
          </PersistGate>
        </Provider>
      </CacheBuster>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
