import React from 'react';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { Link } from 'react-router-dom';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { FooterContainer, Socials, StFooter } from './styled';

export const Footer = () => {
  return (
    <FooterContainer>
      <Divider light />
      <StFooter>
        <div>Copyright © {dayjs().year()} LemoneyLearning</div>
        <Link
          to={routeIDs.PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        <Link
          to={routeIDs.TERM_AND_CONDITION}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <Socials>
          <a
            href="https://www.linkedin.com/company/lemoney-learning/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon fontSize="small" sx={{ color: '#0072b1' }} />
          </a>
          <a
            href="https://www.instagram.com/lemoneylearning/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon fontSize="small" sx={{ color: '#C13584' }} />
          </a>
          <a
            href="https://lemoneylearning.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RssFeedIcon fontSize="small" sx={{ color: '#fc4f08' }} />
          </a>
        </Socials>
      </StFooter>
      <Divider light />
    </FooterContainer>
  );
};
