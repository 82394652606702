/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';
import { PageContainer } from '../../../LibraryComponents/PageContainer';
import { LemmyTooltip } from '../../../LibraryComponents/LemmyTooltip';
import { CancelSaveButtons } from '../../../LibraryComponents/CancelSaveButtons';
import { AppDispatch, RootState, useAppDispatch } from '../../../redux/store';
import {
  createClassroom,
  saveDataCreateClassroom,
} from '../../../redux/slices/classroomSlice';
import {
  getCurriculums,
  getGrades,
  getStates,
} from '../../../redux/slices/resourcesSlices';
import { Spinner } from '../../../LibraryComponents/Spinner';
import { FormsControl } from '../../../GlobalComponents/Form/FormControl';
import { ICurriculums, IStates } from '../../../redux/types/resourcesTypes';
import _ from 'lodash';
import { TeacherData } from '../../../redux/types/teacherTypes';
import { useGTM } from '../../../hooks/useGTM';
import { classSchema } from './schema';
import SaveIcon from '@mui/icons-material/Save';
import { InputTitle, InputTitleGrid } from './CreateClassroomHelpers/styles';
import { filterStatesByStateIds } from './CreateClassroomHelpers/filterStatesByStateIds';
import { filterCoursesByStateAndGrade } from './CreateClassroomHelpers/filterCoursesByStateAndGrades';

export const CreateClassroom = () => {
  const { accessToken: token, userId: teacherId } = useSelector(
    (state: RootState) => state.auth,
  );
  const teacherData: TeacherData | null = useSelector(
    (state: RootState) => state.teacher.teacherData,
  );
  const { curriculums, states, grades, status } = useSelector(
    (state: RootState) => state.resources,
  );
  const { grade, curriculum } = useSelector(
    (state: RootState) => state.classroom.defaultDataCreateClassroom,
  );

  const { pageView } = useGTM();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const debouncedSave = _.debounce((dispatchFunc) => {
    const actions = [
      { condition: curriculums?.length === 0, action: getCurriculums() },
      { condition: !states, action: getStates() },
      { condition: !grades, action: getGrades() },
    ];

    actions.forEach(({ condition, action }) => {
      if (condition) {
        dispatchFunc(action);
      }
    });
  }, 1000);

  const [curriculumsData, setCurriculumsData] = useState(curriculums);

  const goToClassroomOverview = () => {
    navigate('/educator/classrooms');
  };

  useEffect(() => {
    pageView('/educator/createClassroom');
    debouncedSave(dispatch);
    return () => {
      debouncedSave.cancel();
    };
  }, [dispatch]);

  const filterStates = filterStatesByStateIds(curriculums, states) as IStates[];

  const formik = useFormik({
    initialValues: {
      className: '',
      state:
        filterStates?.length > 0
          ? (filterStates[0]?.stateID as string)
          : teacherData?.stateId,
      grade: grade,
      curriculum: curriculum || '',
    },
    validationSchema: classSchema,
    onSubmit: async (values) => {
      dispatch(
        saveDataCreateClassroom({
          grade: values.grade,
          curriculum: values.curriculum,
        }),
      );
      const { className, state, grade, curriculum } = values;
      try {
        await dispatch(
          createClassroom({
            teacherId,
            token,
            classroomName: className,
            stateId: state,
            gradeId: grade,
            curriculumId: curriculum,
          }),
        )
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              return goToClassroomOverview();
            }
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        console.error('Error creating classroom', error);
      }
    },
  });

  useEffect(() => {
    if (curriculums) {
      setCurriculumsData(
        filterCoursesByStateAndGrade(
          curriculums,
          formik.values.state as string,
          formik.values.grade,
        ).sort((a: ICurriculums, b: ICurriculums) =>
          a.name.localeCompare(b.name),
        ),
      );
    }
  }, [curriculums, formik.values.grade, formik.values.state]);

  useEffect(() => {
    if (curriculumsData?.length === 1) {
      formik.setFieldValue('curriculum', curriculumsData[0].id);
    }
  }, [curriculumsData, curriculums]);

  const isLoading = status === 'loading';

  return (
    <Box sx={{ margin: 2 }}>
      {(!states && !grades && !curriculums) || isLoading ? <Spinner /> : null}
      <PageContainer
        styles={{
          maxWidth: { md: '70%', xs: '100%' },
          boxShadow: '0 4px 8px rgba(0, 51, 102, 0.2)',
          borderRadius: '10px',
          mt: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            bgcolor: '#003366',
            color: '#fff',
            p: '20px 0',
            borderRadius: '10px 10px 0 0',
          }}
          gutterBottom
        >
          Create a New Classroom
        </Typography>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={3}
              sx={{ margin: '16px 32px', maxWidth: '100%', width: 'auto' }}
            >
              <InputTitleGrid item xs={12} sm={6}>
                <Box sx={{ display: 'flex' }}>
                  <InputTitle>Name your class</InputTitle>
                  <LemmyTooltip
                    tooltipText="This will be the name that shows up on your dashboard, so make
                  it something recognizable!"
                    tooltipTrigger={
                      <InfoIcon sx={{ fontSize: 15, color: '#003366' }} />
                    }
                    tooltipPlacement="right"
                  />
                </Box>
              </InputTitleGrid>
              <Grid item xs={12} sm={6}>
                <FormsControl
                  control="input"
                  type="text"
                  name="className"
                  formik={formik}
                  label="Class Name"
                />
              </Grid>
              <InputTitleGrid item xs={12} sm={6}>
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <InputTitle>Select your state</InputTitle>
                    <LemmyTooltip
                      tooltipText="The state you pick impacts the Curriculum options available below!"
                      tooltipTrigger={
                        <InfoIcon sx={{ fontSize: 15, color: '#003366' }} />
                      }
                      tooltipPlacement="right"
                    />
                  </Box>
                  <Typography sx={{ fontSize: '0.90rem', color: '#003366' }}>
                    more states coming soon!
                  </Typography>
                </Box>
              </InputTitleGrid>
              <Grid item xs={12} sm={6}>
                <FormsControl
                  control="select"
                  name="state"
                  formik={formik}
                  label="State"
                  children={filterStates?.map((option) => (
                    <MenuItem
                      key={option?.stateID}
                      value={option?.stateID}
                      sx={{ color: 'gray' }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <InputTitleGrid item xs={12} sm={6}>
                <Box sx={{ display: 'flex' }}>
                  <InputTitle>Select your grade</InputTitle>
                  <LemmyTooltip
                    tooltipText="The grate you pick impacts the curriculum options available below”!"
                    tooltipTrigger={
                      <InfoIcon sx={{ fontSize: 15, color: '#003366' }} />
                    }
                    tooltipPlacement="right"
                  />
                </Box>
              </InputTitleGrid>
              <Grid item xs={12} sm={6}>
                <FormsControl
                  control="select"
                  name="grade"
                  formik={formik}
                  label="Grade"
                  children={grades?.map((option) => (
                    <MenuItem
                      key={option?.gradeID}
                      value={option?.gradeID}
                      sx={{ color: 'gray' }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <InputTitleGrid item xs={12} sm={6}>
                <Box sx={{ display: 'flex' }}>
                  <InputTitle>Select your curriculum</InputTitle>
                  <LemmyTooltip
                    tooltipText="This will be used to determine the content your students see. This is unique for every class you create!"
                    tooltipTrigger={
                      <InfoIcon sx={{ fontSize: 15, color: '#003366' }} />
                    }
                    tooltipPlacement="right"
                  />
                </Box>
              </InputTitleGrid>
              <Grid item xs={12} sm={6}>
                <FormsControl
                  control="select"
                  name="curriculum"
                  formik={formik}
                  label="Curriculum"
                  children={curriculumsData
                    ?.filter((c) => c?.hasStudentContent)
                    .map((option) => (
                      <MenuItem
                        key={option?.id}
                        value={option?.id}
                        sx={{ color: 'gray' }}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                />
              </Grid>
              <CancelSaveButtons
                styles={{
                  '& > *:first-child': {
                    borderColor: '#003366',
                    color: '#00336',
                  },
                  button: {
                    fontWeight: 700,
                    mb: '20px',
                  },
                }}
                onCancel={goToClassroomOverview}
                endIconSave={<SaveIcon />}
              />
            </Grid>
          </form>
        </Box>
      </PageContainer>
    </Box>
  );
};
