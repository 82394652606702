export const updateOrInsertLesson = (
  array: {
    lessonId: string;
    counterLesson: number;
    totalLessons: number;
    completed: boolean;
  }[],
  lessonId: string,
  progressIndexLesson: number,
  indexLesson: number,
  totalLessons: number,
  isNext: boolean,
) => {
  let isCompleted = false;
  if (!array) {
    array = [];
  }
  let found = false;
  const newArray = array?.map(
    (item: {
      lessonId: string;
      counterLesson: number;
      totalLessons: number;
      completed: boolean;
    }) => {
      if (item.lessonId === lessonId && !item.completed) {
        if (item.totalLessons === item.counterLesson) {
          isCompleted = true;
        }
        found = true;
        return {
          ...item,
          counterLesson: isNext ? item.counterLesson + 1 : item.counterLesson,
          completed: isCompleted,
        };
      }
      return item;
    },
  );
  if (!found) {
    newArray.push({
      lessonId,
      counterLesson: progressIndexLesson || 0,
      totalLessons: totalLessons ?? 0,
      completed: isCompleted,
    });
  }

  return newArray;
};
