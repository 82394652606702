import { Box } from '@mui/material';
import { getArrowIcon } from './getArrowIcon';
import { StyledImprovement } from '../components/Board/Styled.Board';

export const getRowImprovement = (status: string): JSX.Element => {
  const parsedStatus = parseInt(status);
  if (status === null || status === '0') {
    return (
      <Box
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {status === null ? getArrowIcon(null) : getArrowIcon(parsedStatus)}
        {status === null ? null : <span className="percent">{status}%</span>}
      </Box>
    );
  } else {
    return (
      <StyledImprovement percent={parsedStatus}>
        {getArrowIcon(parsedStatus)}
        <span className="percent">{status}%</span>
      </StyledImprovement>
    );
  }
};
