import { answerOptionMultivalued } from '.';
import { AnswerOption, AnswerPlace } from '../../../redux/types/quizTypes';

export const handleSaveDataMultiOpen = ({
  answerPlace,
  questionType,
  answerOption,
  multiValue,
}: {
  answerPlace: AnswerPlace;
  questionType: 'multi' | 'single' | 'open' | 'sentence' | null;
  answerOption: AnswerOption | undefined;
  multiValue: string[];
}) => {
  const data: any = {
    answerPlaceAnswers: [
      {
        answerPlaceId: answerPlace.answerPlaceId,
        studentAnswers:
          questionType === 'multi'
            ? answerOptionMultivalued(answerPlace, multiValue)
            : [
                {
                  answerId: answerOption?.answerId as string,
                  openEntryAnswerText:
                    questionType === 'open'
                      ? multiValue[0].replace(/,/g, '')
                      : '',
                },
              ],
      },
    ],
  };

  return data;
};
