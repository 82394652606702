import { ReactComponent as HarveyBallFull } from '../../../Assets/iconmonstr-circle-filled.svg';
import harveyBall33 from '../../../Assets/harveyBall33.png';
import harveyBall50 from '../../../Assets/harveyBall50.png';
import harveyBall75 from '../../../Assets/harveyBall75.png';

const IMAGE_WIDTH = '33px';
const FULL_PROGRESS = 100;
const HIGH_PROGRESS = 66;
const MID_PROGRESS = 33;

export const getHarveyBalls = (icon: number) => {
  if (icon === FULL_PROGRESS) {
    return <HarveyBallFull color='error' fontSize='large' />;
  } else if (icon > HIGH_PROGRESS) {
    return <img src={harveyBall75} alt='harveyBall75' width={IMAGE_WIDTH} />;
  } else if (icon > MID_PROGRESS) {
    return <img src={harveyBall50} alt='harveyBall50' width={IMAGE_WIDTH} />;
  } else {
    return <img src={harveyBall33} alt='harveyBall33' width={IMAGE_WIDTH} />;
  }
};
