import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { PageContainer } from '../../LibraryComponents/PageContainer';
import lemmyBase from '../../Assets/Lemmy_Base.png';
import { FormsControl } from '../../GlobalComponents/Form/FormControl';
import { editSchema } from './AccountSettingsHelpers';
import Modal from '../../LibraryComponents/Modal/Modal';
import { DeleteConfirmationDialog } from '../../LibraryComponents/DeleteConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { ChangePassword } from '../../LibraryComponents/ChangePassword';
import { Toast } from '../../LibraryComponents/Toast';
import {
  cancelStripeSubscription,
  deleteTeacherAccount,
  getTeacherDetails,
  teacherRefreshLogin,
  updateProfile,
} from '../../redux/slices/teacherSlice';
import { routeIDs } from '../../Routes/RouteIds.routes';
import { useNavigate } from 'react-router-dom';
import { generateLicenseMessage } from './AccountSettingsHelpers/generateLicenseMessage';
import { formatDateToOrdinal } from './AccountSettingsHelpers/formatDateToOrdinal';
import { LicenseKeyEntry } from './Components/LicenseKeyEntry';
import { ContactUs } from '../../LibraryComponents/ContactUs';
import useDataCleanup from '../../hooks/useDataCleanup';
import {
  loginRefresh,
  logout,
  roleSelection,
} from '../../redux/slices/authSlice';
import useResize from '../../hooks/useResize';
import { IAccountSettingsProps, IToast } from './types';
import { Spinner } from '../../LibraryComponents/Spinner';
import { CancelSubscription } from '../../LibraryComponents/CancelSubscription';

export const AccountSettings = ({
  setShowAccountSetting,
}: IAccountSettingsProps) => {
  const { teacherData } = useSelector((state: RootState) => state.teacher);
  const {
    userId: teacherId,
    accessToken: token,
    refreshToken,
    userIsPremium,
    userSubscriptionStatus,
    lemoneyClassroomExpirationDate,
  } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { width } = useResize();
  const name = `${teacherData?.firstName} ${teacherData?.lastName}` || '';

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openCancelSubscription, setOpenCancelSubscription] = useState(false);

  const [openLicenseKeyEntry, setOpenLicenseKeyEntry] = useState(false);
  const [openToast, setOpenToast] = useState<IToast>({
    open: false,
    msg: '',
    severity: 'error',
  });
  const [saveLoading, setSaveLoading] = useState(false);

  const initialValues = {
    lastName: teacherData?.lastName ?? '',
    firstName: teacherData?.firstName ?? '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editSchema,
    onSubmit: async (values) => {
      const { firstName, lastName } = values;
      const stateId = teacherData?.stateId ?? '';
      const districtId = teacherData?.districtId ?? '';
      const schoolName = teacherData?.schoolName ?? '';
      setSaveLoading(true);
      if (
        firstName !== initialValues.firstName ||
        lastName !== initialValues.lastName
      ) {
        await dispatch(
          updateProfile({
            firstName,
            lastName,
            stateId,
            districtId,
            schoolName,
            teacherId,
            token,
          }),
        )
          .then(() => {
            dispatch(
              getTeacherDetails({
                teacherId,
                token,
              }),
            );
          })
          .catch((err) => console.error(err));
        setOpenToast({
          severity: 'success',
          open: true,
          msg: 'successfully modified profile',
        });
        setTimeout(() => {
          setShowAccountSetting(false);
        }, 2000);
      }
      setShowAccountSetting(false);
      formik.resetForm();
      setSaveLoading(false);
    },
  });

  const handleDeleteAccount = () => {
    setShowConfirmation(true);
  };

  const handleCloseModal = () => {
    setShowConfirmation((prevState) => !prevState);
  };

  const handleSubscriptionCancel = async () => {
    setOpenCancelSubscription(true);
  };

  const closeToast = (isOpen: boolean) =>
    isOpen
      ? setOpenToast((prev) => ({ ...prev, open: false }))
      : setOpenToast((prev) => ({ ...prev, open: true }));
  const { removeData } = useDataCleanup();
  const handleDelete = () => {
    dispatch(deleteTeacherAccount({ teacherId, token }))
      .then((res) => {
        if (res.payload === 400) {
          setOpenToast({
            severity: 'error',
            open: true,
            msg: 'We encountered an issue while attempting to delete your account.',
          });
          return;
        }
        setShowAccountSetting(false);
        setShowConfirmation(false);
        removeData();
        dispatch(logout());
        dispatch(roleSelection({ role: null }));
        navigate(`${routeIDs.LOGIN}?account=delete`);
      })
      .catch((err) => console.error(err));
  };
  const handleSubscriptionCancelClosed = async () => {
    setOpenCancelSubscription(false);
    const teacherRefresh = await dispatch(
      teacherRefreshLogin({ userId: teacherId, refreshToken }),
    );
    dispatch(loginRefresh(teacherRefresh.payload));
  };

  const renderMessageWithContactUs = (message: string) => {
    if (!message.includes('{Contact Us}')) {
      return <>{message}</>;
    } else {
      const messageParts = message.split('{Contact Us}');
      return (
        <>
          {messageParts[0]} <ContactUs />
          {messageParts[1]}
        </>
      );
    }
  };

  const formatLemoneyClassroomExpirationDate = formatDateToOrdinal(
    lemoneyClassroomExpirationDate,
  );
  const expirationDate = lemoneyClassroomExpirationDate
    ? new Date(lemoneyClassroomExpirationDate)
    : null;
  const expirationMessage = renderMessageWithContactUs(
    generateLicenseMessage(
      userIsPremium,
      userSubscriptionStatus,
      expirationDate,
    ).message.replace('{ExpirationDate}', formatLemoneyClassroomExpirationDate),
  );

  return (
    <Box>
      {saveLoading && <Spinner />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: '#003366',
          position: 'relative',
          minHeight: '140px',
          borderRadius: '10px 10px 0 0 ',
          mb: '20px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mt: 1.5,
            textAlign: 'center',
            fontWeight: '600',
            color: '#fff',
          }}
          gutterBottom
        >
          Account Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '90px',
          }}
        >
          <Avatar sx={{ width: '80px', height: 'min-content' }}>
            <img
              src={lemmyBase}
              alt="lemmy-logo"
              width={'60px'}
              height={'100%'}
            />
          </Avatar>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              color: '#003366',
            }}
            gutterBottom
          >
            {name}
          </Typography>
        </Box>
      </Box>
      <PageContainer styles={{ minHeight: '45%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            m: 'auto',
          }}
        >
          <Toast
            isOpen={openToast.open}
            severity={openToast.severity}
            onClose={() => closeToast(true)}
            styles={{
              width: { xs: '100%', sm: '400px' },
              textAlign: 'center',
              top: 40,
            }}
            errorMessage={openToast.msg}
          />
        </Box>
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component={'form'}
            onSubmit={formik.handleSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '300px', md: '400px' },
            }}
          >
            <Typography variant="h6">Account Info</Typography>
            <FormsControl
              control="input"
              type="text"
              data-testid="settings-firstName"
              name="firstName"
              label="First Name"
              formik={formik}
              variant="outlined"
            />
            <FormsControl
              control="input"
              type="text"
              name="lastName"
              data-testid="settings-lastName"
              label="Last Name"
              formik={formik}
              variant="outlined"
            />
            <Button
              variant="contained"
              fullWidth
              data-testid="settings-change-password"
              sx={{ mt: '10px', background: '#003366' }}
              onClick={() => setOpenChangePassword(true)}
            >
              Change Password
            </Button>
            {/* <Typography variant="h6" mt={'2rem'} mb={'1rem'} >
              License Info
            </Typography> */}
            <Typography
              color={
                generateLicenseMessage(
                  userIsPremium,
                  userSubscriptionStatus,
                  lemoneyClassroomExpirationDate,
                ).color
              }
              textAlign={'center'}
              marginTop={'2rem'}
              marginBottom={'2rem'}
            >
              {expirationMessage}
            </Typography>
            {!userIsPremium && (
              <Button
                variant="contained"
                fullWidth
                data-testid="settings-enter-licence"
                sx={{ mt: '10px', background: '#003366' }}
                onClick={() => setOpenLicenseKeyEntry(true)}
              >
                Enter License Key
              </Button>
            )}
            {userSubscriptionStatus === 1 && (
              <Button
                sx={{
                  color: 'red',
                  borderColor: 'red',
                  '&:hover': {
                    backgroundColor: '#FFDDDD',
                    borderColor: 'red',
                  },
                }}
                variant="outlined"
                fullWidth
                data-testid="settings-cancel-subscription"
                onClick={() => handleSubscriptionCancel()}
              >
                Cancel Subscription Renewal
              </Button>
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '20px',
                flexDirection: { xs: 'column-reverse', md: 'row' },
                gap: { xs: '20px' },
              }}
            >
              <Button
                sx={{
                  color: 'red',
                  borderColor: 'red',
                  width: { xs: '100%', md: 'auto' },
                  '&:hover': {
                    backgroundColor: '#FFDDDD',
                    borderColor: 'red',
                  },
                }}
                variant="outlined"
                data-testid="settings-delete-account"
                onClick={handleDeleteAccount}
              >
                Delete Account
              </Button>
              <Button
                sx={{
                  color: '#003366',
                  width: { xs: '100%', md: '5rem', borderColor: '#003366' },
                }}
                variant="outlined"
                data-testid="settings-cancel"
                onClick={() => setShowAccountSetting(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                data-testid="settings-save"
                variant="contained"
                sx={{
                  width: { xs: '100%', md: '5rem', background: '#003366' },
                }}
                disabled={saveLoading}
              >
                {saveLoading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Box>
            <Modal
              isOpen={showConfirmation}
              onClose={handleCloseModal}
              showCloseButton={false}
              maxWidth={0}
            >
              <DeleteConfirmationDialog
                mode="confirmation"
                handleDelete={handleDelete}
                handleCloseModal={handleCloseModal}
                actionButtonLabel="Delete Account"
                confirmationText="Are you sure you want to delete your account? This action cannot be undone."
                dialogTitle="Delete Account"
                open={showConfirmation}
              />
            </Modal>
          </Box>
        </Box>
        {openChangePassword && (
          <Modal
            isOpen={openChangePassword}
            onClose={() => setOpenChangePassword(false)}
            maxWidth={width < 600 ? 400 : 600}
          >
            <ChangePassword
              handleClose={() => setOpenChangePassword(false)}
              setOpenToast={setOpenToast}
              openToast={openToast}
              closeToast={closeToast}
            />
          </Modal>
        )}
        {openCancelSubscription && (
          <Modal
            isOpen={openCancelSubscription}
            onClose={() => setOpenCancelSubscription(false)}
            maxWidth={width < 600 ? 400 : 600}
          >
            <CancelSubscription
              handleClose={handleSubscriptionCancelClosed}
              setOpenToast={setOpenToast}
              openToast={openToast}
              closeToast={closeToast}
            />
          </Modal>
        )}
        <LicenseKeyEntry
          open={openLicenseKeyEntry}
          setOpenLicenseKeyEntry={setOpenLicenseKeyEntry}
          token={token}
          teacherId={teacherId}
          refreshToken={refreshToken}
          renderMessageWithContactUs={renderMessageWithContactUs}
        />
      </PageContainer>
    </Box>
  );
};
