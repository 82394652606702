import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const iconMap = {
  null: <HorizontalRuleIcon sx={{ color: '#696969' }} fontSize="large" />,
  0: <ArrowForwardIcon sx={{ color: '#696969' }} fontSize="large" />,
  positive: <ArrowUpwardIcon color="success" fontSize="large" />,
  negative: <ArrowDownwardIcon color="error" fontSize="large" />,
};

export const getArrowIcon = (change: number | null) => {
  const iconKey =
    change === null
      ? 'null'
      : change === 0
      ? 0
      : change > 0
      ? 'positive'
      : 'negative';
  return iconMap[iconKey];
};
