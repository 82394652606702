import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IStudentDataRegister,
  IStudentState,
  StudentChangePassword,
} from '../types/studentTypes';

const initialState: IStudentState = {
  studentData: null,
  status: 'idle',
  progress: [],
  statusStudentResponse: null,
};

const API_URL = process.env.REACT_APP_API_URL;

export const registerStudent = createAsyncThunk(
  'student/registerStudent',
  async (studentData: IStudentDataRegister, { rejectWithValue }) => {
    const { classroomCode, ...data } = studentData;
    const payload = {
      ...data,
      classroomCode: classroomCode,
    };

    const response = await fetch(`${API_URL}/students/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.ok;
  },
);

export const changePasswordStudent = createAsyncThunk(
  'student/changePassword',
  async (
    { oldPassword, newPassword, studentId, token }: StudentChangePassword,
    { rejectWithValue },
  ) => {
    const payload = {
      newPassword,
      oldPassword,
    };
    const response = await fetch(
      `${API_URL}/students/${studentId}/changePassword`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const changeUserName = createAsyncThunk(
  'student/changeUserName',
  async (
    {
      studentId,
      token,
      username,
    }: {
      studentId: string | null;
      token: string | null;
      username: string;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      newUserName: username,
    };

    const response = await fetch(
      `${API_URL}/students/${studentId}/changeUserName`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const deleteStudentAccount = createAsyncThunk(
  'student/deleteStudentAccount',
  async (
    {
      studentId,
      token,
    }: {
      studentId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/students/${studentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.ok;
  },
);

export const studentJoinClassroom = createAsyncThunk(
  'student/studentJoinClassroom',
  async (
    {
      studentId,
      token,
      classroomCode,
    }: {
      studentId: string | null;
      token: string | null;
      classroomCode: string;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      classroomCode,
    };

    const response = await fetch(`${API_URL}/students/${studentId}/joinClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const studentLeaveClassroom = createAsyncThunk(
  'student/studentLeaveClassroom',
  async (
    {
      studentId,
      token,
    }: {
      studentId: string | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/students/${studentId}/leaveClass`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const isUsernameAvailable = createAsyncThunk(
  'student/isUsernameAvailable',
  async (
    { username, token }: { username: string; token: string | null },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${API_URL}/students/isUsernameAvailable/${username}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const getUserDetails = createAsyncThunk(
  'student/getUserDetails',
  async (
    {
      username,
      token,
    }: {
      username: string | undefined | null;
      token: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(`${API_URL}/students/userNames/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const studentResetPassword = createAsyncThunk(
  'student/studentResetPassword',
  async (
    {
      username,
      newPassword,
    }: {
      username: string;
      newPassword: string;
    },
    { rejectWithValue },
  ) => {
    const payload = newPassword;
    const response = await fetch(
      `${API_URL}/students/${username}/resetPassword`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const renameStudent = createAsyncThunk(
  'student/renameStudent',
  async (
    {
      studentId,
      token,
      firstName,
      lastName,
    }: {
      studentId: string | null;
      token: string | null;
      firstName: string;
      lastName: string;
    },
    { rejectWithValue },
  ) => {
    const payload = {
      firstName,
      lastName,
    };

    const response = await fetch(`${API_URL}/students/${studentId}/rename`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorText = await response
        .text()
        .catch(() => 'Failed to retrieve error message');
      return rejectWithValue({ status: response.status, message: errorText });
    }
    return await response.json();
  },
);

export const saveLocalLessonProgressStudent = createAsyncThunk(
  'student/saveLocalLessonProgressStudent',
  async (
    {
      data,
    }: {
      data: {
        lessonId: string;
        counterLesson: number;
        totalLessons: number;
        completed: boolean;
      }[];
    },
    { rejectWithValue },
  ) => {
    return data;
  },
);

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerStudent.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(registerStudent.fulfilled, (state, action) => {
      state.status = 'idle';
      state.statusStudentResponse = null;
    });
    builder.addCase(registerStudent.rejected, (state, action) => {
      state.status = 'failed';
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(deleteStudentAccount.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deleteStudentAccount.fulfilled, (state) => {
      state.status = 'idle';
      state.statusStudentResponse = null;
    });
    builder.addCase(deleteStudentAccount.rejected, (state, action) => {
      state.status = 'failed';
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(isUsernameAvailable.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(isUsernameAvailable.fulfilled, (state) => {
      state.status = 'idle';
      state.statusStudentResponse = null;
    });
    builder.addCase(isUsernameAvailable.rejected, (state, action) => {
      state.status = 'failed';
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(getUserDetails.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.status = 'idle';
      state.studentData = action.payload;
      state.statusStudentResponse = null;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.status = 'failed';
      state.studentData = null;
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(studentResetPassword.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(studentResetPassword.fulfilled, (state) => {
      state.status = 'idle';
      state.statusStudentResponse = null;
    });
    builder.addCase(studentResetPassword.rejected, (state, action) => {
      state.status = 'failed';
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(renameStudent.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(renameStudent.fulfilled, (state) => {
      state.status = 'idle';
      state.statusStudentResponse = null;
    });
    builder.addCase(renameStudent.rejected, (state, action) => {
      state.status = 'failed';
      state.statusStudentResponse = action.payload;
    });
    builder.addCase(saveLocalLessonProgressStudent.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(
      saveLocalLessonProgressStudent.fulfilled,
      (state, action) => {
        state.status = 'idle';
        state.progress = action.payload;
        state.statusStudentResponse = null;
      },
    );
    builder.addCase(
      saveLocalLessonProgressStudent.rejected,
      (state, action) => {
        state.status = 'failed';
        state.progress = [];
        state.statusStudentResponse = action.payload;
      },
    );
  },
});

export default studentSlice.reducer;
